import { DetailsType } from "../../../utils/enum";
import { AlertsData } from "../../../utils/apis/reports_repository";
import ViewDetails from "../table/ViewDetails";
import { getDateTime } from "../../../utils/helper";

interface ListItemProps {
    data: AlertsData;
}

export const ListItem = ({ data }: ListItemProps) => {
    return (
        <div className="border bg-red border-gray-400 rounded-md my-4 items-center justify-between flex flex-row mx-10 py-4 px-6">
            <div className="flex flex-col">
                <h3 className="text-md self-start font-bold text-gray">{data.cardholderName}</h3>
                <p className="text-sm self-start font-bold text-gray-400">{getDateTime(data.entryTimestamp)}</p>
                <p className="text-sm self-start font-bold text-gray-400">{data.cameraName}</p>
            </div>
            <ViewDetails type={DetailsType.ALERTS} data={data}/>
        </div>
    );
}