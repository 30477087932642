import React, { useEffect, useState } from 'react';
import { Button } from '../Button';
import { Text } from '../Text';
import { Divider } from '@mui/material';
import InputFormField from '../inputs/InputFormField';
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { FormProvider } from 'react-hook-form';
import EmailChip from '../chips/EmailChip';
import RejectedChip from '../chips/RejectedChip';
import { SheetClose } from '../Sheets';
import { InviteUserModel, verifyExportUser, verifyDetails, inviteUsers, RejectedUserModel , downloadExampleCsv } from '../../../utils/apis/user_repository';
import { Spinner } from '../Spinner';
import { showToast } from '../../../utils/toast';
import { Dialog, DialogContent } from "../Dialog";
import Papa from 'papaparse';
import { set } from 'lodash';
import ReInviteUsers from './body/ReInviteUsersComponent';
import { getUserFromLocalStorage } from '../../../utils/helper';

const inviteUserScheme = z.object({
    email: z.string().email("Please enter a valid email address") && z.string().min(1, "Email is required"),
    gallagherId: z.string().min(1, "Cardholder Unique Id is required"),
});

type InviteUserScheme = z.infer<typeof inviteUserScheme>;

const InviteUsers = () => {

    const [emailList, setEmailList] = useState<InviteUserModel[]>([]);
    const [rejectedList, setRejectedList] = useState<RejectedUserModel[]>([]);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [singleDialog, openSingleDialog] = useState(false);

    const formData = useForm<InviteUserScheme>({
        mode: "onBlur",
        resolver: zodResolver(inviteUserScheme),
        defaultValues: {
            email: "",
            gallagherId: "",
        },
    });

    const currentUser = getUserFromLocalStorage();

    useEffect(() => {
        return () => {
            setEmailList([]);
        }
    }, []);

    const handleFile = async (file: File) => {
        const text = await file.text();
        const result = Papa.parse(text, { header: true });
        const data = result.data as InviteUserModel[];

        const newRejectedList = [];
        const newEmailList = [];

        for (const user of data) {
            console.log('user', user);

            const result = await verifyExportUser(user);

            console.log('result', result);

            if (emailList.find((e) => e.email === user.email)) {
                newRejectedList.push({ email: user.email, reason: 'Email already added' });
            }

            if (result && 'company' in result) {
                if (result.company === 'Unknown') {
                    newRejectedList.push({ email: user.email, reason: 'Company Name cannot be Unknown' });
                } else {
                    if (currentUser?.tenant !== result.company && currentUser?.role !== 'SuperAdmin') {
                        newRejectedList.push({ email: user.email, reason: 'Current User does not belong to the tenant' });
                    } else {
                        newEmailList.push(user);
                    }
                }
            } else {
                // Ensure the `result` contains a string reason
                const reason = (result as RejectedUserModel).reason ?? 'Unknown Error';
                newRejectedList.push({ email: user.email, reason });
            }
        }
        // Update the state once with the accumulated lists
        setRejectedList([...rejectedList, ...newRejectedList]);
        setEmailList([...emailList, ...newEmailList]);
    }

    const handleExampleCsvDownload = async () => {
        const result = await downloadExampleCsv();
        if (result) {
            showToast("Download started", { type: "success" });
        }
    }


    return (
        <>
            <div className="flex flex-col items-between mt-12 mx-6">
                <div className="flex flex-row justify-between items-center">
                    <div className='flex flex-col'>
                        <Text as="h2">Invite Users</Text>
                        <span className='text-lg font-medium text-secondaryText'>Invite users to your tenant</span>
                    </div>
                    <div className='items-start justify-center p-0 flex flex-row space-x-4'>
                        <Button variant={"secondary"} onClick={ async () => {
                            await handleExampleCsvDownload();
                        }} className="text-primaryText">Download Template</Button>
                        <Button className={`mb-2 w-[150px] text-white`} onClick={
                            // add csv from here
                            () => {
                                // add csv from file storage 
                                const fileInput = document.createElement('input');
                                fileInput.setAttribute('type', 'file');
                                fileInput.setAttribute('accept', '.csv');
                                fileInput.click();

                                // use validation to make sure its a csv file
                                fileInput.addEventListener('change', async (e) => {
                                    const file = (e.target as HTMLInputElement).files?.[0];
                                    if (file) {
                                        const formData = new FormData();
                                        formData.append('file', file);
                                        setIsLoading(true);
                                        await handleFile(file);
                                        setIsLoading(false);
                                    } else {
                                        showToast('Please select a file', { type: 'error' });
                                    }
                                });
                            }
                        }>Import CSV</Button>
                    </div>
                </div>
                <div className="pt-6">
                    <Divider className="bg-secondaryText" />
                </div>
                <div className='flex flex-row items-end justify-between'>
                    <FormProvider {...formData}>
                        <div className='flex flex-col w-30'>
                            <Text as="h3">Email Address</Text>
                            <InputFormField name='email' showError={false} />
                        </div>
                        <div className='flex flex-col w-30'>
                            <Text as="h3">Cardholder Unique ID</Text>
                            <InputFormField name='gallagherId' showError={false} />
                        </div>
                        <Button className={`bg-primary mb-2 w-[150px] text-white`} onClick={
                            formData.handleSubmit(async (data) => {
                                setIsLoading(true);

                                const result = await verifyDetails(data);
                                if (result) {
                                    if (result.company === 'Unknown') {
                                        showToast('Company name cannot be Unknown', { type: 'error' });
                                        return;
                                    } else {

                                        if (currentUser?.tenant !== result.company && currentUser?.role !== 'SuperAdmin') {
                                            showToast('Current User does not belong to the tenant', { type: 'error' });
                                            setIsLoading(false);
                                            return;
                                        }

                                        if (emailList.find((e) => e.email === data.email)) {
                                            showToast('Email already added', { type: 'error' });
                                            setIsLoading(false);
                                        } else {
                                            setEmailList([...emailList, data]);
                                            setIsLoading(false);
                                        }
                                        return;
                                    }
                                }

                                setIsLoading(false);
                            }, (error) => {
                                if (error.email) {

                                    showToast(error.email.message ?? '', { type: 'error' })
                                } else if (error.gallagherId) {
                                    showToast(error.gallagherId.message ?? '', { type: 'error' });
                                }
                            })
                        }>Save</Button>
                    </FormProvider>
                </div>
                <div className="pt-6">
                    <Divider className="bg-secondaryText" />
                </div>
                <div className='py-4 text-primary text-right'>
                    <Button onClick={() => {
                        setRejectedList([]);
                        setEmailList([]);
                    }} className="bg-primary w-[150px] text-white">Clear all</Button>
                </div>
                {isLoading ? <div className='flex justify-center flex-col items-center'>
                    <Text as="span">Verifying Cardholder Unique ID, please wait</Text>
                    <Spinner />
                </div> : <div className="flex flex-col">
                    {emailList.length > 0 ? <div>
                        <div className='flex flex-row space-x-3 mt-6 items-center'>
                            <Text as="h3" className='mt-0 text-status-primaryText'>Accepted Users</Text>
                            <div className='h-8 w-8 rounded-full items-center flex justify-center bg-status-approved'>
                                <span className='text-bold text-white text-md'>
                                    {emailList.length < 10 ? `0${emailList.length}` : `${emailList.length}`}
                                </span>
                            </div>
                        </div>

                        <div className='flex flex-row space-x-3 mt-4 whitespace-nowrap '>
                            {
                                emailList.map((data) => {
                                    return (
                                        <EmailChip email={data.email} />
                                    )
                                })
                            }
                        </div>
                    </div> : null}
                    {rejectedList.length > 0 ? <div>
                        <div className='flex flex-row space-x-3 mt-6 items-center'>
                            <Text as="h3" className='mt-0 text-status-primaryText'>Rejected Users</Text>
                            <div className='h-8 w-8 rounded-full items-center flex justify-center bg-status-rejected'>
                                <span className='text-bold text-white text-md'>
                                    {rejectedList.length < 10 ? `0${rejectedList.length}` : `${rejectedList.length}`}
                                </span>
                            </div>
                        </div>
                        <div className='flex flex-row space-x-3 overflow-x-auto whitespace-nowrap mt-6 '>
                            {
                                rejectedList.map((data) => {
                                    return (
                                        <RejectedChip email={data.email} reason={data.reason} />
                                    )
                                })
                            }
                        </div>
                    </div> : null}

                </div>}
                {
                    emailList.length > 0 && <div className="absolute bottom-0 mx-12 right-0 mb-20 flex flex-row justify-end">
                        <SheetClose asChild>
                            <Button variant={"secondary"} className="w-44 border border-primary text-primaryText">Cancel</Button>
                        </SheetClose>
                        <SheetClose>
                            <Button variant={"default"} onClick={async () => {
                                await inviteUsers(emailList);
                            }} className="w-44 ml-4 text-white">Send Invites</Button>
                        </SheetClose>
                    </div>
                }
            </div>
            <Dialog open={singleDialog} onOpenChange={(val) => openSingleDialog(val)}>

                <DialogContent>
                    <Text as="h3" className="text-center">Delete Booking</Text>
                    <Text as="span" className="text-center">Are you sure you want to delete this booking? Deleting this booking will result in the user not being able to enter the carpark</Text>
                    <div className="flex flex-row justify-center space-x-4 mt-6">
                        <Button onClick={() => openSingleDialog(false)} variant="secondary" className="w-44 border-primary text-primaryText">Cancel</Button>
                    </div>
                </DialogContent>
            </Dialog>
            <ReInviteUsers />
        </>
    )
}

export default InviteUsers;