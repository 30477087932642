import React, { useState } from 'react';
import { useAsyncDebounce } from 'react-table';

interface GlobalFilterProps {
    preGlobalFilteredRows: any[];
    globalFilter: string | undefined;
    setGlobalFilter: (filterValue: string | undefined) => void;
}

  export default function GlobalFilter({
    globalFilter,
    setGlobalFilter,
  }: GlobalFilterProps) {
    const [value, setValue] = useState<string | undefined>(globalFilter);
  
    const onChange = useAsyncDebounce((value: string | undefined) => {
      setGlobalFilter(value || undefined);
    }, 200);
  
    return (
      <label className="flex gap-x-2 items-baseline">
        
        <input
          type="text"
          className="block w-64 py-4 pl-4 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          value={value || ''}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={`Search Here...`}
        />
      </label>
    );
  }



