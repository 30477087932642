import { getUserFromLocalStorage } from "../../utils/helper";
import edit from "../../assets/images/edit.svg";
import React, { useEffect } from "react";
import EditNameDialog from "../dialogs/editPersonalDetails";
import EditPwDialog from "../dialogs/editPassword";
import { Text } from "../../components/ui/Text";
import { Button } from "../../components/ui/CustomButton";
import { Divider } from "@mui/material";
import RoleChip from "../../components/ui/chips/RoleChip";

const ProfileBody = () => {
  const user = getUserFromLocalStorage();
  const [open, setOpen] = React.useState(false);
  const [pwopen, setPwOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(user.name);

  console.log(user.providerData[0].providerId);

  const handleClose = (value: string) => {
    setOpen(false);
    setSelectedValue(value);
  };

  useEffect(() => {
    if (selectedValue !== user.name) {
      setSelectedValue(user.name);
    }
  }, [selectedValue, user.name]);

  const handlePasswordResetOpen = () => {
    setPwOpen(true);
  };

  const handlePasswordResetClose = () => {
    setPwOpen(false);
  };

  return (
    <>
      <div className="px-28 flex flex-col">
        <div className="flex flex-row justify-between items-center">
          <Text as="h2">User Profile</Text>
          <Button className="w-48 flex justify-center">Refresh Profile</Button>
        </div>
        <Divider sx={{ borderBottomWidth: 3 }} className="h-[2px] py-6 border-secondaryText" />
        <div className="bg-white mt-6 rounded-md w-full flex flex-col px-20 justify-start items-start">
          <div className="w-full flex flex-row justify-between items-center mt-6">
            <Text as="h3" className="text-center p-0 m-0">Personal Details</Text>
            <div className="flex flex-row justify-start items-center">
              <Button variant={"secondary"} className="w-40 flex justify-center">Edit Details</Button>
              <Button variant={"default"} className="w-40 ml-4 flex justify-center">Change Password</Button>
            </div>
          </div>
          <div className="w-full flex flex-row justify-between items-center my-8">
            <div className="flex flex-col border border-secondaryText rounded-md w-60 py-4 items-center">
              <Text className="p-0 m-0" as="h4">Full Name</Text>
              <Text className="mt-2">{user.name}</Text>
            </div>
            <div className="flex flex-col border border-secondaryText rounded-md w-60 py-4 items-center">
              <Text className="p-0 m-0" as="h4">Email Address</Text>
              <Text className="mt-2">{user.email}</Text>
            </div>
            <div className="flex flex-col border border-secondaryText rounded-md w-60 py-4 items-center">
              <Text className="p-0 m-0" as="h4">Gallagher ID</Text>
              <Text className="mt-2">{user.gallagherId}</Text>
            </div>
            <div className="flex flex-col border border-secondaryText rounded-md w-60 py-4 items-center">
              <Text className="p-0 m-0" as="h4">User Role</Text>
              <RoleChip value={user.role} />
            </div>
          </div>
        </div>
      </div>
    </>

  );
};

export default ProfileBody;
