import { RulesData } from "@/utils/apis/rules_repository";
import React from "react";
import { Text } from '../../../../Text';
import { TextField } from "@mui/material";
import { SelectDropDown } from '../../../../inputs/SelectDropDown';
import AppliedUsers from "../DetailComponents/AppliedUsers";
import AppliedUserGroups from "../DetailComponents/AppliedUserGroups";
import AppliedTenants from "../DetailComponents/AppliedTenants";


interface BookingLimitsInterface {
    users: { [user: string]: number }; // Use user ids as keys and store string values
    userGroups: { [group: string]: number }; // Use group names as keys and store string values
    tenants: { [tenant: string]: number }; // Use tenant names as keys and store string values
}
interface BookingLimitProps {
    rule: RulesData;
    users: string[];
    appliedUsers: string[];
    appliedUserGroups: string[];
    tenants: string[];
    appliedTenants: string[];
    bookingLimits: BookingLimitsInterface;
    setAppliedUsers: React.Dispatch<React.SetStateAction<string[]>>;
    // setAppliedUserGroups: React.Dispatch<React.SetStateAction<{ name: string, ruleValue: number }[]>>;
    setAppliedTenants: React.Dispatch<React.SetStateAction<string[]>>;
    setBookingLimits: React.Dispatch<React.SetStateAction<BookingLimitsInterface>>;
    setDataChanged: React.Dispatch<React.SetStateAction<boolean>>;
}

export const BookingLimit: React.FC<BookingLimitProps> = ({ rule, users, appliedUsers, setAppliedUsers, appliedUserGroups, tenants, appliedTenants, setAppliedTenants, bookingLimits, setBookingLimits, setDataChanged }) => {
    return (
        <>
            <AppliedUsers users={users} appliedUsers={appliedUsers} setAppliedUsers={setAppliedUsers} setDataChanged={setDataChanged} />

            <Text as='p'> User Booking Limits </Text>

            {appliedUsers.map((user) => {
                return (
                    <div className='wrapper' key={user}>
                        <div className='bg-white py-4'>
                            <TextField
                                id="clean"
                                variant="standard"
                                InputProps={{
                                    disableUnderline: true,
                                }}
                                contentEditable={false}
                                margin='none'
                                disabled
                                defaultValue={user}
                            />
                        </div>
                        <div>
                            <SelectDropDown className="bg-white" onChange={(e) => {
                                setBookingLimits({ ...bookingLimits, users: { ...bookingLimits.users, [user]: parseInt(e.target.value) } });
                                setDataChanged(true);
                            }
                            } value={bookingLimits.users[user] ? bookingLimits.users[user] : parseInt(rule.value)}>
                                {[...Array(7)].map((_, i) => (
                                    <option key={i} value={i + 1}>{i + 1}</option>
                                ))}
                            </SelectDropDown>
                        </div>
                    </div>
                )
            }
            )}

            <AppliedUserGroups appliedUserGroups={appliedUserGroups} />
            
            <Text as='p'> User Group Booking Limits </Text>

            {appliedUserGroups.map((group) => {
                return (
                    <div className='wrapper' key={group}>
                        <div className='bg-white py-4'>
                            <TextField
                                id="clean"
                                variant="standard"
                                InputProps={{
                                    disableUnderline: true,
                                }}
                                contentEditable={false}
                                margin='none'
                                disabled
                                defaultValue={group}
                            />
                        </div>
                        <div>
                            <SelectDropDown className="bg-white" onChange={(e) => {
                                setBookingLimits({ ...bookingLimits, userGroups: { ...bookingLimits.userGroups, [group]: parseInt(e.target.value) } });
                                setDataChanged(true);
                            }
                            } value={bookingLimits.userGroups[group]}>
                                {[...Array(7)].map((_, i) => (
                                    <option key={i} value={i + 1}>{i + 1}</option>
                                ))}
                            </SelectDropDown>
                        </div>
                    </div>
                )
            }
            )}

            <AppliedTenants tenants={tenants} appliedTenants={appliedTenants} setAppliedTenants={setAppliedTenants} setDataChanged={setDataChanged} isDisabled={false}/>

            <Text as='p'> Tenant Booking Limits </Text>

            {appliedTenants.map((tenant) => {
                return (
                    <div className='wrapper' key={tenant}>
                        <div className='bg-white py-4'>
                            <TextField
                                id="clean"
                                variant="standard"
                                InputProps={{
                                    disableUnderline: true,
                                }}
                                contentEditable={false}
                                margin='none'
                                disabled
                                defaultValue={tenant}
                            />
                        </div>
                        <div>
                            <SelectDropDown className="bg-white" onChange={(e) => {
                                setBookingLimits({ ...bookingLimits, tenants: { ...bookingLimits.tenants, [tenant]: parseInt(e.target.value) } });
                                setDataChanged(true);
                            }
                            } value={bookingLimits.tenants[tenant] ? bookingLimits.tenants[tenant] : parseInt(rule.value)}>
                                {[...Array(7)].map((_, i) => (
                                    <option key={i} value={i + 1}>{i + 1}</option>
                                ))}
                            </SelectDropDown>
                        </div>
                    </div>
                )
            }
            )}
        </>
    );
};

export default BookingLimit;