"use client";

import { type FCC } from "../../types";
import { cn } from "../../utils/helper";
import React from "react";

interface TextProps {
  className?: string;
  messageBase?: string;
  messageId?: string;
  as?: "h1" | "h2" | "h3" | "h4" | "p" | "div" | "span";
  data?: Record<string, string>;
}

// JSDOC for below component
/**
 *  @param {string} className - The class name of the component.
 * @param {string} messageBase - The message base of the component.
 * @param {string} messageId - The message id of the component.
 * @param {string} as - tag to use for the component. Default is div.
 * @returns {JSX.Element} - The JSX element of the component.
 * @example
 * <Text className="text-red-500" messageBase="auth" messageId="welcome" as="h1" />
 * @example
 * <Text className="text-red-500" as="h2">My Text</Text>
 *  @example
 */
export const Text: FCC<TextProps> = ({
  children,
  as = "div",
  className = "",
  data
}) => {
  const Tag = as;
  let _className = "";
  switch (Tag) {
    case "h1":
      _className =
        "scroll-m-20 text-3xl font-bold tracking-tight lg:text-5xl";
      break;
    case "h2":
      _className =
        "mt-10 scroll-m-20 pb-2 text-2xl font-semibold tracking-tight transition-colors first:mt-0 dark:border-b-slate-700";
      break;
    case "h3":
      _className = "mt-8 scroll-m-20 text-xl font-semibold tracking-tight";
      break;
    case "h4":
      _className = "mt-4 scroll-m-20 text-lg font-semibold tracking-tight";
      break;
    case "div":
        _className = "mt-4 scroll-m-20 text-lg tracking-tight";
        break;
    case "p":
      _className = "leading-7 [&:not(:first-child)]:mt-6";
      break;
  }
  return (
    <Tag className={cn(_className, className)}>
        {children}
    </Tag>
  );
};
