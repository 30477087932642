interface StatusChipProps {
    value: string;
}

export default function StatusChip({ value }: StatusChipProps) {
    return (
        <div
            className={`${getColor(value)
                } w-[120px] h-10 rounded-md text-center flex items-center justify-center text-sm text-bold`}
        >
            {
                value.includes("Approved") ? "Approved" : value
            }
        </div>
    );
}

export function getColor(title: string) {
    switch (title) {
        case title.includes("Approved") ? title : "":
            return "bg-status-approved/40 text-status-approved";
        case "Updated":
            return "bg-status-updated/40 text-status-updated";
        case "Exit":
            return "bg-status-exited/40 text-status-exited";
        case "Arrived":
            return "bg-status-arrived/40 text-status-arrived";
        case "Update Rejected":
            return "bg-status-rejected/40 text-status-rejected";
        case "Pending":
            return "bg-status-pending/40 text-status-pending";
        case "Inactive":
            return "bg-status-pending/40 text-status-pending";
        case "Active":
            return "bg-status-approved/40 text-status-approved";
        case "Rejected":
            return "bg-status-rejected/40 text-status-rejected";
        case "Completed":
            return "bg-status-completed/40 text-status-completed";
        case "Expired":
            return "bg-status-expired/40 text-status-expired";
        default:
            return "bg-primary";
    }
}