import { Text } from "../Text";

interface RejectedChipProps {
    email: string;
    reason: string;
  }
  
  const RejectedChip: React.FC<RejectedChipProps> = ({ email, reason }) => {
    return (
      <div className="flex px-4 py-3 rounded-md border items-center justify-center flex-col border-status-rejected">
        <Text as="h4" className='text-status-rejected mt-0' >{email}</Text>
       <span className="text-sm text-status-secondaryText">{reason}</span>
      </div>
    );
  };
  
  export default RejectedChip;
  