// PROD
const config = {
    apiKey: `${process.env.REACT_APP_FB_API_KEY}`,
    authDomain: `${process.env.REACT_APP_FB_AUTH_DOMAIN}`,
    databaseURL: `${process.env.REACT_APP_FB_DATABASE_URL}`,
    projectId: `${process.env.REACT_APP_FB_PROJECT_ID}`,
    storageBucket: `${process.env.REACT_APP_FB_STORAGE_BUCKET}`,
    messagingSenderId: `${process.env.REACT_APP_FB_MESSAGING_SENDER_ID}`,
    appId: `${process.env.REACT_APP_FB_APP_ID}`,
    measurementId: `${process.env.REACT_APP_FB_MEASUREMENT_ID}`,
};

export function getFirebaseConfig() {
    if (!config || !config.apiKey) {
        throw new Error("No Firebase configuration object provided." + "\n" + "Add your web app's configuration object to firebase-config.ts");
    } else {
        return config;
    }
}
