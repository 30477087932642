import { cn } from "../../../utils/helper";
import { type PropsWithClass } from "../../../types";
import React, { memo, useState } from "react";
import { ErrorMessage } from "./ErrorMessage";
import { Textarea } from "./TextArea";
import { Text } from "../Text";
import { Eye, EyeOff } from "lucide-react";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  value?: string;
  onChange?: (value: React.ChangeEvent<HTMLInputElement>) => void;
  endIconOnClick?: () => void;
  startIconOnClick?: () => void;
  label?: string;
  labelId?: string;
  placeholder?: string;
  type?: "text" | "textarea" | "number" | "password" | "date";
  endIcon?: React.ReactNode;
  endIconClassName?: string;
  startIcon?: React.ReactNode;
  startIconClassName?: string;
  showError?: boolean;
  errorMessage?: string;
  autofocus?: boolean;
  disabled?: boolean;
}

const Input = memo(
  React.forwardRef<any, PropsWithClass<InputProps>>(
    (
      {
        type = "text",
        label,
        labelId,
        value,
        showError,
        errorMessage,
        className = "",
        placeholder,
        endIcon,
        disabled,
        startIcon,
        startIconClassName,
        endIconClassName,
        endIconOnClick,
        startIconOnClick,
        ...props
      },
      ref
    ) => {
      const [showPassword, setShowPassword] = useState(false);
      if (type === "text" || type === "password" || type === "date") {
        return (
          <div className="relative text-start">
            {label && (
              <label htmlFor={labelId ?? label}>
                <Text as="span" messageId={label} />
              </label>
            )}
            <div className="relative">
              {startIcon && (
                <div
                  onClick={startIconOnClick}
                  className={cn(
                    "absolute bottom-0 left-0 top-0 flex items-center rounded-md pl-3 pr-1",
                    startIconClassName
                  )}
                >
                  {startIcon}
                </div>
              )}
              <input
                ref={ref}
                type={
                  type === "text"
                    ? "text"
                    : type === "date"
                    ? "date"
                    : showPassword
                    ? "text"
                    : "password"
                }
                name={label}
                value={value}
                id={labelId ?? label}
                disabled={disabled}
                className={cn(
                  "mt-1 flex h-10 w-full min-w-[180px] border-2 border-border rounded-md px-3 py-6",
                  endIcon && "pr-12",
                  startIcon && "pl-12",
                  className
                )}
                placeholder={
                  placeholder ?? ''
                }
                {...props}
              />
              {type === "password" &&
                (showPassword ? (
                  <Eye
                    className="absolute bottom-2 right-3 cursor-pointer"
                    onClick={() => setShowPassword(!showPassword)}
                  />
                ) : (
                  <EyeOff
                    className="absolute bottom-2 right-3 cursor-pointer"
                    onClick={() => setShowPassword(!showPassword)}
                  />
                ))}
              {endIcon && (
                <div
                  onClick={endIconOnClick}
                  className={cn(
                    "absolute bottom-0 right-0 top-0 flex items-center rounded-md pl-3 pr-3",
                    endIconClassName
                  )}
                >
                  {endIcon}
                </div>
              )}
            </div>
            <ErrorMessage
              showError={showError ?? false}
              message={errorMessage}
            />
          </div>
        );
      } else if (type === "number") {
        return (
          <div className="relative text-start">
            {label && (
              <label htmlFor={labelId ?? label}>
                <Text as="span" messageId={label} />
              </label>
            )}
            <input
              ref={ref}
              type="number"
              name={label}
              id={labelId ?? label}
              value={value}
              className={cn(
                "mt-1 flex h-10 w-full min-w-[180px] rounded-md border border-slate-300 bg-gray-700 bg-transparent px-3 py-2 text-sm placeholder:text-black focus:outline-none focus:ring-2 focus:ring-slate-400 disabled:cursor-not-allowed disabled:opacity-50 dark:border-slate-700 dark:text-slate-50 dark:focus:ring-slate-400 dark:focus:ring-offset-slate-900",
                className
              )}
              placeholder={placeholder}
              disabled={disabled}
              {...props}
            />
            {endIcon && <div className="input-end-icon">{endIcon}</div>}
            <ErrorMessage
              showError={showError ?? false}
              message={errorMessage}
            />
          </div>
        );
      } else if (type === "textarea") {
        return (
          <div className="text-start">
            {label && (
              <label htmlFor={labelId ?? label}>
                <Text as="span" messageId={label} />
              </label>
            )}
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
            {/* @ts-ignore */}
            <Textarea
              ref={ref}
              autoFocus={props?.autofocus}
              id={labelId ?? label}
              placeholder={placeholder}
              value={value}
              disabled={disabled}
              className={className}
              {...props}
            />
            <ErrorMessage
              showError={showError ?? false}
              message={errorMessage}
            />
          </div>
        );
      } else {
        return <>Unknown Input Type</>;
      }
    }
  )
);

Input.displayName = "Input";
export default Input;
