"use client"

import * as React from "react"
import { format } from "date-fns"
import { Calendar as CalendarIcon } from "lucide-react"
import { DateRange } from "react-day-picker"

import { cn } from "../../../utils/helper"
import { Button } from "../Button"
import { Calendar } from "../Calendar"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../Popover"

interface DateRangePickerProps {
    startDate: Date
    endDate: Date
    setStartDate: (date: Date) => void
    setEndDate: (date: Date) => void
}

export function DatePickerWithRange({
  startDate,
    endDate,
    setStartDate,
    setEndDate
}: DateRangePickerProps) {
  const [date, setDate] = React.useState<DateRange | undefined>({
    from: startDate,
    to: endDate,
  })
  return (
    <div className={cn("grid gap-2")}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={"outline"}
            className={cn(
                "w-[280px] justify-start text-left font-medium text-sm h-12 px-4 border border-gray-300 rounded-md text-gray-700 bg-white hover:bg-gray-100",
                !date && "text-muted-foreground"
              )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {date?.from ? (
              date.to ? (
                <>
                  {format(date.from, "LLL dd, y")} -{" "}
                  {format(date.to, "LLL dd, y")}
                </>
              ) : (
                format(date.from, "LLL dd, y")
              )
            ) : (
              <span className="text-secondaryText">Select Dates for booking</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            initialFocus
            mode="range"
            className="bg-gray-50"
            defaultMonth={date?.from}
            disabled={{ before:  new Date() }}
            selected={date}
            onSelect={
              (newDate) => {
                setDate(newDate);
                setStartDate(newDate!.from!);
                setEndDate(newDate!.to!);
              }
            }
            numberOfMonths={2}
          />
        </PopoverContent>
      </Popover>
    </div>
  )
}
