import {
    getFirestore, collection, query, where, documentId, getDocs, getDoc, doc
} from "firebase/firestore";
import { getClosestMonday } from "../helper";
import { get } from "lodash";
export interface Occupancy {
    entries: EntryData[];
    expectedEntries: ExpectedEntries[];
    date: string;
}

export interface EntryData {
    guest: number;
    vip: number;
    booking: number;
    tenantName: string;
}

export interface ExpectedEntries {
    bookingId: string;
    tenant: string;
    userId: string;
}

interface TenantAlerts {
    alerts: AlertsData[];
}

export interface AlertsData {
    alertTimestamp: string;
    entryTimestamp: string;
    cameraID: string;
    cameraName: string;
    cardholderID: string;
    client: string;
    cardholderName: string;
    gallagherID: string;
    location: string;
    plate: string;
    messageType: string;
    startTimestamp: string;
    repeatOffender: boolean;
    acknowledged: boolean;
    tenantID: string;
    alertType: string;
    description: string;
}

export const firestore = getFirestore();

export const occupancyCollection = collection(firestore, "occupancy");

export const getOccupancyPerWeek = async (): Promise<Occupancy[]> => {
    try {
        // create array to store document snapshot 
        const date = getClosestMonday(new Date());

        // create array that takes the date and adds 7 days to it
        const dateArray = [date];
        for (let i = 1; i < 7; i++) {
            // check if the date is after the current date
            const currentDate = new Date();
            const newDate = new Date(dateArray[i - 1]);
            if (newDate < currentDate) {
                dateArray.push(new Date(dateArray[i - 1].getTime() + 24 * 60 * 60 * 1000));
            }
        }

        const dates = query(
            occupancyCollection,
            where(documentId(), "in",
                dateArray.map((date) => date.toISOString().split("T")[0])
            ),
        );

        const snapshot = await getDocs(dates);

        return snapshot.docs.map((doc) => {
            const data = doc.data() as Occupancy;
            return {
                ...data,
                date: doc.data().date as string,
            };
        }
        );
    } catch (error) {
        console.error("Error fetching occupancy:", error);
        throw error;
    }
};

export const getEntriesPerWeek = async (): Promise<Occupancy[]> => {
    try {
        const date = getClosestMonday(new Date());

        const dateArray = [date];

        for (let i = 1; i < 7; i++) {
            dateArray.push(new Date(dateArray[i - 1].getTime() + 24 * 60 * 60 * 1000));
        }

        const dates = query(
            occupancyCollection,
            where(documentId(), "in", dateArray.map((date) => date.toISOString().split("T")[0]))
        );

        const snapshot = await getDocs(dates);

        return dateArray.map((date) => {
            const data = snapshot.docs.find((doc) => doc.id === date.toISOString().split("T")[0]);
            return {
                entries: get(data?.data(), "entries", []),
                expectedEntries: get(data?.data(), "expectedEntries", []),
                date: date.toISOString().split("T")[0],
            };
        });
    } catch (error) {
        console.error("Error fetching entries:", error);
        throw error;
    }
}

export const getTenantAlerts = async (tenant: string): Promise<AlertsData[]> => {
    try {
        const tenantDoc = doc(firestore, "alerts", tenant);


        const tenantSnapshot = await getDoc(tenantDoc);

        const tenantData = tenantSnapshot.data() as TenantAlerts;

        const sortedAlerts = tenantData.alerts.sort((a, b) => {
            return new Date(b.entryTimestamp).getTime() - new Date(a.entryTimestamp).getTime();
        });

        return sortedAlerts;

    } catch (error) {
        console.error("Error fetching tenant alerts:", error);
        throw error;
    }
}

