import { Doughnut } from "react-chartjs-2";
import { registerables, Chart as ChartJS } from 'chart.js';
import { TenantsData } from "@/utils/apis/tenants_repository";

ChartJS.register(...registerables);

interface DoughnutChartProps {
    chartData: TenantsData;
}

const options = {
    responsive: true,
    cutout: 70,
    plugins: {
        legend: {
            display: false,
        },
    }
};

const doughnutLabel = {
    id: 'doughnutLabel',
    beforeDatasetsDraw: function (chart: any, args: any, pluginOptions: any) {

        const { ctx, data, chartArea: { top, left, height, width } } = chart;

        const totalAllocation = data.datasets[0].data[0] + data.datasets[0].data[1] + data.datasets[0].data[2] + data.datasets[0].data[3];

        ctx.save();
        ctx.font = `bolder 16px Inter`;
        ctx.fillStyle = '#000000';
        ctx.textBaseline = 'middle';
        ctx.textAlign = 'center';
        ctx.fillText(`${Math.ceil((totalAllocation - data.datasets[0].data[3]) / totalAllocation * 100)}% Occupied`, left + width / 2, top + height / 2);

        ctx.restore();

        ctx.font = `bolder 12px Inter`;
        ctx.fillStyle = '#A9B5C6';
        ctx.textBaseline = 'middle';
        ctx.textAlign = 'center';
        ctx.fillText(`${data.datasets[0].data[3]} Spaces Left`, left + width / 2, top + height / 2 + 20);
    }
}

export default function DoughnutChart({ chartData }: DoughnutChartProps) {

    const data = {
        labels: ['Guest', 'Booking', 'Permanent', 'Available'],
        datasets: [
            {
                label: ' Slots',
                data: [chartData.liveGuestOccupancy, chartData.liveBookingOccupancy, chartData.liveVIPOccupancy, chartData.availableOccupancy],
                backgroundColor: [
                    'rgba(255,119,51,0.2)',
                    'rgb(76,185,99 , 0.2)',
                    'rgb(101,175,255 , 0.2)',
                    'rgba(222,229,229,0.2)'
                ],
                borderWidth: 1,
                borderColor: [
                    'rgba(255,119,51)',
                    'rgba(76,185,99)',
                    'rgba(101,175,255)',
                    'rgba(222,229,229)'
                ],
                hoverOffset: 4,
            },
        ],
    };

    return (
        <>
            <Doughnut data={data} options={options} plugins={[doughnutLabel]} />
        </>
    )
}