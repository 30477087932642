import clsx, { ClassValue } from "clsx";
import { Timestamp } from "firebase/firestore";
import { twMerge } from "tailwind-merge";
import moment from "moment";
import { Roles } from "../components/ui/containers/RoleDropdown";


export function cn(...inputs: ClassValue[]) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call
    return twMerge(clsx(inputs));
}

export const getUserFromLocalStorage = () => {
    const user = localStorage.getItem("user");
    if (user) {
        return JSON.parse(user);
    }
    return null;
};

export const mapRoleToEnum = (role: string): string => {
    switch (role.toLowerCase()) {
      case "user":
        return Roles.USER;
      case "superadmin":
      case "super admin":
        return Roles.SUPER_ADMIN;
      case "tenantadmin":
      case "tenant admin":
        return Roles.TENANT_ADMIN;
      case "guest":
        return Roles.GUEST;
      default:
        throw new Error(`Unknown role: ${role}`);
    }
  };



// get day of week from date utc string
export const getDayOfWeek = (date: string) => {
    const dateObj = new Date(date);
    return dateObj.toLocaleString("default", { weekday: "long" });
};

export function formatDateTime(datetimeString: string): string {
    const date = new Date(datetimeString);

    // Use toISOString() for easy formatting, then manipulate the output
    const isoString = date.toISOString(); // Produces something like '2024-02-26T16:00:00.000Z'
    return isoString;
}

export function classNames(...classes: (string | undefined | null | false)[]): string {
    return classes.filter(Boolean).join(" ");
}

// Function that gets a date string and only gets the date part
export const getDate = (date: string) => {
    // convert to day month year format
    const dateObj = new Date(date);

    const utcDate = new Date(dateObj.getTime() + dateObj.getTimezoneOffset() * 60000);

    const day = utcDate.getDate();
    // if day is less than 10, add a 0 in front
    const dayString = day < 10 ? `0${day}` : `${day}`;
    const month = dateObj.getMonth() + 1;
    // if month is less than 10, add a 0 in front
    const monthString = month < 10 ? `0${month}` : `${month}`;
    const year = dateObj.getFullYear();
    return `${dayString}/${monthString}/${year}`;
};

// Function that gets a string and adds a space between each capital letter
export const addSpaceBetweenCapitalLetters = (string: string) => {
    return string.replace(/([A-Z])/g, " $1").trim();
};

export const updateUserNamesInLocalStorage = (name: string) => {
    const user = getUserFromLocalStorage();
    if (user) {
        localStorage.setItem("user", JSON.stringify({ ...user, name }));
    }
};
// Function that gets a date string and only gets the time part (hours and minutes) and AM or PM
export const getTime = (date: string) => {
    // convert to day month year format
    const dateObj = new Date(date);

    const utcDate = new Date(dateObj.getTime() + dateObj.getTimezoneOffset() * 60000);

    const hours = utcDate.getHours();
    const minutes = utcDate.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const hoursString = hours % 12 === 0 ? "12" : `${hours % 12}`;
    const minutesString = minutes < 10 ? `0${minutes}` : `${minutes}`;
    return `${hoursString}:${minutesString} ${ampm}`;
};

// Function that gets a date string and gets the date part and time part
export const getDateTime = (date: string) => {
    return `${getDate(date)} ${getTime(date)}`;
};

// Function to convert a Time string to a Date string in string (17th Dec) format
export const convertTimestampToDate = (time: Timestamp) => {
    const dateObj = time.toDate();

    // Define options for the date and time format
    const dateOptions: Intl.DateTimeFormatOptions = {
        day: "numeric",
        month: "short",
    };

    // Format the date part
    const formattedDate = new Intl.DateTimeFormat("en-US", dateOptions).format(dateObj);

    // Combine the formatted date and time
    return `${formattedDate}`;
};

// Function to get current day of weeek from date
export const getClosestMonday = (date: Date) => {
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(date.setDate(diff));
};

// Function to convert a Time string to a Time string in string (10:45 PM) format
export const convertTimestampToTime = (time: Timestamp) => {
    const dateObj = new Date(time.toDate());
    const hours = dateObj.getHours();
    const minutes = dateObj.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const hoursString = hours % 12 === 0 ? "12" : `${hours % 12}`;
    const minutesString = minutes < 10 ? `0${minutes}` : `${minutes}`;
    return `${hoursString}:${minutesString} ${ampm}`;
};

// Function that gets a date string and converts it to a date object
export const getDateObject = (date: string) => {
    // convert to day month year format
    const dateObj = new Date(date);
    const utcDate = new Date(dateObj.getTime() + dateObj.getTimezoneOffset() * 60000);
    return utcDate;
};

// Function that gets a date object and time string and converts it to a date string with Z
export const getDateTimeString = (date: string, time: string) => {
    const dateObj = new Date(date);
    // change hours depending on AM or PM
    if (time.includes("PM")) {
        // keep in mind that 12 PM is 12 hours
        if (time.split(":")[0] !== "12") {
            dateObj.setHours(parseInt(time.split(":")[0]) + 12);
        } else {
            dateObj.setHours(parseInt(time.split(":")[0]));
        }
    } else {
        dateObj.setHours(parseInt(time.split(":")[0]));
    }
    dateObj.setMinutes(parseInt(time.split(":")[1]));
    const newDate = moment(dateObj).format();
    // convert remove timezone and add Z
    return newDate.split("+")[0] + "Z";
};

// Function to format date to iso string
export const getISOString = (date: Date) => {
    const dateObj = new Date(date);
    const isoDate = dateObj.toDateString() + "T" + dateObj.getHours() + ":" + dateObj.getMinutes() + ":" + dateObj.getSeconds() + "." + dateObj.getMilliseconds() + "Z";
    return isoDate;
};

export const addDays = (date: Date, days: number): Date => {
    date.setDate(date.getDate() + days);
    return date;
};

// Function that gets fullname and removes the space between them
export const getFullNameWithoutSpace = (name: string) => {
    return name.replace(/\s/g, "");
};

// get the time from a date object
export const getTimeFromDate = (date: Date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const hoursString = hours % 12 === 0 ? "12" : `${hours % 12}`;
    const minutesString = minutes < 10 ? `0${minutes}` : `${minutes}`;
    return `${hoursString}:${minutesString} ${ampm}`;
};

// used in default booking times for the time picker
export const prettifyTime = (time: number) => {
    // take 900 and return 9:00 AM
    // take 1700 and return 5:00 PM
    let hours = Math.floor(time / 100);
    let minutes = time % 100;
    let suffix = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours || 12;
    let minutesString = minutes < 10 ? "0" + minutes : minutes;
    return `${hours}:${minutesString} ${suffix}`;
};

// used in default booking times for the time picker

export const unprettifyTime = (time: string) => {
    // take 9:00 AM and return 900
    // take 5:00 PM and return 1700
    let timeArray = time.split(" ");
    let hours = parseInt(timeArray[0].split(":")[0]);
    let minutes = parseInt(timeArray[0].split(":")[1]);
    let suffix = timeArray[1];
    if (suffix === "PM") {
        hours += 12;
    }
    return hours * 100 + minutes;
};


