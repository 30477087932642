import { Firestore } from 'firebase/firestore';
import { showToast } from '../toast';

export interface UserGroupsModel {
    bookingLimit?: number;
    futureBookingPeriod?: number;
    lastMinuteBookingPeriod?: number;
    groupId?: string;
    name: string;
    description: string;
    tenant: string;
    users: { userId: string, name: string }[];
    appliedRules: string[];
}

export const getUserGroups = async (tenant?: string): Promise<UserGroupsModel[]> => {
    try {
        const db = await import('firebase/firestore');
        const firestore: Firestore = db.getFirestore();

        // if tenant is not provided, return all user groups. Otherwise, return user groups for the tenant
        const query = tenant ? db.query(db.collection(firestore, 'userGroups'), db.where('tenant', '==', tenant)) : db.collection(firestore, 'userGroups');
        const querySnapshot = await db.getDocs(query);

        const usersRef = db.collection(firestore, "users");
        const usersSnap = await db.getDocs(usersRef);

        // console.log("User groups")
        // console.log(querySnapshot.docs.map((doc) => doc.data()));
        // console.log(usersSnap.docs.map((doc) => doc.data()));

        // Return a list of user group data objects
        const groups = querySnapshot.docs.map((doc) => ({
            groupId: doc.id,
            name: doc.data().name,
            description: doc.data().description,
            tenant: doc.data().tenant,
            users: doc.data().users.map((userId: string) => {
                const user = usersSnap.docs.find((doc) => doc.id === userId);
                return {
                    userId: userId,
                    name: user?.data().name,
                };
            }
            ),
            appliedRules: doc.data().appliedRules,
            bookingLimit: doc.data().bookingLimit? doc.data().bookingLimit : 0,
            futureBookingPeriod: doc.data().futureBookingPeriod? doc.data().futureBookingPeriod : 0,
        }));
        console.log("GROUPS: ", groups);
        return groups;
    } catch (error) {
        console.error('Error fetching user groups:', error);
        throw error; // handle or log the error accordingly
    }
};

export const getUserGroupByName = async (name: string): Promise<UserGroupsModel | null> => {
    try {
        const db = await import('firebase/firestore');
        const firestore: Firestore = db.getFirestore();

        const usersRef = db.collection(firestore, "users");
        const usersSnap = await db.getDocs(usersRef);

        // query the user group by name
        const query = db.query(db.collection(firestore, 'userGroups'), db.where('name', '==', name));
        const querySnapshot = await db.getDocs(query);

        if (querySnapshot.docs.length === 0) {
            return null;
        }

        // Return the user group data object
        const doc = querySnapshot.docs[0];
        return {
            groupId: doc.id,
            name: doc.data().name,
            description: doc.data().description,
            tenant: doc.data().tenant,
            users: doc.data().users.map((userId: string) => {
                const user = usersSnap.docs.find((doc) => doc.id === userId);
                return {
                    userId: userId,
                    name: user?.data().name,
                };
            }
            ),
            appliedRules: doc.data().appliedRules,
            bookingLimit: doc.data().bookingLimit? doc.data().bookingLimit : 0,
            futureBookingPeriod: doc.data().futureBookingPeriod? doc.data().futureBookingPeriod : 0,
        };

    } catch (error) {
        console.error('Error fetching user group by name:', error);
        throw error; // handle or log the error accordingly
    }
}

export const addUserGroup = async (userGroup: UserGroupsModel): Promise<void> => {
    
    try {
        const db = await import('firebase/firestore');
        const firestore: Firestore = db.getFirestore();

        // Remove the user names from the users array before adding the user group to the database
        const users = userGroup.users;
        const userIdList = users.map((user) => user.userId);

        let userGroupId;
        let userGroupBeforeUpdate;

        // if the user group already exists, update the user group. Otherwise, add the user group
        if (userGroup.groupId) {
            const userGroupRef = db.doc(firestore, 'userGroups', userGroup.groupId);
            userGroupBeforeUpdate = await db.getDoc(userGroupRef).then((doc) => doc.data());
            await db.updateDoc(userGroupRef, {
                ...userGroup,
                users: userIdList,
            }).then(() => {
                userGroupId = userGroup.groupId;
            });
            showToast('User group updated successfully', { type: 'success' })
        } else {
            const userGroupRef = db.collection(firestore, 'userGroups');
            userGroup.bookingLimit = 1;
            userGroup.futureBookingPeriod = 7;
            userGroupBeforeUpdate = userGroup;
            await db.addDoc(userGroupRef, {
                ...userGroup,
                users: userIdList,
            }).then((docRef) => {
                userGroupId = docRef.id;
            });
            showToast('User group added successfully', { type: 'success' })
        }
        
        // update the rule doc with the user group id
        // if (userGroup.appliedRules.length > 0) {
            const rulesRef = db.collection(firestore, 'rules');
            const rulesSnapshot = await db.getDocs(rulesRef);

            for (let ruleName of userGroup.appliedRules) {
                const ruleDoc = rulesSnapshot.docs.find((doc) => doc.data().title === ruleName);
                if (ruleDoc) {
                    const ruleId = ruleDoc.id;
                    const ruleRef = db.doc(firestore, 'rules', ruleId);
                    await db.updateDoc(ruleRef, {
                        userGroups: db.arrayUnion(userGroupId),
                    });
                    console.log(`User group ${userGroup.name} added to rule ${ruleName}`);
                }
            }
            for (let ruleName of userGroupBeforeUpdate?.appliedRules) {
                if (!userGroup.appliedRules.includes(ruleName)) {
                    const ruleDoc = rulesSnapshot.docs.find((doc) => doc.data().title === ruleName);
                    if (ruleDoc) {
                        const ruleId = ruleDoc.id;
                        const ruleRef = db.doc(firestore, 'rules', ruleId);
                        await db.updateDoc(ruleRef, {
                            userGroups: db.arrayRemove(userGroupId),
                        });
                        console.log(`User group ${userGroup.name} removed from rule ${ruleName}`);
                    }
                }
            }
        // }
        
    } catch (error) {
        console.error('Error adding user group:', error);
        showToast('Error adding user group', { type: 'error' });
        throw error; // handle or log the error accordingly
    }
};

export const deleteUserGroup = async (userGroup: UserGroupsModel): Promise<void> => {
    try {
        const db = await import('firebase/firestore');
        const firestore: Firestore = db.getFirestore();

        // Delete the user group from the database
        if (userGroup.groupId) {
            const userGroupRef = db.doc(firestore, 'userGroups', userGroup.groupId);
            await db.deleteDoc(userGroupRef);
        }
        showToast('User group deleted successfully', { type: 'success' })
    } catch (error) {
        console.error('Error deleting user group:', error);
        showToast('Error deleting user group', { type: 'error' });
        throw error; // handle or log the error accordingly
    }
};