


export const DoughnutLabel = () => {
    return (
        <>
            <div className='grid grid-cols-2 gap-10 py-8'>
                <div className='flex flex-row items-center'>
                    <div className='w-14 h-5 bg-[#65AFFF]/20 border-[#65AFFF] border rounded-sm mr-2'></div>
                    <div className='text-sm font-medium text-gray-400'>VIP</div>
                </div>
                <div className='flex flex-row items-center'>
                    <div className='w-14 h-5 bg-[#4CB963]/20 border-[#4CB963] border rounded-sm mr-2'></div>
                    <div className='text-sm font-medium text-gray-400'>Booking App</div>
                </div>
                <div className='flex flex-row items-center'>
                    <div className='w-14 h-5 bg-[#FF7733]/20 border-[#FF7733] border rounded-sm mr-2'></div>
                    <div className='text-sm font-medium text-gray-400'>Guest</div>
                </div>
                <div className='flex flex-row items-center'>
                    <div className='w-14 h-5 bg-[#DEE5E5]/20 border-[#DEE5E5] border rounded-sm mr-2'></div>
                    <div className='text-sm font-medium text-gray-400'>Available Spaces</div>
                </div>
            </div>
        </>
    );
}