import React from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Text } from "../../../Text"
import { Button as CustomButton } from "../../../Button";
import { Divider, IconButton } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { UserGroupsModel, addUserGroup, deleteUserGroup } from "../../../../../utils/apis/user_groups_repository";

interface UserGroupsHeaderProps {
    title: string;
    description: string;
    showSubmitButton: boolean;
    userGroup: UserGroupsModel;
    setDialogState: (state: boolean) => void;
    setShowSubmitButton: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UserGroupsHeader: React.FC<UserGroupsHeaderProps> = ({ title, description, showSubmitButton, userGroup, setDialogState, setShowSubmitButton }) => {
    const [updating, setUpdating] = React.useState(false);

    const queryClient = useQueryClient();

    const mutationToUpdate = useMutation({
        mutationFn: addUserGroup,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['userGroups'] });
            setUpdating(false);
            setDialogState(false);
        },
    })

    const mutationToDelete = useMutation({
        mutationFn: deleteUserGroup,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['userGroups'] });
            queryClient.invalidateQueries({ queryKey: ['rules'] });
            setUpdating(false);
            setDialogState(false);
        },
    })

    const submitChanges = () => {
        setUpdating(true)
        setShowSubmitButton(false);
        console.log("submitting changes");
        console.log(userGroup);
        mutationToUpdate.mutate(userGroup);
    };

    const deleteGroup = () => {
        setUpdating(true);
        setShowSubmitButton(false);
        mutationToDelete.mutate(userGroup);
    }

    return (
        <>
            <div className="flex flex-row items-center justify-between">
                    <Text as="h2">{ title }</Text>
                    <div className="flex flex-row space-x-8 items-center">
                        <IconButton aria-label="delete" onClick={deleteGroup}>
                            <DeleteIcon />
                        </IconButton>
                        {!updating && showSubmitButton && (
                            <CustomButton
                                className="bg-primary w-38"
                                onClick={submitChanges}
                                style={{ animation: "wobble 1s" }}
                            >
                                Save Changes
                            </CustomButton>
                        )}
                        {updating && (
                            <CustomButton
                                className="bg-primary w-38"
                                disabled
                            >
                                Saving...
                            </CustomButton>
                        
                        )}
                    </div>
                </div>
                <span className='text-lg font-medium text-secondaryText'>{ description }</span>
                <div className="pt-6">
                    <Divider className="bg-secondaryText" />
                </div>
        </>
    );
};

export default UserGroupsHeader;