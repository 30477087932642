import React from 'react';
import {
    DropdownMenu,
    DropdownMenuTrigger,
    DropdownMenuContent,

    DropdownMenuRadioGroup,
    DropdownMenuRadioItem
} from '../inputs/DropDown'; // Adjust the path accordingly
import { Button } from '../CustomButton'; // Adjust the path accordingly
import { ChevronDown } from 'lucide-react';
import { getUserFromLocalStorage } from '../../../utils/helper';
import { UserModel } from '@/utils/apis/user_repository';

export const Roles = {
    USER: 'user',
    SUPER_ADMIN: 'superAdmin',
    TENANT_ADMIN: 'tenantAdmin',
    GUEST: 'guest',
  };

  export interface SimpleDropdownProps {
    currentRole: string;
    onRoleChange: (role: string) => void;
  }

  const roleDisplayNames = {
    [Roles.USER]: 'User',
    [Roles.SUPER_ADMIN]: 'Super Admin',
    [Roles.TENANT_ADMIN]: 'Tenant Admin',
    [Roles.GUEST]: 'Guest',
  };

  const SimpleDropdown: React.FC<SimpleDropdownProps> = ({ currentRole, onRoleChange }) => {

    const currentUser: UserModel = getUserFromLocalStorage();
   
    console.log(currentUser.role);

    return (
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button className='w-56 flex justify-between items-center' variant="secondary">
            {roleDisplayNames[currentRole]}
            <ChevronDown className="ml-2 h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56 bg-white">
          <DropdownMenuRadioGroup value={currentRole} onValueChange={onRoleChange}>
            <DropdownMenuRadioItem className='bg-white' value={Roles.USER}>{roleDisplayNames[Roles.USER]}</DropdownMenuRadioItem>
            {currentUser.role === 'SuperAdmin' ? <DropdownMenuRadioItem className='bg-white' value={Roles.SUPER_ADMIN}>{roleDisplayNames[Roles.SUPER_ADMIN]}</DropdownMenuRadioItem> : null}
            <DropdownMenuRadioItem className='bg-white' value={Roles.TENANT_ADMIN}>{roleDisplayNames[Roles.TENANT_ADMIN]}</DropdownMenuRadioItem>
            <DropdownMenuRadioItem className='bg-white' value={Roles.GUEST}>{roleDisplayNames[Roles.GUEST]}</DropdownMenuRadioItem>
          </DropdownMenuRadioGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    );
  };

export default SimpleDropdown;