// same as Button.tsx. But,
// set background color to white, set the text color to brown, and set the border color to brown.
// put the microsoft logo on the left side of the button.
// takes in "Sign Up" | "Sign In" as props.

import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "../../utils/helper";
import microsoft from "../../assets/images/microsoft.png";

const microsoftButtonStyle = cva(
  "relative inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 ring-offset-background",
  {
    variants: {
      variant: {
        default: "text-brown bg-white hover:bg-brown-dark focus-visible:ring-brown",
        secondary: "text-white bg-secondary hover:bg-secondary-dark focus-visible:ring-secondary",
        danger: "text-white bg-danger hover:bg-danger-dark focus-visible:ring-danger",
      },
      size: {
        default: "h-12 py-2 px-4",
        sm: "h-9 px-3 rounded-md",
        xs: "h-7 min-w-0 px-2 rounded-md",
        full: "h-10 w-full rounded-md",
        lg: "h-11 px-8 rounded-md",
      },
      font: {
        default: "font-medium",
        bold: "font-bold",
      }
    },
    defaultVariants: {
      variant: "default",
      size: "default",
      font: "default",
    }
  }
);

export interface MicrosoftButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
  VariantProps<typeof microsoftButtonStyle> {
  isLoading?: boolean;
  asChild?: boolean;
}

const MicrosoftButton = React.forwardRef<HTMLButtonElement, MicrosoftButtonProps>(
  ({ className, variant, size, font, children, isLoading, ...props }, ref) => {
    return (
      <button
        className={cn(microsoftButtonStyle({ variant, size, font, className }))}
        ref={ref}
        {...props}
      >
          <div className="flex items-center">
            <img src={microsoft} alt="Microsoft Logo" className="h-6 w-6 mr-6" />
          </div>
          {children}
      </button>
    );
  }
);

MicrosoftButton.displayName = "MicrosoftButton";

export { MicrosoftButton, microsoftButtonStyle };

// Path: src/pages/authentication/signin.tsx
