import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Text } from '../../components/ui/Text';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { deleteValetBooking, getValetBookingDetails, getBookingDetailsFromHistory, updateValetBooking, } from '../../utils/apis/bookings_repository';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import the CSS for react-confirm-alert
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import check_mark from "../../assets/images/check_mark.png";
import { MdDirections } from 'react-icons/md';
import { FaApple } from 'react-icons/fa';
import { handleValetCancelBooking } from '../../utils/apis/payment_repository';
import vip_logo from "../../assets/images/vip_logo.png";
const getTimeNowInAussie = (): Date => {
    const date = new Date();
    const options: Intl.DateTimeFormatOptions = {
        timeZone: 'Australia/Sydney',
        hour12: false,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    };
    const formatter = new Intl.DateTimeFormat('en-GB', options);
    const parts = formatter.formatToParts(date);

    const aussieDateParts = parts.reduce((acc, part) => {
        acc[part.type] = part.value;
        return acc;
    }, {} as Record<string, string>);

    const aussieTime = new Date(
        `${aussieDateParts.year}-${aussieDateParts.month}-${aussieDateParts.day}T${aussieDateParts.hour}:${aussieDateParts.minute}:${aussieDateParts.second}`
    );

    return aussieTime;
};

const EditBooking = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const bookingId = queryParams.get('bookingId');
    const navigate = useNavigate();

    const [selectedDate, setSelectedDate] = useState<Date>(getTimeNowInAussie());
    const [loading, setLoading] = useState<boolean>(false);
    const [venue, setVenue] = useState<string>('');
    const [amount, setAmount] = useState<number>(0);
    const [isDateAvailable, setIsDateAvailable] = useState<boolean>(true);
    const [phone, setPhone] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [plateNumber, setPlateNumber] = useState<string>('');
    const [status, setStatus] = useState<boolean>(false);
    const [savingChanges, setSavingChanges] = useState<boolean>(false);
    const [cancelingBooking, setCancelingBooking] = useState<boolean>(false);
    const [editSuccess, setEditSuccess] = useState<boolean>(false);
    const [bookingMessage, setBookingMessage] = useState<string | null>(null);

    const formattedDate = new Date(selectedDate).toLocaleDateString();
    const formattedTime = new Date(selectedDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

    const tenantOccupancyUrl = process.env.REACT_APP_TENANT_OCCUPANCY_URL;
    const googleMapsUrl = process.env.REACT_APP_GOOGLE_MAPS_URL;
    const appleMapsUrl = process.env.REACT_APP_APPLE_MAPS_URL;

    useEffect(() => {
        if (bookingId) {
            fetchBookingDetails();
        }
    }, [bookingId]);

    const fetchBookingDetails = async () => {
        try {
            const bookingData = await getValetBookingDetails(bookingId!);
            setBookingData(bookingData);
        } catch (error) {
            console.error('Error fetching booking details from bookings:', error);
            try {
                const bookingData = await getBookingDetailsFromHistory(bookingId!);
                if (bookingData) {
                    setBookingMessage('Your booking has already been completed or deleted. Please make a new booking.');
                }
            } catch (historyError) {
                console.error('Error fetching booking details from history:', historyError);
                setBookingMessage('An error occurred while fetching booking details. Please try again later.');
            }
        }
    };

    const setBookingData = (bookingData: any) => {
        // Extract the date part from the startTime
        const { startTime, venue, phone, email, plate, status } = bookingData;
        const dateOnly = startTime.split('T')[0];
        console.log("check before passing ", dateOnly);

        // Create a new Date object using the date-only string
        console.log("check after passing ", new Date(dateOnly));
        setSelectedDate(new Date(dateOnly));
        setPhone(phone);
        setEmail(email);
        setPlateNumber(plate);
        setVenue(venue);
        setAmount(fetchAmountForVenue(venue));
        setIsDateAvailable(true);
        setStatus(status === "Approved");
    };

    const fetchAmountForVenue = (venue: string): number => {
        switch (venue) {
            case '12 Micron':
            case 'Meat & Wine Co':
            case 'Nola':
            case 'Callao':
            case "The Butcher's Block":
                return 80;
            default:
                return 0;
        }
    };

    const handleDateChange = async (date: Date) => {
        setSelectedDate(date);
        setLoading(true);
        setIsDateAvailable(true); // Reset availability state
        const isAvailable = await checkDateAvailability(date);
        setIsDateAvailable(isAvailable);
        setLoading(false);
    };
    const formatDateForPayload = (dateString: string): string => {
        const date = new Date(dateString);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();
        const datePart = date.toISOString().split('T')[0]; // Extract date in YYYY-MM-DD format
        const timePart = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}.000`; // Create time string in HH:MM:SS.sss format
        return `${datePart}T${timePart}Z`; // Combine date and time in the required format
    };

    const checkDateAvailability = async (date: any): Promise<boolean> => {

        const formattedDate = formatDateForPayload(date);
        console.log("foramtted date ", formattedDate)

        try {

            const response = await axios.post(`${process.env.REACT_APP_TENANT_OCCUPANCY_URL}`, {
                date: formattedDate,
            });
            return response.data.status === true;
        } catch (error) {
            console.error('Error fetching date availability', error);
            return false;
        }
    };

    const handleSaveChanges = async () => {
        setSavingChanges(true);

        const bookingData = {
            startTime: selectedDate.toISOString(),
            venue: venue,
            email: email,
            plateNumber: plateNumber.toString(),
            bookingId: bookingId,
            phone: phone,
        };

        try {
            const success = await updateValetBooking(bookingData);
            if (success) {
                setEditSuccess(true); // Set edit success state
            }
            setSavingChanges(false);
        } catch (error) {
            console.error('Error saving booking changes:', error);
            setSavingChanges(false);
        }
    };

    const confirmCancelBooking = () => {
        confirmAlert({
            message: 'Are you sure you want to cancel this booking?',
            buttons: [
                {
                    label: 'No',
                },
                {
                    label: 'Yes',
                    onClick: handleCancelBooking,
                },
            ],
        });
    };

    const handleCancelBooking = async () => {
        setCancelingBooking(true);
        try {
            const isCancelSuccess = await handleValetCancelBooking(bookingId!);

            if (isCancelSuccess) {
                navigate("/valet")
            }

        } catch (error) {
            console.error('Error canceling booking:', error);
        }
        setCancelingBooking(false);
    };


    const openInGoogleMaps = () => {
        const url = `${googleMapsUrl}${-33.86336531482488},${151.20226835295642}`;
        window.open(url, '_blank');
    };

    const openInAppleMaps = () => {
        const url = `${appleMapsUrl}${-33.86336531482488},${151.20226835295642}`;
        window.open(url, '_blank');
    };


    return (
        <div className='w-full h-screen flex items-center justify-center'>
            <div className="valetCard">
                <div className="bg-white flex flex-col items-center justify-center">
               
                    
                    <img src={vip_logo} style={{ height: '150px' }} />
                    {!editSuccess ? <Text as="h2" className="text-xl  justify-center font-bold text-gray-600 mt-0 mb-4">
                        Edit Booking
                    </Text> : <></>}
                    {bookingMessage ? (
                        <div className="text-center text-red-600 font-medium">
                            <p>{bookingMessage}</p>
                            <button
                                onClick={() => navigate("/valet")}
                                className="w-48 bg-primary h-12 py-2 px-4 text-white font-medium rounded-md mt-4"
                            >
                                Make a New Booking
                            </button>
                        </div>
                    ) :
                        !editSuccess ? (
                            <>
                                <div className="venue-selection mb-4 flex items-center">
                                    <select
                                        value={venue}
                                        onChange={(e) => setVenue(e.target.value)}
                                        className="border-input ring-offset-background focus:outline-blue-500 placeholder:text-muted-foreground h-12 w-64 rounded-md border hover:border-2 focus-visible:outline-blue-500 bg-transparent px-4 pr-10 py-2 text-md disabled:cursor-not-allowed disabled:opacity-50 border-gray-400"
                                    >
                                        <option value="" disabled>Select a venue</option>
                                        <option value="12 Micron">12 Micron</option>
                                        <option value="Meat & Wine Co">Meat & Wine Co</option>
                                        <option value="Nola">Nola</option>
                                        <option value="Callao">Callao</option>
                                        <option value="The Butcher's Block">The Butcher's Block</option>
                                    </select>
                                </div>

                                <div className="booking-details mb-4">
                                    <input
                                        type="text"
                                        disabled
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        placeholder="Enter Email"
                                        className="border-input ring-offset-background focus:outline-blue-500 placeholder:text-muted-foreground h-12 w-64 rounded-md border hover:border-2 focus-visible:outline-blue-500 bg-transparent px-4 pr-10 py-2 text-md disabled:cursor-not-allowed disabled:opacity-50 border-gray-400"
                                    />
                                    <br />
                                    <br />
                                    <input
                                        type="text"
                                        value={plateNumber}
                                        onChange={(e) => {
                                            // Filter to allow only alphanumeric characters and convert to uppercase
                                            const filteredValue = e.target.value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
                                            setPlateNumber(filteredValue);
                                        }}
                                        placeholder="Enter Plate Number"
                                        className="border-input ring-offset-background focus:outline-blue-500 placeholder:text-muted-foreground h-12 w-64 rounded-md border hover:border-2 focus-visible:outline-blue-500 bg-transparent px-4 pr-10 py-2 text-md disabled:cursor-not-allowed disabled:opacity-50 border-gray-400"
                                    />

                                    <br />
                                    <br />
                                    <input
                                        type="text"
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                        maxLength={10}
                                        placeholder="Enter Phone Number"
                                        className="border-input ring-offset-background focus:outline-blue-500 placeholder:text-muted-foreground h-12 w-64 rounded-md border hover:border-2 focus-visible:outline-blue-500 bg-transparent px-4 pr-10 py-2 text-md disabled:cursor-not-allowed disabled:opacity-50 border-gray-400"
                                    />
                                </div>

                                <DatePicker
                                    minDate={getTimeNowInAussie()}
                                    selected={selectedDate}
                                    onChange={handleDateChange}
                                    dateFormat="dd/MM/yyyy"
                                    filterDate={(date) => {
                                        const day = date.getDay(); 
                                        return day === 5 || day === 6 ;
                                      }}
                                    className="date-picker mb-4 px-4 border-input items-center justify-center ring-offset-background focus: outline-blue-500 placeholder:text-muted-foreground flex h-12 w-64 rounded-md border hover:border-2 disabled:opacity-50 border-gray-400"
                                />

                                {loading ? (
                                    <div className="loader">Checking availability...</div>
                                ) : (
                                    <div className="flex flex-row space-x-4 justify-center mt-4 py-5">
                                        {status ? (
                                            <>
                                                <button
                                                    onClick={handleSaveChanges}
                                                    disabled={!isDateAvailable || !venue}
                                                    className="w-48 bg-primary h-12 py-2 px-4 text-white font-medium rounded-md disabled:cursor-not-allowed disabled:opacity-50 flex items-center justify-center"
                                                >
                                                    {savingChanges ? (
                                                        <svg className="animate-spin h-5 w-5 mr-3 text-white" viewBox="0 0 24 24">
                                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8z"></path>
                                                        </svg>
                                                    ) : (
                                                        "Save Changes"
                                                    )}
                                                </button>
                                                <button
                                                    onClick={confirmCancelBooking}
                                                    className="w-48 bg-red-600 h-12 py-2 px-4 text-white font-medium rounded-md flex items-center justify-center"
                                                >
                                                    {cancelingBooking ? (
                                                        <svg className="animate-spin h-5 w-5 mr-3 text-white" viewBox="0 0 24 24">
                                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8z"></path>
                                                        </svg>
                                                    ) : (
                                                        "Cancel Booking"
                                                    )}
                                                </button>
                                            </>
                                        ) : (
                                            <div className="text-primary">Fetching booking details...</div>
                                        )}
                                    </div>
                                )}

                                {!isDateAvailable && (
                                    <p className="error-message mt-2 text-primary">
                                        Selected date is occupied. Please choose another date.
                                    </p>
                                )}
                            </>
                        ) : (
                            <div className="text-center">
                                <h2 className="success-message text-2xl font-bold text-green-600 mt-4">
                                    Your booking has been updated successfully!
                                </h2>
                                <div className="mt-4 text-center">
                                    <p><b>Email:</b> {email}</p>
                                    <p><b>Phone: </b>{phone}</p>
                                    <p><b>Plate Number:</b> {plateNumber}</p>
                                    <p><b>Venue:</b> {venue}</p>
                                    <p><b>Date: </b>{selectedDate.toLocaleDateString()}</p>
                                    <br />
                                    <div className="flex justify-center">
                                        <AddToCalendarButton
                                            hideBranding={true}
                                            name={`Booking at ${venue}`}
                                            options={['Google', 'iCal', 'Outlook.com', 'Microsoft365', 'Apple', 'Yahoo']}
                                            location={venue}
                                            description={`Booking Details:\n\nVenue: ${venue}\nDate: ${formattedDate}\nTime: ${formattedTime}\nAmount Paid: $${amount}\nPhone: ${phone}\nEmail: ${email}`}
                                            startDate={new Date(selectedDate).toISOString().slice(0, 10)} // Format to 'YYYY-MM-DD'
                                            endDate={new Date(selectedDate).toISOString().slice(0, 10)}   // Format to 'YYYY-MM-DD'
                                        ></AddToCalendarButton>
                                    </div>
                                    <div className="mt-4 mb-4 flex space-x-4 justify-center">
                                        <button
                                            className="flex items-center justify-center bg-blue-500 text-white px-4 py-2 rounded-md"
                                            onClick={openInGoogleMaps}
                                        >
                                            <MdDirections className="mr-2" /> Google Maps
                                        </button>
                                        <button
                                            className="flex items-center justify-center bg-gray-300 text-gray-700 px-4 py-2 rounded-md"
                                            onClick={openInAppleMaps}
                                        >
                                            <FaApple className="mr-2" /> Apple Maps
                                        </button>
                                    </div>
                                    <div className="flex justify-center">
                                        <button
                                            onClick={() => navigate("/valet")}
                                            className="w-48 bg-gray-400 h-12 py-2 px-4 text-white font-medium rounded-md mr-4"
                                        >
                                            Exit

                                        </button>
                                        <button
                                            onClick={() => navigate("/valet")}
                                            className="w-48 bg-primary h-12 py-2 px-4 text-white font-medium rounded-md"
                                        >
                                            Create Booking
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                </div>
            </div>
        </div>
    );
};

export default EditBooking;
