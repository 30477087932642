import React from "react";
import { UserGroupsModel } from "../../../../../utils/apis/user_groups_repository";
import { Autocomplete, Divider, TextField } from "@mui/material";
import { SelectDropDown } from "../../../../ui/inputs/SelectDropDown";
import { TenantsData, getTenants } from "../../../../../utils/apis/tenants_repository";
import { UserModel, getAllUsers } from "../../../../../utils/apis/user_repository";
import { RulesData } from "../../../../../utils/apis/rules_repository";
import { getUserFromLocalStorage } from "../../../../../utils/helper";

interface UserGroupsBodyProps {
    userGroup?: UserGroupsModel;
    setUserGroup: React.Dispatch<React.SetStateAction<UserGroupsModel>>;
    setShowSubmitButton: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UserGroupsBody: React.FC<UserGroupsBodyProps> = ({ userGroup, setUserGroup, setShowSubmitButton }) => {

    const [tenants, setTenants] = React.useState<TenantsData[]>([]);
    const [tenantSelected, setTenantSelected] = React.useState<string>('');
    const [users, setUsers] = React.useState<UserModel[]>([]);
    const [appliedUsers, setAppliedUsers] = React.useState<UserModel[]>([]);
    const [rules, setRules] = React.useState<RulesData[]>([]);
    const [appliedRules, setAppliedRules] = React.useState<RulesData[]>([]);
    const [userInputWidth, setUserInputWidth] = React.useState<number>(250);
    const [ruleInputWidth, setRuleInputWidth] = React.useState<number>(250);
    const [userGroupName, setUserGroupName] = React.useState<string>('');
    const [userGroupDescription, setUserGroupDescription] = React.useState<string>('');

    React.useEffect(() => {
        const user = getUserFromLocalStorage();
        if (userGroup) {
            setUserGroupName(userGroup.name);
            setUserGroupDescription(userGroup.description);
            setTenantSelected(userGroup.tenant);
        } else {
            setTenantSelected(user.tenant);
        }

        if (user.role === 'SuperAdmin') {
            getTenants().then((data) => {
                setTenants(data);
            })
        } else {
            setTenants(
                [{
                    name: user.tenant,
                    futureBookingPeriod: 0,
                    bookingLimit: 0,
                    lastMinuteBookingPeriod: 1,
                    fulldayDefaultTimes: {
                        startHour: 800,
                        endHour: 1800
                    
                    },
                    gracePeriod: 0,
                    isWeekend: false,
                    isMulti: false,
                    tenantId: user.tenant,
                    tenantRules: [],
                    liveBookingOccupancy: 0,
                    liveGuestOccupancy: 0,
                    liveVIPOccupancy: 0,
                    availableOccupancy: 0,
                    totalAllocation: 0,
                }]
            )
        }

        const userSpecificRules: RulesData[] = [ // vip is not to be included for groups
            {
                title: "Booking Limit",
                summary: "Bookings per week.",
                description: "Restrict users to book only a specific number of times per week.",
                status: true,
                statuses: {},
                value: "1", // string in the datamodel
                tenants: [],
                users: [],
                userGroups: [],
                mobileOption: {}
            },
            {
                title: "Future Bookings",
                summary: "Bookings in advance.",
                description: "How far in the future a booking can be made.",
                status: true,
                statuses: {},
                value: "0", // string in the datamodel
                tenants: [],
                users: [],
                userGroups: [],
                mobileOption: {}
            },
            {
                title: "Weekend",
                summary: "Allow weekend bookings.",
                description: "Allow weekend bookings",
                status: true,
                statuses: {},
                value: "false", // string in the datamodel
                tenants: [],
                users: [],
                userGroups: [],
                mobileOption: {}
            },
            {
                title: "Multiple Days",
                summary: "Allow multiple day bookings.",
                description: "Allow multiple day bookings",
                status: true,
                statuses: {},
                value: "false", // string in the datamodel
                tenants: [],
                users: [],
                userGroups: [],
                mobileOption: {}
            }
        ];
        setRules(userSpecificRules);

        getAllUsers(tenantSelected).then((data) => {
            const filteredUsers = data.filter((user) => user.role !== 'Pending');
            setUsers(filteredUsers);
            if (userGroup) {
                const appliedUserList: UserModel[] = [];
                const appliedRuleList: RulesData[] = [];
                userGroup.users.forEach((user) => {
                    appliedUserList.push(filteredUsers.filter((u) => u.userId === user.userId)[0]);
                });
                userGroup.appliedRules.forEach((rule) => {
                    appliedRuleList.push(userSpecificRules.filter((r) => r.title === rule)[0]);
                });
                setAppliedUsers(appliedUserList);
                setAppliedRules(appliedRuleList);
            }
        })
    }, []); // initial tenants, users and rules lists to pick from

    React.useEffect(() => {
        getAllUsers(tenantSelected).then((data) => {
            const filteredUsers = data.filter((user) => user.role !== 'Pending');
            setUsers(filteredUsers);
        })
    } , [tenantSelected]); // when the tenant is updated, update the users list

    React.useEffect(() => {
        if (appliedUsers.length > 3) {
            if (appliedUsers.length === 3) {
                setUserInputWidth(300);
            } else if (appliedUsers.length === 4) {
                setUserInputWidth(350);
            } else if (appliedUsers.length === 5) {
                setUserInputWidth(400);
            } else if (appliedUsers.length === 6) {
                setUserInputWidth(450);
            } else {
                setUserInputWidth(500);
            }
        } else {
            setUserInputWidth(250);
        }
    }, [appliedUsers]); // as the users are being added, increase the width of the users input field

    React.useEffect(() => {
        if (appliedRules.length > 3) {
            if (appliedRules.length === 3) {
                setRuleInputWidth(300);
            } else if (appliedRules.length === 4) {
                setRuleInputWidth(350);
            } else if (appliedRules.length === 5) {
                setRuleInputWidth(400);
            } else if (appliedRules.length === 6) {
                setRuleInputWidth(450);
            } else {
                setRuleInputWidth(500);
            }
        } else {
            setRuleInputWidth(250);
        }
    }, [appliedRules]); // as the rules are being added, increase the width of the rules input field

    React.useEffect(() => {
        if (userGroupName !== '' && !userGroup) {
            setUserGroup({
                name: userGroupName,
                description: userGroupDescription,
                tenant: tenantSelected,
                users: appliedUsers,
                appliedRules: appliedRules.map((rule) => rule.title)
            });
            // setShowSubmitButton(true);
        } else if (userGroup) {
            if (userGroupName !== userGroup.name || userGroupDescription !== userGroup.description || tenantSelected !== userGroup.tenant || appliedUsers.length !== userGroup.users.length || appliedRules.length !== userGroup.appliedRules.length) {
                // console.log("Got user group. values arent equal")
                setUserGroup({
                    groupId: userGroup.groupId,
                    name: userGroupName,
                    description: userGroupDescription,
                    tenant: tenantSelected,
                    users: appliedUsers,
                    appliedRules: appliedRules.map((rule) => rule.title)
                });
            } else {
                // console.log("Got user group. values are equal")
                setShowSubmitButton(false);
            }
        } else {
            console.log("None")
        }
    }, [userGroupName, userGroupDescription, tenantSelected, appliedUsers, appliedRules ]); // if all the fields are filled or changes were made, gather and set the final userGroup and show the submit button

    return (
        <>
            <div className="flex flex-col items-between mt-12 mx-6">

                <span className='text-md font-medium text-secondaryText my-4'>Group Name</span>
                <div className="custom-select">
                    <TextField className="bg-white w-64" onChange={(e) => {
                        setUserGroupName(e.target.value);
                        // if the value is not empty, show the submit button
                        if (e.target.value !== '') {
                            setShowSubmitButton(true);
                        } else {
                            setShowSubmitButton(false);
                        }
                    }} error={userGroupName === ''} helperText={userGroupName === ''? "Required" : null} value={userGroupName}></TextField>
                </div>

                <span className='text-md font-medium text-secondaryText my-4'>Description</span>
                <div className="custom-select">
                    <TextField className="bg-white w-64" onChange={(e) => {
                        setUserGroupDescription(e.target.value);
                        setShowSubmitButton(true)
                    }} value={userGroupDescription}></TextField>
                </div>

                {/* display only if the tenants list is more than 1 items long, because, for tenant admins, it'll always be their tenant */}
                <div className="custom-select" hidden={tenants.length <= 1}>
                <span className='text-md font-medium text-secondaryText my-4'>Select Tenant</span>
                    <SelectDropDown className="bg-white" onChange={(e) => {
                        setTenantSelected(e.target.value);
                        setShowSubmitButton(true)
                    }
                    } value={tenantSelected}>
                        <option value={''} key={''}>Select Tenant</option>
                        {tenants.map((tenant) => {
                            return (
                                <option value={tenant.name} key={tenant.name}>{tenant.name}</option>
                            )
                        })}
                    </SelectDropDown>
                </div>

                <span className='text-md font-medium text-secondaryText my-4' hidden={userGroupName == ''}>Select Users</span>
                <div hidden={userGroupName == ''}>
                    <Autocomplete
                        disablePortal
                        id="combo-box"
                        options={users.map((user) => user.name)}
                        multiple
                        value={appliedUsers.map((user) => user.name)}
                        limitTags={4}
                        onChange={(event, value) => {
                            setAppliedUsers(value.map((name) => {
                                return users.filter((user) => user.name === name)[0];
                            }));
                            setShowSubmitButton(true)
                        }}
                        sx={{ width: userInputWidth }}
                        renderInput={(params) => <TextField {...params} label="Select Users" />}
                    />
                </div>

                <span className='text-md font-medium text-secondaryText my-4' hidden={userGroupName == ''}>Apply Rules</span>
                <div hidden={userGroupName == ''}>
                    <Autocomplete
                        disablePortal
                        id="combo"
                        options={rules.map((rule) => rule.title)}
                        multiple
                        value={appliedRules.map((rule) => rule.title)}
                        limitTags={4}
                        onChange={(event, value) => {
                            console.log(value);
                            setAppliedRules(value.map((title) => {
                                return rules.filter((rule) => rule.title === title)[0];
                            }));
                            setShowSubmitButton(true)
                        }}
                        sx={{ width: ruleInputWidth }}
                        renderInput={(params) => <TextField {...params} label="Applied Rules" />}
                    />
                </div>
            </div>
        </>
    );
};

export default UserGroupsBody;