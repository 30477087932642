import { Firestore, Query, DocumentData } from "@firebase/firestore";
import { callApi, RequestType } from "../fetch";
import { showToast } from "../toast";
import { getFirestore, query, collection, where, getDocs , runTransaction , doc } from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { getAuth } from "firebase/auth";
import FileSaver from "file-saver";
import axios from "axios";
import { partial } from "lodash";
import log from "../logger";

export interface UserModel {
    bookingLimit: number;
    futureBookingPeriod: number;
    lastMinuteBookingPeriod: number;
    userId: string;
    cardholderId: string;
    gallagherId: string;
    email: string;
    name: string;
    role: string;
    tenant: string;
    permanentParking: boolean;
    vehicles: string[];
}

export interface UserUpdateModel {
    userId: string;
    name: string;
    role: string;
}

export interface GallagherResponse {
    cardholderID: string;
    firstName: string;
    lastName: string;
    company: string;
    plate: string;
}

export interface InviteUserModel {
    email: string;
    gallagherId: string;
}

export interface RejectedUserModel {
    email: string;
    reason: string;
}

export const getAllUsers = async (tenant?: string): Promise<UserModel[]> => {
    try {
        const db = await import("firebase/firestore");
        const firestore: Firestore = db.getFirestore();

        const usersRef: Query<DocumentData> = tenant ? db.query(db.collection(firestore, "users"), db.where("tenant", "==", tenant), db.where("role", "!=", "Pending")) : db.query(db.collection(firestore, "users"), db.where("role", "!=", "Pending"));

        const usersSnap = await db.getDocs(usersRef);

        const userData = usersSnap.docs;

        // Return a list of user data objects
        return userData.map((doc) => ({
            userId: doc.id,
            cardholderId: doc.data().cardholderId,
            email: doc.data().email,
            name: doc.data().name,
            gallagherId: doc.data().gallagherId,
            role: doc.data().role,
            tenant: doc.data().tenant,
            vehicles: doc.data().vehicles,
            permanentParking: doc.data().permanentParking ? doc.data().permanentParking : false,
            bookingLimit: doc.data().bookingLimit,
            futureBookingPeriod: doc.data().futureBookingPeriod,
            lastMinuteBookingPeriod: doc.data().lastMinuteBookingPeriod,
        }));
    } catch (error) {
        console.error("Error fetching users:", error);
        throw error; // You might want to handle or log the error accordingly
    }
};

export const getPendingUsers = async (): Promise<UserModel[]> => {
    try {
        const db = await import("firebase/firestore");
        const firestore: Firestore = db.getFirestore();

        const usersRef: Query<DocumentData> = db.query(db.collection(firestore, "users"), db.where("role", "==", "Pending"));

        const usersSnap = await db.getDocs(usersRef);

        // Return a list of user data objects
        return usersSnap.docs.map((doc) => ({
            userId: doc.id,
            cardholderId: doc.data().cardholderId,
            email: doc.data().email,
            name: doc.data().name,
            gallagherId: doc.data().gallagherId,
            role: doc.data().role,
            tenant: doc.data().tenant,
            vehicles: doc.data().vehicles,
            permanentParking: doc.data().permanentParking ? doc.data().permanentParking : false,
            bookingLimit: doc.data().bookingLimit,
            futureBookingPeriod: doc.data().futureBookingPeriod,
            lastMinuteBookingPeriod: doc.data().lastMinuteBookingPeriod,
        }));
    } catch (error) {
        console.error("Error fetching users:", error);
        throw error; // You might want to handle or log the error accordingly
    }
}

export const getUser = async (userId: string): Promise<UserModel | null> => {
    try {
        const db = await import("firebase/firestore");
        const firestore: Firestore = db.getFirestore();

        const userRef = db.doc(firestore, "users", userId);

        // Get the user document snapshot
        const userSnap = await db.getDoc(userRef);

        // Return a user data object
        return {
            userId: userId,
            cardholderId: userSnap.data()?.cardholderId,
            email: userSnap.data()?.email,
            permanentParking: userSnap.data()?.permanentParking ? userSnap.data()?.permanentParking : false,
            name: userSnap.data()?.name,
            gallagherId: userSnap.data()?.gallagherId,
            role: userSnap.data()?.role,
            tenant: userSnap.data()?.tenant,
            vehicles: userSnap.data()?.vehicles,
            bookingLimit: userSnap.data()?.bookingLimit,
            futureBookingPeriod: userSnap.data()?.futureBookingPeriod,
            lastMinuteBookingPeriod: userSnap.data()?.lastMinuteBookingPeriod,
        };
    } catch (error) {
        console.error("Error fetching user:", error);
        throw error; // You might want to handle or log the error accordingly
    }
};

export const getUserRole = async (userId: string): Promise<string> => {
    try {
        const db = await import("firebase/firestore");
        const firestore: Firestore = db.getFirestore();

        // get document from users collection with the given userId
        const user = db.doc(db.collection(firestore, "users", userId));

        const userSnap = await db.getDoc(user);

        // Return the user's role
        return userSnap.data()?.role;
    } catch (error) {
        console.error("Error fetching user role:", error);
        throw error; // You might want to handle or log the error accordingly
    }
};

export const deleteUser = async (userId: string): Promise<boolean> => {
    try {
        const deleteBody = {
            userId: userId,
        }

        const response = await callApi<null>("updateUserData", deleteBody, RequestType.DELETE);
        if (response?.data) {
            showToast("User deleted sucessfully", { type: "success" });
            return true;
        } else {
            showToast("Something went wrong, please try again", { type: "error" });
            return false;
        }
        log({ collection: "users", documentId: userId, message: "User deleted", type: "delete" });
    } catch (error) {
        console.error("Error deleting user:", error);
        throw error; // You might want to handle or log the error accordingly
    }
}

export const updateUser = async (user: UserUpdateModel): Promise<boolean> => {
    try {

        const editBody = {
            userId: user.userId,
            role: user.role,
            name: user.name,
        }

        const response = await callApi<null>("updateUserData", editBody, RequestType.PUT);

        console.log("response: ", response);

        if (response?.data) {
            showToast("User updated successfully", { type: "success" });
            return true;
        } else {
            showToast("Error updating user", { type: "error" });
            return false;
        }
        log({ collection: "users", documentId: user.userId, message: "User updated", type: "update" });
    } catch (error) {
        console.error("Error updating user:", error);
        return false;
    }
}

export const changeUserAccess = async (userId: string, access: boolean): Promise<boolean> => {
    try {

        const accessChangeBody = {
            userId: userId,
            isDisabled: access
        }

        const response = await callApi<null>("updateUserData", accessChangeBody, RequestType.PUT);

        console.log("response: ", response);

        if (response?.data) {
            showToast(`User successfully ${access ? 'Disabled' : 'Enabled'}`, { type: "success" });
            log({ collection: "users", documentId: userId, message: `User access ${access ? 'disabled' : 'enabled'}`, type: "update" });
            return true;
        } else {
            showToast("Error changing user access", { type: "error" });
            return false;
        }
    } catch (error) {
        console.error("Error changing user access:", error);
        return false;
    }
}

export async function downloadBlob<T extends Blob>(link: string) {
    const response = await axios
        .get(link, {
            responseType: "blob",
        });
    return response.data as T;
}

export const downloadExampleCsv = async (): Promise<boolean> => {
    try {

        // make sure the user is authenticated
        const auth = getAuth();

        if (!auth.currentUser) {
            showToast("You need to be logged in to download the example CSV", { type: "error" });
            return false;
        }

        const storage = getStorage();
        const exampleCsvRef = ref(storage, "example.csv");
        // Get the download URL
        const url = await getDownloadURL(exampleCsvRef);

        console.log("url: ", url);

        const response = await downloadBlob(url);

        console.log("response: ", response);

        FileSaver.saveAs(response, "example.csv");

        return true;

    } catch (error) {
        console.error("Error downloading example CSV:", error);
        return false;
    }
}


export const inviteUsers = async (inviteUserModel: InviteUserModel[]): Promise<InviteUserModel | null> => {
    console.log("inviteUserModel: ", inviteUserModel);

    const response = await callApi<InviteUserModel>("sendInvitationEmail", inviteUserModel, RequestType.POST);

    if (response?.data) {
        showToast("Users invited successfully", { type: "success" });
        return response.data;
    } else {
        showToast("Error inviting users", { type: "error" });
        return null;
    }
};

export interface PartialUserModel {
    cardholderId: string;
    tenant: string;
    plates: string;
}

export const updateUserInFirestore = async (userId: string, partialData: PartialUserModel): Promise<void> => {
    try {

        console.log("userId: ", userId);

        const firestore = getFirestore();
        const userRef = doc(firestore, "users", userId);

        await runTransaction(firestore, async (transaction) => {
            const userDoc = await transaction.get(userRef);
            if (!userDoc.exists()) {
                throw new Error("User not found");
            }

            console.log(partialData)

            const plates =partialData.plates === null ? []  : partialData.plates.includes(';') ?  partialData.plates.split(";").map((plate) => plate.trim()) : [partialData.plates];

            // We can update the user document directly with the partial data
            transaction.update(userRef, {
                gallagherId: partialData.cardholderId,
                tenant: partialData.tenant,
                plates: plates
            });
        });

        console.log("User data updated successfully");
    } catch (error) {
        console.error("Error updating user data in Firestore:", error);
        throw error;
    }
};

export const refreshProfile = async (cardholderId: string) : Promise< PartialUserModel | null> => {
    try{
        const response = await callApi<any>("getCardholderDetails", {cardholderId: cardholderId}, RequestType.POST);

        if(response.data){
            const data = {
                cardholderId: response.data.response.gallagherID,
                tenant: response.data.response.company,
                plates: response.data.response.plate,
            }
            return data;
        }else{
            showToast("Error refreshing profile", { type: "error" });
            return null;
        }
    }catch (error) {
        console.error("Error refreshing profile:", error);
        throw error;
    }
}

export const verifyDetails = async (user: InviteUserModel): Promise<GallagherResponse | null> => {
    // TODO: Implement this function
    const payload = {
        gallagherId: user.gallagherId,
    };
    const response = await callApi<null>("getUserFromIotCentral", payload, RequestType.POST);

    // check firebase if the user exists in the users collection
    const firestore: Firestore = getFirestore();

    const usersEmailRef: Query<DocumentData> = query(collection(firestore, "users"), where("email", "==", user.email.toLocaleLowerCase()));
    const usersGallagherRef = query(collection(firestore, "users"), where("gallagherId", "==", user.gallagherId));

    const usersSnap = await getDocs(usersEmailRef);
    const usersSnapGallagher = await getDocs(usersGallagherRef);

    if (response?.data && usersSnap.docs.length === 0 && usersSnapGallagher.docs.length === 0) {
        const gallagherData: GallagherResponse = response.data["response"];
        return gallagherData;
    } else {
        if (usersSnap.docs.length !== 0) {
            usersSnap.docs.forEach((doc) => {
                if(doc.data().role === "Pending"){
                    showToast("User already invited", { type: "error" });
                } else if(doc.data().role !== "Guest"){
                    showToast("User already exists", { type: "error" });
                }
                return;
            });
        } else {
            if (usersSnapGallagher.docs.length !== 0) {
                showToast("Cardholder Unique ID already exists", { type: "error" });
            } else {
                showToast(`Cardholder Unique ID not found for ${user.gallagherId}`, { type: "error" });
            }
        }
        return null;
    }
};

export const createValetUser = async (user: UserModel): Promise<String> => {
    // console.log("user: ", user);
    let newUserId  = '';
    let action = "updat";
    try {
        const db = await import("firebase/firestore");
        const firestore: Firestore = db.getFirestore();

        const usersRef = db.collection(firestore, "users");
        const allUsers = await getAllUsers();

        // check if the user already exists in the users collection
        const userExists = await allUsers.find((u) => u.email === user.email);
        // console.log("userExists: ", userExists);
        let userDoc;
        if (userExists) {
            action = "updat";
            userDoc = userExists;
        } else {
            action = "creat";
            userDoc = await db.addDoc(usersRef, {
                cardholderId: user.cardholderId,
                email: user.email,
                name: user.name,
                gallagherId: user.gallagherId,
                vehicles: user.vehicles,
                role: "Guest",
                tenant: "VALET",
            }).then((docRef) => {
                newUserId = docRef.id;
                return {
                    userId: docRef.id,
                    cardholderId: user.cardholderId,
                    email: user.email,
                    name: user.name,
                    gallagherId: user.gallagherId,
                    vehicles: user.vehicles,
                    role: user.role,
                    tenant: user.tenant,
                };
            });
            await db.updateDoc(db.doc(firestore, "users", userDoc.userId), {
                userId: userDoc.userId,
            });
        }
        if (userDoc) {
            // console.log("User: ", userDoc);
            return userDoc.userId;
        } else {
            console.log("No user")
            return newUserId;
        }
    } catch (error) {
        console.error("Error " + action + "ing user:", error);
        return newUserId;
    }
}

export const verifyExportUser = async (user: InviteUserModel): Promise<GallagherResponse | RejectedUserModel> => {
    // TODO: Implement this function
    const payload = {
        'gallagherId': user.gallagherId,
    };
    const response = await callApi<null>('getUserFromIotCentral', payload, RequestType.POST);

    // check firebase if the user exists in the users collection
    const firestore: Firestore = getFirestore();

    const usersEmailRef: Query<DocumentData> = query(collection(firestore, 'users'), where('email', '==', user.email.toLocaleLowerCase()));
    const usersGallagherRef = query(collection(firestore, 'users'), where('gallagherId', '==', user.gallagherId));

    const usersSnap = await getDocs(usersEmailRef);
    const usersSnapGallagher = await getDocs(usersGallagherRef);

    if (response?.data && usersSnap.docs.length === 0 && usersSnapGallagher.docs.length === 0) {
        const gallagherData: GallagherResponse = response.data['response'];
        return gallagherData;
    } else {
        if (usersSnap.docs.length !== 0) {
            return {
                email: user.email,
                reason: 'User already exists',
            };
        } else {
            if (usersSnapGallagher.docs.length !== 0) {
                return {
                    email: user.email,
                    reason: 'Cardholder Unique ID already exists',
                };
            } else {
                return {
                    email: user.email,
                    reason: `Cardholder Unique ID not found for ${user.gallagherId}`,

                }
            }
        }

    }
};
