import { Timestamp, Firestore, DocumentData, setDoc, Query, doc, arrayUnion, updateDoc, onSnapshot, getFirestore, collection, query, where, getDocs, deleteDoc, getDoc, addDoc } from "firebase/firestore";
import { showToast } from "../toast";
import { callApi, RequestType } from "../fetch";
import { getUser } from "./user_repository";
import { firestore, getTenant } from "./tenants_repository";
import { formatDateTime } from "../helper";
import log from "../logger";

export interface EventData {
  description: string;
  status: string;
  type: string;
  timestamp: Timestamp;
  violation?: string;
}

export interface OriginalBooking {
  startTime: string;
  endTime: string;
}

export interface Violation {
  title: string;
  description: string;
}

export interface BookingsData {
  bookingId: string;
  bookingType: string;
  email: string;
  endTime: string;
  startTime: string;
  events: EventData[];
  originalBooking?: OriginalBooking;
  permanentParking?: boolean;
  multiID: string;
  plate: string[];
  status: string;
  tenant: string;
  userId: string;
  name: string;
  violation: Violation;
  createdBy: string;
  gallagherId: string | undefined | null;
  phone?: string;
  paymentId? : string;
}

export const getBookings = async (tenant?: string): Promise<BookingsData[]> => {
  try {
    const firestore: Firestore = getFirestore();

    const bookingsRef: Query<DocumentData> = tenant
      ? query(collection(firestore, 'bookings'), where('tenant', '==', tenant))
      : collection(firestore, 'bookings');
    const bookingsSnap = await getDocs(bookingsRef);

    // Return a list of booking data objects
    return bookingsSnap.docs.map((doc) => ({
      bookingId: doc.id,
      bookingType: doc.data().bookingType,
      email: doc.data().email,
      endTime: doc.data().endTime,
      startTime: doc.data().startTime,
      events: doc.data().events,
      multiID: doc.data().multiID,
      plate: doc.data().plate,
      originalBooking: doc.data().originalBooking ? doc.data().originalBooking : undefined,
      status: doc.data().status,
      tenant: doc.data().tenant,
      userId: doc.data().userId,
      name: doc.data().name,
      violation: doc.data().violation,
      createdBy: doc.data().createdBy ?? '',
      gallagherId: doc.data().gallagherId ?? '',
      phone: doc.data().phone ?? '',
      paymentId: doc.data().paymentId ?? '',
    }));
  } catch (error) {
    console.error('Error fetching bookings:', error);
    throw error; // You might want to handle or log the error accordingly
  }
};
export const getBookingHistory = async (tenant?: string): Promise<BookingsData[]> => {
  try {
    const firestore: Firestore = getFirestore();

    const bookingsRef: Query<DocumentData> = tenant
      ? query(collection(firestore, 'history'), where('tenant', '==', tenant))
      : collection(firestore, 'history');
    const bookingsSnap = await getDocs(bookingsRef);

    // Return a list of booking data objects sorted by start time
    const bookings =  bookingsSnap.docs.map((doc) => ({
      bookingId: doc.id,
      bookingType: doc.data().bookingType,
      email: doc.data().email,
      endTime: doc.data().endTime,
      startTime: doc.data().startTime,
      events: doc.data().events,
      multiID: doc.data().multiID,
      plate: doc.data().plate,
      originalBooking: doc.data().originalBooking ? doc.data().originalBooking : undefined,
      status: doc.data().status,
      tenant: doc.data().tenant,
      userId: doc.data().userId,
      name: doc.data().name,
      violation: doc.data().violation,
      createdBy: doc.data().createdBy ?? '',
      gallagherId: doc.data().gallagherId ?? '',
      phone: doc.data().phone ?? '',
      paymentId: doc.data().paymentId ?? '',
    }));

    return bookings;

  } catch (error) {
    console.error('Error fetching bookings:', error);
    throw error; // You might want to handle or log the error accordingly
  }
};
export const createMultiBooking = async (bookingData: Partial<BookingsData>): Promise<boolean> => {
  try {
    const user = await getUser(bookingData.userId ?? '');

    if (!user) {
      showToast('Something went wrong when getting User', { type: 'error' });
      return false;
    }

    const db = await import('firebase/firestore');
    const firestore: Firestore = db.getFirestore();
    const bookingRef = db.collection(firestore, 'bookings');
    const startTime = formatDateTime(bookingData.startTime ?? '');
    const endTime = formatDateTime(bookingData.endTime ?? '');

    console.log('startTime', startTime);
    console.log('endTime', endTime);

    const data: BookingsData = {
      bookingId: '',
      bookingType: bookingData.bookingType ?? '',
      email: user.email,
      endTime: endTime,
      startTime: startTime,
      events: [],
      multiID: '',
      plate: user.vehicles,
      status: 'Pending',
      tenant: user.tenant,
      userId: bookingData.userId ?? '',
      name: user.name,
      violation: { title: '', description: '' },
      createdBy: 'Admin',
      gallagherId: ''
    }

    const bookingId = db.doc(bookingRef).id;

    await db.setDoc(db.doc(bookingRef, bookingId), data);

    return new Promise<boolean>(async (resolve, reject) => {
      const docRef = db.doc(bookingRef, bookingId);
      // Listen for changes using onSnapshot
      const unSubscribe = onSnapshot(docRef, (doc) => {
        // Check if the document exists and has been updated
        if (doc.exists() && doc.data()?.status === "Approved") {
          // Document has been updated
          unSubscribe();
          showToast('Multi Booking created successfully', { type: 'success' });
          resolve(true);
        } else if (doc.exists() && doc.data()?.status === "Rejected") {
          // Document does not exist or has not been updated yet
          unSubscribe();
          showToast(`${doc.data().violation.title}, ${doc.data().violation.description}`, { type: 'error' });
          resolve(false);
        }
      }, (error) => {
        resolve(false);
        // Handle errors
        unSubscribe();
        showToast('Error listening for changes', { type: 'error' });
        console.error('Error listening for changes:', error);
        reject(error);
      });
    });


  } catch (error) {
    showToast('Error creating multi-day booking, Try again', { type: 'error' });
    console.error('Error creating multi-day booking:', error);
    return false;
  }
}

export const createSingleBooking = async (bookingData: Partial<BookingsData>): Promise<boolean> => {
  try {

    const user = await getUser(bookingData.userId ?? '');

    if (!user) {
      showToast('Something went wrong when getting User', { type: 'error' });
      return false;
    }

    console.log(user)

    const db = await import('firebase/firestore');
    const firestore: Firestore = db.getFirestore();
    const bookingRef = db.collection(firestore, 'bookings');
    const startTime = formatDateTime(bookingData.startTime ?? '');
    const endTime = formatDateTime(bookingData.endTime ?? '');


    // get document id before adding to firestore
    const bookingId = db.doc(bookingRef).id;
    const data: BookingsData = {
      bookingId: bookingId,
      bookingType: bookingData.bookingType ?? '',
      email: user.email,
      endTime: endTime,
      startTime: startTime,
      events: [],
      multiID: '',
      plate: user.vehicles,
      status: 'Pending',
      tenant: user.tenant,
      userId: bookingData.userId ?? '',
      name: user.name,
      violation: { title: '', description: '' },
      createdBy: 'Admin',
      gallagherId: user.gallagherId ?? ''
    }
    await setDoc(db.doc(bookingRef, bookingId), data);

    const docRef = doc(bookingRef, bookingId);

    return new Promise<boolean>(async (resolve, reject) => {
      const unSubscribe = onSnapshot(docRef, (doc) => {
        // Check if the document exists and has been updated
        if (doc.exists() && doc.data()?.status === "Approved") {
          // Document has been updated
          showToast('Booking created successfully', { type: 'success' });
          unSubscribe();
          resolve(true);
        } else if (doc.exists() && doc.data()?.status === "Rejected") {
          // Document does not exist or has not been updated yet
          showToast(`${doc.data().violation.title}, ${doc.data().violation.description}`, { type: 'error' });
          unSubscribe();
          resolve(false);
        }
      }, (error) => {
        // Handle errors
        showToast('Error listening for changes', { type: 'error' });
        unSubscribe();
        console.error('Error listening for changes:', error);
        reject(error);
      });
    });
  } catch (error) {
    showToast('Error creating booking, Try again', { type: 'error' });

    console.error('Error creating booking:', error);
    return false; // You might want to handle or log the error accordingly
  }
}

export const editSingleBooking = async (bookingData: Partial<BookingsData>): Promise<boolean> => {
  console.log('single update bookingData', bookingData);
  try {
    // get the document 
    const bookingRef = collection(firestore, 'bookings');

    const event: EventData = {
      description: '',
      status: 'Pending',
      type: 'Update',
      timestamp: Timestamp.now(),
    }

    bookingData.startTime = formatDateTime(bookingData.startTime ?? '');
    bookingData.endTime = formatDateTime(bookingData.endTime ?? '');

    // update booking data
    await updateDoc(doc(bookingRef, bookingData.bookingId), {
      ...bookingData,
      status: 'Pending',
      events: arrayUnion(event),
      startTime: bookingData.startTime,
      endTime: bookingData.endTime,
    });
    await new Promise(resolve => setTimeout(resolve, 1000));

    return new Promise<boolean>((resolve, reject) => {
      const docRef = doc(bookingRef, bookingData.bookingId);
      // Listen for changes using onSnapshot
      const unSubscribe = onSnapshot(docRef, (doc) => {
        // Check if the document exists and has been updated
        if (doc.exists()) {
          const booking = doc.data() as BookingsData;
          const lastEvent = booking.events[booking.events.length - 1];
          if (lastEvent.status === "Approved") {
            // Document has been updated
            showToast('Booking updated successfully', { type: 'success' });
            unSubscribe();
            resolve(true);
          } else if (lastEvent.status.includes("Rejected")) {
            // Document does not exist or has not been updated yet
            showToast(`Booking Rejected, ${lastEvent.violation}`, { type: 'error' });
            unSubscribe();
            resolve(false);
          }
        }
      }, (error) => {
        // Handle errors
        showToast('Error listening for changes', { type: 'error' });
        unSubscribe();
        console.error('Error listening for changes:', error);
        reject(error);
      });
    });
  } catch (error) {
    showToast('Error editing booking, Try again', { type: 'error' });
    console.error('Error editing booking:', error);
    throw error; // You might want to handle or log the error accordingly
  }
};

export const editMultiBooking = async (bookingData: Partial<BookingsData>) => {
  console.log('multi update bookingData', bookingData);

  const startTime = formatDateTime(bookingData.startTime ?? '');
  const endTime = formatDateTime(bookingData.endTime ?? '');

  const payload = {
    "multiId": bookingData.multiID,
    "startTime": startTime,
    "endTime": endTime,
  }

  const response = await callApi<null>('editMultiDayBooking', payload, RequestType.POST);

  if (response?.data) {
    showToast('Multi-day booking updated successfully', { type: 'success' });
  } else {
    showToast('Error updating multi-day booking', { type: 'error' });
  }
}


export const deleteSingleBooking = async (bookingId: string) => {
  try {
    const db = await import('firebase/firestore');
    const firestore: Firestore = db.getFirestore();
    const bookingRef = db.collection(firestore, 'bookings');
    await db.deleteDoc(db.doc(bookingRef, bookingId));
    log({collection: 'bookings', documentId: bookingId, type: 'delete', message: 'Single booking deleted'});
    showToast('Booking deleted successfully', { type: 'success' });
  } catch (error) {
    showToast('Error deleting booking, Try again', { type: 'error' });
    throw error;
  }
}

export const deleteMultiBooking = async (multiId: string) => {

  const payload = {
    'multiId': multiId,
  };

  const response = await callApi<null>('deleteMultiDayBooking', payload, RequestType.POST);

  if (response?.data) {
    log({collection: 'bookings', documentId: multiId, type: 'delete', message: 'Multi-day booking deleted'});
    showToast('Multi-day booking deleted successfully', { type: 'success' });
  } else {
    showToast('Error deleting multi-day booking', { type: 'error' });
  }

}

export const createValetBooking = async (bookingData: any): Promise<{ bookingId: string | null, isApproved: boolean }> => {
  try {
    const bookingRef = collection(firestore, 'bookings');

    const setTime = (date: string, hours: number, minutes: number, seconds: number): string => {
      const dt = new Date(date);
      dt.setHours(hours, minutes, seconds, 0);
      const dateString = dt.toISOString().split('T')[0]; // Extract date in YYYY-MM-DD format
      const timeString = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}.000`; // Create time string in HH:MM:SS.sss format
      return `${dateString}T${timeString}Z`; // Combine date and time in the required format
    };

    const tenant = await getTenant('VALET');
    const startHour = tenant.fulldayDefaultTimes.startHour ? Math.floor(tenant.fulldayDefaultTimes.startHour / 100) : 12;
    const startMinute = tenant.fulldayDefaultTimes.startHour ? tenant.fulldayDefaultTimes.startHour % 100 : 0;
    const endHour = tenant.fulldayDefaultTimes.endHour ? Math.floor(tenant.fulldayDefaultTimes.endHour / 100) : 23;
    const endMinute = tenant.fulldayDefaultTimes.endHour ? tenant.fulldayDefaultTimes.endHour % 100 : 59;

    let startTime: string;
    if (bookingData.bookingStartTime) {
      const [hours, minutes, seconds] = bookingData.bookingStartTime.split(':').map(Number);
      startTime = setTime(bookingData.startTime ?? '', hours, minutes, seconds);
    } else {
      startTime = setTime(bookingData.startTime ?? '', startHour, startMinute, 0);
    }
    const endTime = setTime(bookingData.startTime ?? '', endHour, endMinute, 0);
    const startTimeNew = setTime(bookingData.startTime ?? '', startHour, startMinute, 0);
    const plateArray = bookingData.plateNumber ? [bookingData.plateNumber] : [];

    const data = {
      plate: [],
      startTime: startTimeNew,
      endTime: endTime,
      tenant: 'VALET',
      status: 'Pending',
      violation: { title: '', description: '' },
      email: bookingData.email,
      userId: bookingData.userId,
      name: bookingData.name,
      bookingType: 'Guest',
      timeType: 'FullDay',
      events: [],
      multiId: '',
      phone: bookingData.phone,
      venue: bookingData.venue,
    };

    const docRef = await addDoc(bookingRef, data);
    log({collection: 'bookings', documentId: docRef.id, type: 'create', message: 'Valet booking created'});
    await new Promise(resolve => setTimeout(resolve, 3000));
    return new Promise<{ bookingId: string | null, isApproved: boolean }>((resolve, reject) => {
      const unSubscribe = onSnapshot(docRef, (doc) => {
        if (doc.exists()) {
          const bookingStatus = doc.data()?.status;
          const cardholderID = doc.data()?.cardholderId;
          const plate = doc.data()?.plate;
    
          if (bookingStatus === "Approved") {
            // if (new Date().toISOString().split('T')[0] === new Date(bookingData.startTime).toISOString().split('T')[0] && plate.length > 0) {
            //   fetch(`${process.env.REACT_APP_FB_ADDPLATE}`, {
            //     method: 'POST',
            //     headers: { 'Content-Type': 'application/json' },
            //     body: JSON.stringify({ cardholderId: cardholderID, plate: bookingData.plateNumber })
            //   })
            //   .then(response => {
            //     if (!response.ok) {
            //       throw new Error(`Network response was not ok: ${response.statusText}`);
            //     }
            //     return response.json();
            //   })
            //   .then((data) => {
            //     // You can print the response here
            //     console.log("API Response: ", data);
              
            //     // Handle successful plate addition
            //     showToast('Booking created and plate added successfully', { type: 'success' });
                
            //     // Unsubscribe only after the fetch completes
            //     unSubscribe();
            //     resolve({ bookingId: docRef.id, isApproved: true });
            //   })
            //   .catch(error => {
            //     console.error('There was a problem with the fetch operation:', error);
            //     showToast('Booking added successfully, but we were unable to add your plate', { type: 'error' });
                
            //     // Unsubscribe in case of error too
            //     unSubscribe();
            //     resolve({ bookingId: docRef.id, isApproved: true });
            //   });
            // } else {
            //   // If no plate or date condition fails, resolve directly
            //   unSubscribe();
            //   resolve({ bookingId: docRef.id, isApproved: true });
            // }
                unSubscribe();
                resolve({ bookingId: docRef.id, isApproved: true });
          } else if (bookingStatus === "Rejected") {
            const violation = doc.data()?.violation;
            const errorMessage = violation ? `${violation.title}, ${violation.description}` : 'Booking rejected';
            showToast(errorMessage, { type: 'error' });
            unSubscribe();
            resolve({ bookingId: null, isApproved: false });
          }
        }
      }, (error) => {
        showToast('Error listening for changes', { type: 'error' });
        unSubscribe();
        console.error('Error listening for changes:', error);
        reject(error);
      });
    });
    
  } catch (error) {
    showToast('Error creating booking, Try again', { type: 'error' });
    console.error('Error creating booking:', error);
    return { bookingId: null, isApproved: false };
  }
};


export const getValetBookingDetails = async (bookingId: string): Promise<any> => {
  try {
    const bookingRef = doc(firestore, 'bookings', bookingId);
    const bookingSnapshot = await getDoc(bookingRef);

    if (bookingSnapshot.exists()) {
      return bookingSnapshot.data();
    } else {
      throw new Error('Booking not found');
    }
  } catch (error) {
    console.error('Error fetching booking details:', error);
    throw error;
  }
};

export const updateValetBooking = async (bookingData: any): Promise<boolean> => {
  try {
    const bookingRef = collection(firestore, 'bookings');
    const usersRef = collection(firestore, 'users');

    const setTime = (startDateTime: string, hours: number, minutes: number): string => {
      const dt = new Date(startDateTime);
      dt.setUTCHours(hours, minutes, 0, 0);
      const dateString = dt.toISOString().split('T')[0];
      const timeString = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:00.000`;
      return `${dateString}T${timeString}Z`;
    };

    const tenant = await getTenant('VALET');
    const startHour = tenant.fulldayDefaultTimes.startHour ? Math.floor(tenant.fulldayDefaultTimes.startHour / 100) : 12;
    const startMinute = tenant.fulldayDefaultTimes.startHour ? tenant.fulldayDefaultTimes.startHour % 100 : 0;
    const endHour = tenant.fulldayDefaultTimes.endHour ? Math.floor(tenant.fulldayDefaultTimes.endHour / 100) : 23;
    const endMinute = tenant.fulldayDefaultTimes.endHour ? tenant.fulldayDefaultTimes.endHour % 100 : 59;

    const startDate = bookingData.startTime.split('T')[0];
    const startTime = setTime(`${startDate}T00:00:00.000Z`, startHour, startMinute);
    const endTime = setTime(`${startDate}T00:00:00.000Z`, endHour, endMinute);

    const existingBookingDoc = await getDoc(doc(bookingRef, bookingData.bookingId));
    if (!existingBookingDoc.exists()) {
      showToast('Booking not found', { type: 'error' });
      throw new Error('Booking not found');
    }
    const existingBookingData = existingBookingDoc.data();
    const existingUserId = existingBookingData.userId;

    const event: EventData = {
      description: '',
      status: 'Pending',
      type: 'Update',
      timestamp: Timestamp.now(),
    }

    const q = query(usersRef, where("email", "==", bookingData.email));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      showToast('User not found for the provided email', { type: 'error' });
      throw new Error('User not found');
    }

    // Check if the start time is today
    const today = new Date();
    const dateOnly = startTime.split('T')[0];
    const startDateTime = new Date(dateOnly);
    const isToday = today.toISOString().split('T')[0] === startTime.split('T')[0];
    console.log("plate length ", existingBookingData.plate.length);

    if (isToday) {
      if (existingBookingData.plate.length > 0) {
        // There is an existing plate
        if (bookingData.plateNumber !== '') {
          // There is a new plate number to update
          await fetch(`${process.env.REACT_APP_FB_ADDPLATE}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              cardholderId: existingBookingData.cardholderId,
              removePlate: existingBookingData.plate[0],  // Remove the existing plate
              addPlate: bookingData.plateNumber           // Add the new plate
            })
          }).catch(error => {
            console.error('Error updating plate:', error);
            showToast(`Error updating plate: ${error.message}`, { type: 'error' });
          });
        } else {
          // Plate number is empty, so delete the existing plate
          await fetch(`${process.env.REACT_APP_FB_ADDPLATE}`, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              cardholderId: existingBookingData.cardholderId,
              plate: existingBookingData.plate[0]   // Remove the existing plate
            })
          }).catch(error => {
            console.error('Error deleting plate:', error);
            showToast(`Error deleting plate: ${error.message}`, { type: 'error' });
          });
        }
      } else {
        // There is no existing plate
        if (bookingData.plateNumber !== '') {
          // There is a new plate number to add
          await fetch(`${process.env.REACT_APP_FB_ADDPLATE}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              cardholderId: existingBookingData.cardholderId,
              plate: bookingData.plateNumber              // Add the new plate
            })
          }).catch(error => {
            console.error('Error adding plate:', error);
            showToast(`Error adding plate: ${error.message}`, { type: 'error' });
          });
        } else {
          // No existing plate and no new plate number provided
          console.log('No existing plate and no new plate number provided. Not adding plates.');
        }
      }
    } else {
      console.log('Start time is not today. Not updating plates.');
    }

    const existingStartTime = new Date(existingBookingData.startTime);
    const newStartTime = new Date(bookingData.startTime);

    // Check if the existing start time is today
    const existingIsToday = today.toDateString() === existingStartTime.toDateString();
    // Check if the new start time is not today
    const newIsNotToday = today.toDateString() !== newStartTime.toDateString();

    if (existingIsToday && newIsNotToday) {
      const newBookingData = {
        // Existing data fields
        ...existingBookingData,
    
        // Fields from the new bookingData (overriding existing ones if provided)
        plate: bookingData.plateNumber ? [bookingData.plateNumber] : existingBookingData.plate,  // Ensure it's an array, fallback to existing data if empty
        startTime: startTime?.toString() ?? existingBookingData.startTime,  // Fallback to existing data if undefined
        endTime: endTime?.toString() ?? existingBookingData.endTime,        // Fallback to existing data if undefined
        email: bookingData.email ?? existingBookingData.email,              // Ensure email is provided, fallback to existing
        phone: bookingData.phone ?? existingBookingData.phone,              // Fallback to existing if phone is not provided
        venue: bookingData.venue ?? existingBookingData.venue,              // Ensure venue is provided, fallback to existing
        bookingId: bookingData.bookingId ?? existingBookingData.bookingId,  // Ensure bookingId is provided
        events: arrayUnion(event),                                          // Add new event, while preserving existing events
        status: 'Pending',  // Update the status
        // Ensure all other fields that should remain the same come from existingBookingData
        userId: existingBookingData.userId,  // Keep the existing userId
        tenant: 'VALET',  // Keep the tenant information
        violation: existingBookingData.violation || { title: '', description: '' }, // Fallback to existing violation
        bookingType: existingBookingData.bookingType || 'Guest',  // Fallback to existing or default
        timeType: existingBookingData.timeType || 'FullDay',  // Fallback to existing or default
        multiId: existingBookingData.multiId || '',  // Fallback to existing multiId or empty string
      };
    
      // Now, create the new valet booking using the newBookingData object
      await createValetBooking(newBookingData);
      
      await deleteValetBooking(existingBookingData.bookingId);
      
    } else if (!existingIsToday && !newIsNotToday) {
      if (bookingData.plateNumber !== '') {
        // There is a plate in bookingData to add
        await fetch(`${process.env.REACT_APP_FB_ADDPLATE}`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            cardholderId: existingBookingData.cardholderId,
            plate: bookingData.plateNumber              // Add the new plate
          })
        }).catch(error => {
          console.error('Error adding plate:', error);
          showToast(`Error adding plate: ${error.message}`, { type: 'error' });
        });
      } else {
        console.log('No plate number provided in bookingData.');
      }

    } else {
      console.log('Either the existing start time is not today or the new start time is today. Not deleting plates.');
    }


    const plateArray = bookingData.plateNumber ? [bookingData.plateNumber] : [];
    const data = {
      plate: plateArray,
      startTime: startTime.toString(),
      endTime: endTime.toString(),
      tenant: 'VALET',
      status: 'Pending',
      violation: { title: '', description: '' },
      email: bookingData.email,
      userId: existingUserId,
      bookingType: 'Guest',
      timeType: 'FullDay',
      bookingId: bookingData.bookingId,
      events: arrayUnion(event),
      multiId: '',
      phone: bookingData.phone,
      venue: bookingData.venue,
    };

    await updateDoc(doc(bookingRef, bookingData.bookingId), data);

    await new Promise(resolve => setTimeout(resolve, 1000));

    return new Promise<boolean>((resolve, reject) => {
      const docRef = doc(bookingRef, bookingData.bookingId);

      const unSubscribe = onSnapshot(docRef, (doc) => {
        if (doc.exists()) {
          const bookings = doc.data();
          const lastEvent = bookings.events[bookings.events.length - 1];
          if (lastEvent.status === "Approved") {
            showToast('Booking updated successfully', { type: 'success' });
            unSubscribe();
            resolve(true);
          } else if (lastEvent.status === "Rejected") {
            const violation = doc.data()?.violation;
            const errorMessage = violation ? `${violation.title}, ${violation.description}` : 'Booking rejected';
            showToast(errorMessage, { type: 'error' });
            unSubscribe();
            resolve(false);
          }
        }
      }, (error) => {
        showToast('Error listening for changes', { type: 'error' });
        unSubscribe();
        console.error('Error listening for changes:', error);
        reject(error);
      });
    });
  } catch (error) {
    showToast('Error updating booking, Try again', { type: 'error' });
    console.error('Error updating booking:', error);
    return false;
  }
};

// Function to delete the valet booking
export const deleteValetBooking = async (bookingId: string) => {
  try {
    const bookingRef = collection(firestore, 'bookings');
    const bookingSnapshot = await getDoc(doc(bookingRef, bookingId));
    const bookingData = bookingSnapshot.data();

    if (bookingData) {
      // Check if the start time is today
      const today = new Date();
      const startDateTime = new Date(bookingData.startTime);
      const isToday = today.toDateString() === startDateTime.toDateString();

      if (isToday && bookingData.plate.length > 0) {
        // Remove the plate from the IoT central system before deleting the booking
        await fetch(`${process.env.REACT_APP_FB_ADDPLATE}`, {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ cardholderId: bookingData.cardholderId, plate: bookingData.plate[0] })
        }).catch(error => {
          console.error('Error removing plate:', error);
        });
      }

      await deleteDoc(doc(bookingRef, bookingId));
      if (bookingData.paymentId && bookingData.paymentId !== "" ) {
        console.log("Payment was made for the booking. Sending cancellation email");
        await callApi<null>('sendValetCancellationEmail', {
          email: bookingData.email,
          bookingId: bookingId,
          bookingDate: bookingData.startTime,
          name: bookingData.name,
        }, RequestType.POST);
      }
      log({collection: 'bookings', documentId: bookingId, type: 'delete', message: 'Valet booking deleted'});
      // showToast('Booking deleted successfully', { type: 'success' });
    } else {
      showToast('Booking not found', { type: 'error' });
      throw new Error('Booking not found');
    }
  } catch (error) {
    showToast('Error deleting booking, Try again', { type: 'error' });
    console.error('Error deleting booking:', error);
    throw error;
  }
};


export const addPaymentIdToBooking = async (
  bookingId: string,
  paymentId: string,
  customerId: string,
  plateNumber: string
): Promise<boolean> => {
  try {
    const bookingRef = collection(firestore, 'bookings');
    
    // Retrieve the existing booking document
    const existingBookingDoc = await getDoc(doc(bookingRef, bookingId));
    if (!existingBookingDoc.exists()) {
      showToast('Booking not found', { type: 'error' });
      throw new Error('Booking not found');
    }

    // Get existing booking data
    const existingBookingData = existingBookingDoc.data();
    const cardholderID = existingBookingData.cardholderId;
    
    // Initialize plateArray as empty
    let plateArray: string[] = [];
    
    // Try adding the plate to IoT Central (onsite module)
    if (new Date().toISOString().split('T')[0] === new Date(existingBookingData.startTime).toISOString().split('T')[0] && plateNumber) {
      try {
        const response = await fetch(`${process.env.REACT_APP_FB_ADDPLATE}`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ cardholderId: cardholderID, plate: plateNumber }),
        });

        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }

        const data = await response.json();
        console.log("API Response: ", data);
        showToast('Plate added to onsite module successfully', { type: 'success' });
        
        plateArray = [plateNumber];
      } catch (error) {
        console.error('Failed to add plate to onsite module:', error);
        showToast('Payment completed but failed to add plate', { type: 'error' });
      }
    }

    const updatedBookingData = {
      plate: plateArray,
      startTime: existingBookingData.startTime.toString(),
      endTime: existingBookingData.endTime.toString(),
      tenant: existingBookingData.tenant,
      status: existingBookingData.status,
      violation: { title: '', description: '' },
      email: existingBookingData.email,
      userId: existingBookingData.userId,
      bookingType: existingBookingData.bookingType,
      timeType: existingBookingData.timeType,
      bookingId: existingBookingData.bookingId,
      multiId: '',
      phone: existingBookingData.phone,
      venue: existingBookingData.venue,
      paymentId: paymentId,
      customerId: customerId,
      events: existingBookingData.events,
    };

    // Update the booking document with the new booking data
    await updateDoc(doc(bookingRef, bookingId), updatedBookingData);

    showToast('Payment completed', { type: 'success' });
    return true;

  } catch (error) {
    showToast('Error completing Payment, try again', { type: 'error' });
    console.error('Error adding Payment ID and event:', error);
    return false;
  }
};




export const addOverstayEventToBooking = async (bookingId: string): Promise<boolean> => {
  try {
    const bookingRef = collection(firestore, 'bookings');

    // Retrieve the existing booking document
    const existingBookingDoc = await getDoc(doc(bookingRef, bookingId));
    if (!existingBookingDoc.exists()) {
      showToast('Booking not found', { type: 'error' });
      throw new Error('Booking not found');
    }

    // Get existing booking data
    const existingBookingData = existingBookingDoc.data();
    const event: EventData = {
      description: 'Overstay Payment',
      status: 'Approved',
      type: 'Overstay',
      timestamp: Timestamp.now(),
    }

    // Append the paymentId to the existing booking data and add the new event to the events array
    const updatedBookingData = {
      plate: existingBookingData.plate,
      startTime: existingBookingData.startTime.toString(),
      endTime: existingBookingData.endTime.toString(),
      tenant: existingBookingData.tenant,
      status: existingBookingData.status,
      violation: { title: '', description: '' },
      email: existingBookingData.email,
      userId: existingBookingData.userId,
      bookingType: existingBookingData.bookingType,
      timeType: existingBookingData.timeType,
      bookingId: existingBookingData.bookingId,
      multiId: '',
      phone: existingBookingData.phone,
      venue: existingBookingData.venue,
      events: arrayUnion(event),
    };

    // Update the booking document with the updated booking data
    await updateDoc(doc(bookingRef, bookingId), updatedBookingData);
    log({collection: 'bookings', documentId: bookingId, type: 'update', message: 'Overstay event added to booking'});
    return true;
  } catch (error) {
    showToast('Error completing Payment, try again', { type: 'error' });
    console.error('Error adding Payment ID and event:', error);
    return false;
  }
};


export const getBookingDetailsFromHistory = async (bookingId: string): Promise<any> => {
  try {
    const bookingRef = doc(firestore, 'history', bookingId);
    const bookingSnapshot = await getDoc(bookingRef);

    if (bookingSnapshot.exists()) {
      return bookingSnapshot.data();
    } else {
      throw new Error('Booking not found in history');
    }
  } catch (error) {
    console.error('Error fetching booking details from history:', error);
    throw error;
  }
};
