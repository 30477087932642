import React from 'react';

interface EmailChipProps {
  email: string;
}

const EmailChip: React.FC<EmailChipProps> = ({ email }) => {
  return (
    <div className="flex flex-row">
      <div className="text-primaryText bg-status-approved-shaded inline-flex border-status-approved text-status-approved border-2 rounded-md px-5 justify-center items-center py-4">
        {email}
      </div>
    </div>
  );
};

export default EmailChip;
