import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div className="App text-gray-400 font-bold rounded-lg">
      <header className="App-header">
        <h1 className="text-lg ">: /</h1>
        <br />
        <h1 className="text-md">
          We searched everywhere, but couldn't find the page you're looking for.
        </h1>
        <br />
        <p className="text-md">
          May be you're looking for the {" "}
          <a
            href="/signin"
            className="text-lg text-orange-500 font-bold hover:text-green-900 hover:bg-gray-100 cursor-pointer p-1 rounded-lg"
          >
            Login Page
          </a>
          ?
        </p>
      </header>
    </div>
  );
}
