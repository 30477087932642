import { Autocomplete, TextField } from '@mui/material';
import { Text } from '../../../../Text';

interface AppliedUserGroupsProps {
    appliedUserGroups: string[];
}

export const AppliedUserGroups: React.FC<AppliedUserGroupsProps> = ({ appliedUserGroups }) => {
    return (
        <>
            <Text className="mt-10" as="h3">
                Applied User Groups
            </Text>
            <Autocomplete
                disabled
                disablePortal
                id='usergroups'
                options={[]}
                multiple
                value={appliedUserGroups}
                sx={{ width: 460 }}
                renderInput={(params) => <TextField {...params} label="User Groups" />}
                className='my-4'
            />
        </>
    );
}

export default AppliedUserGroups;