import { Firestore, getFirestore , getDocs, collection, query, where } from "firebase/firestore";
import { onboardTenant, offboardTenant, BookingTimesInterface } from "./rules_repository";
import log from "../logger";

export interface TenantsData {
    name: string;
    gracePeriod: number;
    futureBookingPeriod: number;
    bookingLimit: number;
    lastMinuteBookingPeriod: number;
    fulldayDefaultTimes: BookingTimesInterface;
    liveBookingOccupancy: number;
    liveGuestOccupancy: number;
    liveVIPOccupancy: number;
    availableOccupancy: number;
    totalAllocation : number;
    isWeekend: boolean;
    isMulti: boolean;
    tenantId: string;
    tenantRules: string[];
    locations?: string[];
    autoEnroll?: boolean;
    lprAccess?: boolean;
    maxPlates?: number;
    image?: string;
    towerLocation?: string;
    bookingApp?: boolean;
    bookingAppData?: {
        reEntry: boolean;
        noBookingEntry: boolean;
        permanentParker: number;
        reservedAllocation: number;
        liveBookingOccupancy: number;
        livePermanentParkingOccupancy: number;
        accessRequired: boolean;
    }
}

export const firestore: Firestore = getFirestore();


export const getTenants = async (): Promise<TenantsData[]> => {
    try {
        const tenantsRef = collection(firestore, "tenants");
        const tenantsSnap = await getDocs(tenantsRef);

        const tenants = tenantsSnap.docs
            .map((doc) => ({
                name: doc.data().name,
                gracePeriod: doc.data().gracePeriod ?? 15, // ideally rule.value parsed to Int
                futureBookingPeriod: doc.data().futureBookingPeriod ?? 0,
                bookingLimit: doc.data().bookingLimit ?? 0,
                lastMinuteBookingPeriod: doc.data().lastMinuteBookingPeriod ?? 1,
                fulldayDefaultTimes: doc.data().fulldayDefaultTimes ?? {},
                isWeekend: doc.data().isWeekend,
                isMulti: doc.data().isMulti,
                tenantId: doc.data().tenantId,
                tenantRules: doc.data().tenantRules ?? [],
                liveBookingOccupancy: doc.data().liveBookingOccupancy ?? 0,
                liveGuestOccupancy: doc.data().liveGuestOccupancy ?? 0,
                liveVIPOccupancy: doc.data().liveVIPOccupancy ?? 0,
                availableOccupancy: doc.data().availableOccupancy ?? 0,
                totalAllocation : doc.data().totalAllocation ?? 0,
                autoEnroll: doc.data().autoEnroll ?? false,
                lprAccess: doc.data().lprAccess ?? false,
                maxPlates: doc.data().maxPlates ?? 0,
                towerLocation: doc.data().towerLocation ?? "",
                locations: doc.data().locations ?? [],
            }));
        return tenants;
    } catch (error) {
        console.error("Error fetching tenants:", error);
        throw error;
    }
};

export const getTenant = async (tenantName: string): Promise<TenantsData> => {
    try {

        // const tenantsRef = collection(firestore, "tenants");
        // const tenantsSnap = await getDocs(tenantsRef);
        // const tenantDoc = tenantsSnap.docs.find((doc) => doc.data().name === tenantName);

        // const tenant = tenantDoc?.data();
        const tenantsRef = collection(firestore, "tenants");
        const tenantQuery = query(tenantsRef, where("name", "==", tenantName));
        
        const tenantSnap = await getDocs(tenantQuery);
        const tenant = tenantSnap.docs[0]?.data();
        if(tenant) {
            return {
                name: tenant.name,
                gracePeriod: tenant.gracePeriod,
                futureBookingPeriod: tenant.futureBookingPeriod,
                bookingLimit: tenant.bookingLimit,
                lastMinuteBookingPeriod: tenant.lastMinuteBookingPeriod,
                fulldayDefaultTimes: tenant.fulldayDefaultTimes,
                isWeekend: tenant.isWeekend,
                isMulti: tenant.isMulti,
                tenantId: tenant.tenantId,
                tenantRules: tenant.tenantRules,
                liveBookingOccupancy: tenant.liveBookingOccupancy,
                liveGuestOccupancy: tenant.liveGuestOccupancy,
                liveVIPOccupancy: tenant.liveVIPOccupancy,
                availableOccupancy: tenant.availableOccupancy,
                totalAllocation : tenant.totalAllocation,
                autoEnroll: tenant.autoEnroll,
                lprAccess: tenant.lprAccess,
                maxPlates: tenant.maxPlates,
                towerLocation: tenant.towerLocation,
            };
        } else {
            throw new Error("Tenant not found");
        }
    } catch (error) {
        console.error("Error fetching tenant:", error);
        throw error;
    }
};

export const updateTenant = async (tenant: TenantsData): Promise<void> => {
    console.log("Updating tenant: ", tenant);
    try {
        const db = await import("firebase/firestore");
        const firestore: Firestore = db.getFirestore();

        const tenantsRef = db.collection(firestore, "tenants");
        const tenantsSnap = await db.getDocs(tenantsRef);
        const tenantDoc = tenantsSnap.docs.find((doc) => doc.data().name === tenant.name);

        const tenantId = tenantDoc?.id;

        if(tenantId) {
            await db.updateDoc(tenantDoc.ref, {
                gracePeriod: tenant.gracePeriod,
                futureBookingPeriod: tenant.futureBookingPeriod,
                bookingLimit: tenant.bookingLimit,
                isWeekend: tenant.isWeekend,
                isMulti: tenant.isMulti,
                totalAllocation: tenant.totalAllocation,
                maxPlates: tenant.maxPlates,
                autoEnroll: tenant.autoEnroll,
                lprAccess: tenant.lprAccess,
                towerLocation: tenant.towerLocation,
                locations: tenant.locations,
            });
            log({collection: 'tenants', documentId: tenantId, message: 'Tenant updated', type: 'update'});
        } else {
            await db.addDoc(tenantsRef, {
                name: tenant.name,
                totalAllocation: tenant.totalAllocation
            }).then(async (docRef) => {
                console.log('Tenant initialized');
                log({collection: 'tenants', documentId: docRef.id, message: 'Tenant initialized', type: 'create'});
                // add id to tenant object
                await db.updateDoc(docRef, {
                    tenantId: docRef.id
                }).then(async () => {
                    // add tenant name to tenant only rules "tenants" array and set status of tenant in "statuses" to false
                    await onboardTenant(tenant.name).then(() => {
                        // showToast("Tenant added successfully", { type: "success"});
                    return;
                    });
                });
            });
        }
    } catch (error) {
        console.error("Error updating tenant:", error);
        // showToast("Error updating tenant. "+error, { type: "error"});
        // throw error;
        return;
    }
};

export const deleteTenant = async (tenantId: string): Promise<void> => {
    try {
        const db = await import("firebase/firestore");
        const firestore: Firestore = db.getFirestore();

        const tenantRef = db.doc(firestore, `tenants/${tenantId}`);
        const tenantData = await db.getDoc(tenantRef).then((doc) => {
            if (doc.exists()) {
                console.log("Tenant found: ", doc.data().name);
                return doc.data();
            } else {
                console.log("No such tenant document!");
                return null;
            }
        });
        const tenantName = tenantData?.name;

        await db.deleteDoc(tenantRef).then(async () => {
            console.log('Tenant deleted');
            log({collection: 'tenants', documentId: tenantId, message: 'Tenant deleted', type: 'delete'});
            // remove tenant name from tenant only rules "tenants" array and set status of tenant in "statuses" to true
            await offboardTenant(tenantName);
        });
        
    } catch (error) {
        console.error("Error deleting tenant:", error);
        throw error;
    }
}

export interface OnsiteAddTenant {
    username: string;
    code: string;
    allocation: number;
    locations: string[];
    autoEnroll: boolean;
    lprAccess: boolean;
    maxPlates: number;
    image: string;
    towerLocation: string;
    currentOccupancy: number;
    bookingApp: boolean;
    bookingAppData: {
        reEntry: boolean;
        noBookingEntry: boolean;
        permanentParker: number;
        reservedAllocation: number;
        liveBookingOccupancy: number;
        livePermanentParkingOccupancy: number;
        accessRequired: boolean;
    }

}

export const sendTenantToOnsite = async (tenant: OnsiteAddTenant, operation: 'add' | 'update'): Promise<boolean> => {
    console.log("Sending tenant to onsite...");
    console.log(tenant);
    console.log(operation);
    try {
        let path;
        let payload;
        if(operation === 'add') {
            path = `${process.env.REACT_APP_CENTRAL_URL}${process.env.REACT_APP_ADD_TENANT_PATH}?${process.env.REACT_APP_VERSION_PARAM}`;
            payload = {
                request: tenant
            };
        } else {
            path = `${process.env.REACT_APP_CENTRAL_URL}${process.env.REACT_APP_UPDATE_TENANT_PATH}?${process.env.REACT_APP_VERSION_PARAM}`;
            payload = {
                //             "username": "ABC", String (REQUIRED)
                // "allocation": 78, int
                // "locations": ["B1", "B2"], (Array)
                // "autoEnroll": False, (Bool)
                // "lprAccess": False,(Bool)
                // "maxPlates": 2, int
                // "image": "", String (Empty String for now)
                // "towerLocation": "T1", string
                request: {
                    username: tenant.username,
                    allocation: tenant.allocation,
                    locations: tenant.locations,
                    autoEnroll: tenant.autoEnroll,
                    lprAccess: tenant.lprAccess,
                    maxPlates: tenant.maxPlates,
                    image: tenant.image,
                    towerLocation: tenant.towerLocation,
                }
            };
        }
        console.log("Path: ", path);
        console.log("Payload: ", payload);
        const res = await fetch(path, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `${process.env.REACT_APP_AUTH}`,
            },
            body: JSON.stringify(payload),
        });
        const data = await res.json();
        console.log(data);
        return data.response.status; // boolean
        // return true;
    } catch (error) {
        console.error("Error sending tenant to onsite:", error);
        return false;
    }
};