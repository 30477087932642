import React from 'react';
import { RulesData } from '../../../../../utils/apis/rules_repository';
import { Row } from "react-table";
import ViewDetails from '../../ViewDetails';
import { DetailsType } from "../../../../../utils/enum";
import StatusChip from '../../../chips/StatusChip';

interface RulesDataColumnProps {
    tenant: string;
}

export const TenantAdminRulesColumn = (props: RulesDataColumnProps) => [
  {
    Header: <div style={{ textAlign: 'center' }}>Title</div>,
    accessor: "title",
    Cell: ({ value }: { value: string }) => <div className='w-[100px] flex items-center justify-center text-center pl-10'>{value}</div>,
    width: 6
  },
  {
    Header: <div style={{ textAlign: 'center' }}>Summary</div>,
    accessor: "summary",
    Cell: ({ value }: { value: string }) => <div className='flex justify-center items-center  text-center'>{value}</div>,
  },
  {
    Header: <div style={{ textAlign: 'start' }}>Applied Users</div>,
    accessor: "users",
    Cell: ({ value }: { value: any[] }) => <div className='text-center'>{value.length === 0 ? "N/A": 
    value.map((user, index) => {
        return <span key={index}>{user.name}{index !== value.length - 1 ? ", " : ""}</span>
    })
    }</div>
  },
  {
    Header: <div style={{ textAlign: 'start' }}>Applied User Groups</div>,
    accessor: "userGroups",
    Cell: ({ value }: { value: any[] }) => <div className='text-center'>{(!value || value.length === 0) ? "N/A": 
    value.map((group, index) => {
        return <span key={index}>{group.name}{index !== value.length - 1 ? ", " : ""}</span>
    })
    }</div>
  },
  {
    Header: <div style={{ textAlign: 'start' }}>Status</div>,
    accessor: 'status',
    Cell: ({ row }: { row: Row<RulesData> }) => <div className='flex justify-center'>{<StatusChip value={(row.original.statuses && row.original.statuses[props.tenant]) ? "Active" : "Inactive"} />}</div>
  },
  {
    Header: <div style={{ textAlign: 'start' }}></div>,
    accessor: "details",
    Cell: ({ row }: { row: Row<RulesData> }) => <div className='text-center'>{<ViewDetails type={DetailsType.RULES} data={row.original as RulesData}></ViewDetails>}</div> // new
  },
];