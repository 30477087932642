import React from "react";
import { UserGroupsColumn } from "../../components/ui/table/columns/UserGroupsColumn";
import { useQuery } from "@tanstack/react-query";
import { getUserGroups } from "../../utils/apis/user_groups_repository";
import { Spinner } from "../../components/ui/Spinner";
import CustomTable from "../../components/ui/CustomTable";
import { getUserFromLocalStorage } from "../../utils/helper"

const UserGroups = () => {

    const user = getUserFromLocalStorage();

    const columns = React.useMemo(() => UserGroupsColumn(), []);
    const { data: userGroups, isLoading: dataLoading, isError } = useQuery({
        queryKey: ['userGroups'],
        queryFn: () => getUserGroups(
            user.role === "SuperAdmin" ? null : user.tenant
        ),
    })

    if (isError) {
        return <div className="flex justify-center">Something went wrong</div>
    }

    return (
        <>
            <div className='w-full px-14'>
                {dataLoading || userGroups === undefined ? <Spinner /> : <CustomTable title={"User Groups"} columns={columns} data={userGroups} count={7} />}
            </div>
        </>
    )
};

export default UserGroups;