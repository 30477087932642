import React from 'react';
import { Text } from "../../../Text"
import { Button } from "../../../Button";
import { RulesData, updateRuleStatuses, updateRuleStatus } from '../../../../../utils/apis/rules_repository';
import { useQueryClient } from '@tanstack/react-query';

interface RuleHeaderProps {
    rule: RulesData;
    user: any;
}

export const RuleHeader: React.FC<RuleHeaderProps> = ({ rule, user }) => {
    const [updating, setUpdating] = React.useState(false);
    const [statuses, setStatuses] = React.useState(rule.statuses);
    const queryClient = useQueryClient();

    const updateStatuses = async (newStatuses: any) => {
        setUpdating(true);
        setStatuses(newStatuses);
        await updateRuleStatuses(newStatuses, rule).then((response) => {
            queryClient.invalidateQueries({ queryKey: ['rules'] });
            setUpdating(false);
        });
    }

    const updateStatus = async (newStatus: boolean) => {
        setUpdating(true);
        const tempRule = { ...rule };
        tempRule.status = newStatus;
        await updateRuleStatus(tempRule).then((response) => {
            queryClient.invalidateQueries({ queryKey: ['rules'] });
            setUpdating(false);
        });
    }

    return (
        <>
            <Text as="h3">Rule Title</Text>
            <Text className="mt-2 text-secondaryText">{rule.title}</Text>

            <Text className="mt-6" as="h3">
                Rule Description
            </Text>
            <Text className="mt-2 text-secondaryText">{rule.description}</Text>

            <Text className="mt-6" as="h3">
                Rule Status
            </Text>
            <Button
                onClick={() => {
                    // if the rule is grace period rule, then just revert the status and do nothing
                    if (rule.title === "Grace Period" || rule.title === "Default Booking Time") {
                        console.log("cannot change this rule status");
                        return;
                    }
                    if (user.role === "TenantAdmin") { // update only users tenant status
                        const currentStatuses = rule.statuses ? { ...rule.statuses } : {};
                        if (!currentStatuses[user.tenant]) {
                            console.log("setting status to false");
                            currentStatuses[user.tenant] = false;
                        }
                        console.log("currentStatuses", currentStatuses);
                        currentStatuses[user.tenant] = !currentStatuses[user.tenant];
                        updateStatuses(currentStatuses);
                    } else { // update all tenants status
                        updateStatus(!rule.status);
                    }

                }}
                className={`w-26 mt-2 mx-2 ${rule.status
                    ? "bg-status-approved-shaded text-status-approved-full"
                    : "bg-primary"
                    }`}
                style={{ border: "1.5px solid #9DA8A7" }}
            >
                {user.role === "TenantAdmin" ? ((statuses && statuses[user.tenant]) ? "Active" : "Inactive") : rule.status ? "Active" : "Inactive"}
            </Button>
        </>
    );
};

export default RuleHeader;