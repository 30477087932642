import { useState } from "react";
import { InviteUserModel, getPendingUsers, inviteUsers, deleteUser } from "../../../../utils/apis/user_repository";
import { Button } from "../../Button";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Spinner } from "../../Spinner";
import * as Collapsible from '@radix-ui/react-collapsible';
import { Divider, IconButton } from "@mui/material";
import { ChevronsDownIcon, ChevronsUpIcon } from "lucide-react";
import DeleteIcon from '@mui/icons-material/Delete';

const ReInviteUsers = () => {
  // maintain a list of users who were re-invited to show "Invited" button
  const [invitedUsers, setInvitedUsers] = useState<string[]>([]);
  const [open, setOpen] = useState(false); // collapsible state
  const [emailAndLoading, setEmailAndLoading] = useState<{ email: string, loading: boolean }[]>([]);

  const queryClient = useQueryClient();
  
  // get users with pending status
  const { data: pendingUsers, isLoading: dataLoading, isError } = useQuery({
    queryKey: ['pendingusers'],
    queryFn: () => getPendingUsers(),
  });

  const deleteUserHandler = (email: string) => {
    const userId = pendingUsers?.find(user => user.email === email)?.userId;
    if (userId) {
      deleteUser(userId).then(() => {
        console.log("deleted: " + email);
        queryClient.invalidateQueries({ queryKey: ['pendingusers'] }).then(() => {
          setEmailAndLoading(emailAndLoading.filter(e => e.email !== email));
        })
      });
    }
  }

  return (
    // display each user with a button to re-invite and delete
    // on click of re-invite, send email to user
    // on success, update button to show "Invited"
    <>
      <Divider />
      {dataLoading && <p className='text-lg font-medium text-secondaryText'>Loading...</p>}
      {isError && <p className='text-lg font-medium text-secondaryText'>Something went wrong</p>}
      <div className="flex flex-col items-between mt-12 mx-6">
        <Collapsible.Root className="CollapsibleRoot" open={open} onOpenChange={setOpen}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} className="pb-6">
            <Collapsible.Trigger asChild>
              <button className="absolute right-10 text-lg font-medium text-secondaryText  hover:text-orange-900">
                <div className="flex flex-row justify-between items-center">
                  <span className="text-sm font-small pr-6">Pending Users</span>
                  {open ?
                    <ChevronsDownIcon className="h-5 w-5" />
                    :
                    <ChevronsUpIcon className="h-5 w-5" />
                  }
                </div>
              </button>
            </Collapsible.Trigger>
          </div>
          <Collapsible.Content>
            {pendingUsers && pendingUsers.map((user: InviteUserModel) => (
              <div key={user.email}>
                <div className="flex flex-row justify-between items-center">
                  <span className='text-lg font-medium text-secondaryText'>{user.email}</span>
                  <div>

                    <Button className={` mb-2 w-[150px] text-white`} onClick={() => {
                      setEmailAndLoading([...emailAndLoading, { email: user.email, loading: true }]);
                      const emailInAUserModelArray: InviteUserModel[] = [{ email: user.email, gallagherId: user.gallagherId }];
                      inviteUsers(emailInAUserModelArray).then(() => {
                        setInvitedUsers([...invitedUsers, user.email]);
                        setEmailAndLoading(emailAndLoading.map(e => e.email === user.email ? { email: e.email, loading: false } : e));
                      })
                    }
                    } disabled={invitedUsers.includes(user.email)}>
                      {emailAndLoading.find(e => e.email === user.email)?.loading
                        ? <Spinner /> :
                        invitedUsers.includes(user.email) ? 'Invited' : 'Re-Invite'
                      }
                    </Button>
                    <IconButton className="ml-1 w-[50px]" aria-label="delete" onClick={() => {
                      setEmailAndLoading([...emailAndLoading, { email: user.email, loading: true }]);
                      deleteUserHandler(user.email);
                     }} disabled={emailAndLoading.find(e => e.email === user.email)?.loading}>
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </div>
              </div>
            ))}
          </Collapsible.Content>
        </Collapsible.Root>
      </div>
    </>
  );
};

export default ReInviteUsers;