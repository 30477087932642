import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Tooltip, Divider } from "@mui/material";
import logo from '../../assets/images/logo.png';
import { SignOutUser } from "../../firebase/firebase";
import { getUserFromLocalStorage } from "../../utils/helper";
import { ChevronDown, ChevronUp, LogOut } from "lucide-react";

const SideBar = () => {

    const navigate = useNavigate();

    // get current path of the route using useLocation hook
    const location = useLocation();

    // get the current path from the location object
    const currentPath = location.pathname;

    const user = getUserFromLocalStorage() ?? { role: "User" };

    const [dropdowns, setDropdowns] = useState<{
        [key: string]: boolean;
    }>({
        bookings: false,
        admin: false,
        users: false,
    });

    const toggleDropdown = (title: string) => {
        setDropdowns(prevState => ({
            ...prevState,
            [title.toLowerCase()]: !prevState[title.toLowerCase()],
        }));
    };

    const Menu = user.role === "SuperAdmin" || user.email === "yapa@ddilabs.ai" || user.email === "gabe@ddilabs.ai" || user.email === "yusuf@ddilabs.ai" ? [
        {
            title: "Bookings",
            navigator: "#",
            subItems: [
                { title: "Active Bookings", navigator: "/bookings" },
                { title: "History", navigator: "/bookings/history" }
            ]
        },
        {
            title: "Admin",
            navigator: "#",
            subItems: [
                { title: "Rules", navigator: "/rules" },
                { title: "Tenants", navigator: "/tenants" },
                { title: "Reports", navigator: "/reports" }
            ]
        },
        {
            title: "Users",
            navigator: "#",
            subItems: [
                { title: "All Users", navigator: "/users" },
                { title: "User Groups", navigator: "/usergroups" }
            ]
        },
        { title: "Profile", navigator: "/profile" },
        { title: "Valet", navigator: "/valet" },
    ] : [
        {
            title: "Bookings",
            navigator: "#",
            subItems: [
                { title: "Active Bookings", navigator: "/bookings" },
                { title: "History", navigator: "/bookings/history" }
            ]
        },
        {
            title: "Admin",
            navigator: "#",
            subItems: [
                { title: "Rules", navigator: "/rules" },
                { title: "Reports", navigator: "/reports" }
            ]
        },
        {
            title: "Users",
            navigator: "#",
            subItems: [
                { title: "All Users", navigator: "/users" },
                { title: "User Groups", navigator: "/usergroups" }
            ]
        },
        { title: "Profile", navigator: "/profile" },
    ];

    const onClick = (index: number, navigator: string) => {
        // console.log(process.env.REACT_APP_VERSION)
        if (index === 8) {
            console.log("signing out");
            SignOutUser();
            localStorage.removeItem('user')
        }
        navigate(navigator);
    }
    // Function to determine if the current path is valet or its subroutes
    const isValetOrSubroute = (path: string) => {
        return path.startsWith('/valet') || path.startsWith('/valet/edit/') || path.startsWith('/valet/cancel/');
    };

    // Render sidebar items, excluding valet and its subroutes
    if (isValetOrSubroute(currentPath)) {
        return null;
    }
    return (
        <div className="w-1/6 flex flex-col bg-white py-5 justify-between">
            <div className="flex flex-col overflow-y-auto justify-start">
                <div className="flex items-center">
                    <img src={logo} alt="logo" />
                </div>
                <div>
                    <Divider sx={{ borderBottomWidth: 1 }} className="mt-10 bg-border" />
                </div>
                <ul className="pl-6 overflow-y-auto py-2">
                    {Menu.map((item, index) => (
                        <li key={index}>
                            <Tooltip title={item.title} placement="right">
                                <div onClick={() => {
                                    if (item.subItems) {
                                        toggleDropdown(item.title);
                                    } else {
                                        onClick(index, item.navigator);
                                    }
                                }} className={`${currentPath === item.navigator ? "bg-primary text-white" : "text-secondaryText"} text-bold justify-between flex items-center p-4 my-6 rounded-l-md cursor-pointer`}>

                                    {item.title}

                                    {item.subItems ? dropdowns[item.title.toLowerCase()] ? <ChevronUp /> : <ChevronDown /> : null}
                                </div>
                            </Tooltip>
                            {item.subItems && (
                                <ul className={`pl-4 ${dropdowns[item.title.toLowerCase()] ? "" : "hidden"}`}>
                                    {item.subItems.map((subItem, subIndex) => (
                                        <li key={`${index}-${subIndex}`}>
                                            <Tooltip title={subItem.title} placement="right">
                                                <div
                                                    className={`${currentPath === subItem.navigator ? "bg-primary text-white" : "text-secondaryText"} text-bold flex my-4 items-center p-4 rounded-l-md cursor-pointer`}
                                                    onClick={() => onClick(index, subItem.navigator)}
                                                >
                                                    {subItem.title}
                                                </div>
                                            </Tooltip>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </li>
                    ))}
                </ul>
                <Divider sx={{ borderBottomWidth: 1 }} className="bg-border" />
            </div>
            <div className="flex flex-col mb-10 justify-start">
                <div>
                    <Tooltip title="Logout" placement="right" className="mx-6">
                        <div>
                            <div
                                className={`border-2 border-primary flex-row space-x-3 text-bold flex items-center p-3 rounded-md cursor-pointer`}
                                onClick={() => onClick(8, "/")}
                            >
                                <LogOut className="w-6 h-6 text-primary" />
                                <div>
                                    Logout
                                </div>
                            </div>
                        </div>

                    </Tooltip>
                </div>
                {/* version number at the very bottom */}
                <div className="version-tag mt-4">
                    <p className="ml-6 mb-6 text-start text-xs text-secondaryText">
                        Version: {process.env.REACT_APP_VERSION ? `${process.env.REACT_APP_VERSION}` : "-"}
                    </p>
                </div>
            </div>
        </div>
    )
};

export default SideBar;