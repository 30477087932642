import { useRouteError } from "react-router-dom";

export default function UnAuthPage() {
    const error = useRouteError();
    console.error(error);

    return (
        <div className="App text-gray-400 font-bold rounded-lg">
            <header className="App-header">
                <h1 className="text-lg ">Unauthorised Access</h1>
                <br />
                <h1 className="text-md">
                    We apologise for the inconvenience, but you are not authorised to visit this page.
                </h1>
                <br />
                <p className="text-md">
                    If you believe this is an error, please contact support@ddilabs.ai
                </p>
            </header>
        </div>
    );
}
