import { Outlet , useLocation } from "react-router-dom"
import bg2 from '../assets/images/bg2.jpg';

const AuthLayout = () => {

    const { pathname } = useLocation();

    return (
        <div className="flex flex-row bg-background w-screen h-screen">
            <Outlet />
            <div className="authLayout">
                <img src={bg2} alt="bg"/>
            </div>
        </div>
    )
}

export default AuthLayout;