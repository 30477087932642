import React, { useMemo } from 'react';
import { UserDataColumn } from '../../components/ui/table/columns/UserDataColumn';
import { useQuery } from '@tanstack/react-query';
import { getAllUsers } from '../../utils/apis/user_repository';
import { Spinner } from '../../components/ui/Spinner';
import CustomTable from '../../components/ui/CustomTable';
import { getUserFromLocalStorage } from '../../utils/helper';
import { Button } from '../../components/ui/CustomButton';
import { Sheet, SheetContent } from '../../components/ui/Sheets';
import { Text } from '../../components/ui/Text';
import { useState } from 'react';
import InviteUsers from '../../components/ui/sheets/InviteUsers';

const UsersBody = () => {

    const user = getUserFromLocalStorage();

    const [openDialog, setOpenDialog] = useState(false);

    const handleOpenDialog = (val: boolean) => {
        setOpenDialog(val);
    }

    const columns = React.useMemo(() => UserDataColumn(), []);

    const queryFn = useMemo(() => () => getAllUsers(
        user.role === "SuperAdmin" ? undefined : user.tenant
    ), [user.role, user.tenant]);

    const { data: userData, isLoading: dataLoading, isError, refetch } = useQuery({
        queryKey: ['users', user.tenant],
        queryFn,
        enabled: !!user, // Ensure user is defined before fetching
    });

    if (dataLoading) return <div className='flex h-full justify-center items-center'>
        <Spinner />
    </div>;
    if (isError) return <div>Error loading data. <Button onClick={() => refetch}>Retry</Button></div>;
    return (
        <div className='px-14 w-full'>
            {dataLoading || userData === undefined ? <Spinner /> : userData.length === 0 ? <div className="flex flex-col items-center">
                <Text as="h3" className="text-center">No Bookings found</Text>
                <Sheet open={openDialog} onOpenChange={(val) => handleOpenDialog(val)}>
                    <Button onClick={() => handleOpenDialog(true)} className="w-48 mt-4 flex justify-center">Create Booking</Button>
                    <SheetContent children={<InviteUsers />} />
                </Sheet>
            </div> : <CustomTable title={"Users"} columns={columns} data={userData} count={7} />}
        </div>
    );
};

export default UsersBody;