import { AlertsData } from "../../../utils/apis/reports_repository";
import { Text } from "../Text";
import { Button } from "../CustomButton";
import { Divider } from "@mui/material";
import { getDateTime } from "../../../utils/helper";
import { DetailsComponent } from '../../ui/containers/Details'

interface AlertDetailsProps {
    alert: AlertsData;
}

export const AlertDetails = ({ alert }: AlertDetailsProps) => {
    return (
        <div className="flex flex-col h-screen overflow-hidden items-between mt-10 mx-6">
            <div className="flex flex-row items-center justify-between">
                <Text as="h2">Alert Details</Text>
            </div>
            <div className="pt-6">
                <Divider className="bg-secondaryText" />
            </div>
            <div className="overflow-y-auto whitespace-nowrap flex flex-col mb-24">
                <div className="flex flex-row space-x-8 items-center">
                    <div className="flex flex-col">
                        <Text as="h3">Alert Type</Text>
                        <Button className={`w-44 mt-2`}>{alert.alertType}</Button>
                    </div>
                    <div className="flex flex-col">
                        <Text as="h3">Vehicle Entry Time</Text>
                        <Text className="text-secondaryText">{getDateTime(alert.entryTimestamp)}</Text>
                    </div>
                </div>
                <Text as="h3">Personal Details</Text>
                <div className="flex flex-row space-x-8 mt-6 items-center">
                    <DetailsComponent title="Cardholder Name" description={alert.cardholderName} />
                    <DetailsComponent title="Cardholder ID" description={alert.cardholderID} />
                    <DetailsComponent title="Gallagher ID" description={alert.tenantID} />
                </div>
                <Text as="h3" className="mt-10">Camera Details</Text>
                <div className="mt-6 flex-row flex gap-x-8">
                    <DetailsComponent title="Camera Name" description={alert.cameraName} />
                    <DetailsComponent title="Camera ID" description={alert.cameraID} />
                </div>
                <Text className="mt-10" as="h3">Alert Description</Text>
                <Text className="mt-6 text-secondaryText">{alert.description}</Text>
                <Text className="mt-10" as="h3">Alert Timestamp</Text>
                <Text className="mt-6 text-secondaryText">{getDateTime(alert.alertTimestamp)}</Text>
                <Text className="mt-10" as="h3">Vehicle Plate</Text>
                <div className="flex justify-start space-x-8 mt-6 items-start">{
                    <Button variant="default" className="w-44 text-white">{alert.plate}</Button>
                }</div>
            </div>
        </div>
    );
}