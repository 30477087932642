import { Text } from "../Text";
import { Button } from "../CustomButton";
import { Divider } from "@mui/material";
import { useState, useEffect } from "react";
import { getUserRules } from "../../../utils/apis/rules_repository";
import { deleteUser, changeUserAccess, UserModel, refreshProfile, getUser, updateUserInFirestore } from "../../../utils/apis/user_repository";
import { Dialog, DialogContent } from "../Dialog";
import UserDetailsBody from "./body/UserDetails/UserDetailsBody";
import EditUserBody from "./body/UserDetails/EditUserBody";
import { useQueryClient, useQuery } from '@tanstack/react-query';
import { Spinner } from "../Spinner";
import { showToast } from "../../../utils/toast";

export interface UserDetailsProps {
    user: UserModel;
    setSheetOpen: (val: boolean) => void;
}

const UserDetails = ({ user, setSheetOpen }: UserDetailsProps) => {

    const [appliedRules, setAppliedRules] = useState<string[]>([]);

    const [deleteDialog, setDeleteDialog] = useState<boolean>(false);
    const [disableDialog, setDisableDialog] = useState<boolean>(false);
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
    const [disableLoading, setDisableLoading] = useState<boolean>(false);

    const [refreshLoading, setRefreshLoading] = useState<boolean>(false);

    const [editBody, setEditBody] = useState<boolean>(false);

    const { data: userData, isLoading: dataLoading, isError } = useQuery({
        queryKey: ['userData', user.userId],
        queryFn: () => getUser(
            user.userId
        ),
    })

    const queryClient = useQueryClient();

    const deleteUserPressed = () => {
        setDeleteLoading(true);
        deleteUser(user.userId).then(() => {
            queryClient.invalidateQueries({ queryKey: ['users'] });
            setDeleteLoading(false);
            setDeleteDialog(false);
            setSheetOpen(false);
        })
    }

    const refreshUserPressed = async () => {
        setRefreshLoading(true);
        await refreshProfile(user.cardholderId).then((data) => {
            console.log(data);
            if (data) {
                updateUserInFirestore(user.userId, data).then(() => {
                    showToast("Users Updated Successfully", { type: "success" });
                    queryClient.invalidateQueries({ queryKey: ['userData'] });
                    setRefreshLoading(false);
                });
            }
        });
    }

    const changeUserAccessPressed = () => {
        setDisableLoading(true);

        const status = user.role !== "Disabled" ? true : false;

        changeUserAccess(user.userId, status).then(() => {
            queryClient.invalidateQueries({ queryKey: ['users'] });
            setDisableLoading(false);
            setDisableDialog(false);
            setSheetOpen(false);
        })
    }

    useEffect(() => {
        // fetch the applied rules for the user
        getUserRules(user.userId).then((rules) => {
            setAppliedRules(rules);
        }
        );
    }, [user]);

    function editUserPressed() {
        setEditBody(!editBody);
    }


    return (
        <>
            {dataLoading ? <div className="flex justify-center h-screen items-center">
                <Spinner />
            </div> : <div className="flex flex-col h-screen overflow-hidden items-between mt-12 mx-6">
                <div className="flex flex-row items-center justify-between">
                    <Text as="h2">User Details</Text>
                    <div className="flex flex-row justify-start">
                        {user.role !== "Disabled" ? <div className="flex flex-row justify-start">
                            <Button variant="secondary" onClick={editUserPressed} className="w-38 border-primary mr-6 text-primaryText">{editBody ? 'View Details' : 'Edit Details'}</Button>
                            {editBody ? null : <Button variant="secondary" onClick={() => setDisableDialog(true)} className="w-38 border-delete mr-6 text-delete">Disable User</Button>}
                        </div> : <Button variant="secondary" onClick={() => setDisableDialog(true)} className="w-38 border-status-approved mr-6 text-status-approved">Enable User</Button>}
                        <Dialog open={deleteDialog} onOpenChange={(val) => setDeleteDialog(val)}>
                            <Button onClick={() => setDeleteDialog(true)} className="bg-delete w-38">Delete User</Button>
                            <DialogContent>
                                <Text as="h3" className="text-center">Delete User {user.name}</Text>
                                <Text as="span" className="text-center">
                                    Are you sure you want to delete this user? Deleting this user will remove all the data associated with this user.
                                </Text>
                                <div className="flex flex-row justify-center space-x-4 mt-6">
                                    <Button onClick={() => setDeleteDialog(false)} variant="secondary" className="w-44 border-primary text-primaryText">Cancel</Button>
                                    <Button onClick={deleteUserPressed} isLoading={deleteLoading} className="w-44 bg-delete text-white">Delete</Button>
                                </div>
                            </DialogContent>
                        </Dialog>

                    </div>
                    <Button onClick={refreshUserPressed} isLoading={refreshLoading} className="w-38 border-primary text-white">Refresh</Button>
                </div>
                <div className="pt-6">
                    <Divider className="bg-secondaryText" />
                </div>
                {editBody ? (
                    <EditUserBody user={userData!} setSheetOpen={setSheetOpen} />
                ) : (
                    <UserDetailsBody user={userData!} />
                )}
            </div>}
            <Dialog open={disableDialog} onOpenChange={(val) => setDisableDialog(val)}>
                <DialogContent>
                    <Text as="h3" className="text-center">{user.role !== 'Disabled' ? 'Disable User ' : 'Enable User '} {user.name}</Text>
                    <Text as="span" className="text-center">
                        Are you sure you want to {user.role !== 'Disabled' ? 'disable ' : 'enable '} this user? {user.role !== 'Disabled' ? 'Disabling this user will not let the user login to the system.' : ''}
                    </Text>
                    <div className="flex flex-row justify-center space-x-4 mt-6">
                        <Button onClick={() => setDisableDialog(false)} variant="secondary" className="w-44 border-primary text-primaryText">Cancel</Button>
                        <Button onClick={() => changeUserAccessPressed()} isLoading={disableLoading} className={`w-44 ${user.role !== 'Disabled' ? 'bg-delete' : 'bg-status-approved'} text-white`}>{user.role !== 'Disabled' ? 'Disable' : 'Enable'}</Button>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default UserDetails;