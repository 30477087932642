import React from "react";
import { useState } from "react";
import { useQuery } from '@tanstack/react-query';
import CustomTable from "../../components/ui/CustomTable";
import { getBookingHistory } from "../../utils/apis/bookings_repository";
import { Text } from "../../components/ui/Text";

import { Spinner } from "../../components/ui/Spinner";

import { getUserFromLocalStorage } from "../../utils/helper";
import { Button } from "../../components/ui/CustomButton";
import { Sheet } from "../../components/ui/Sheets";
import CreateBookingBody from "../../components/ui/sheets/CreateBooking";
import { BookingHistoryColumn } from "../../components/ui/table/columns/BookingHistoryColumn";

const BookingsHistoryBody = () => {

    const user = getUserFromLocalStorage();

    const [openDialog, setOpenDialog] = useState(false);

    const columns = React.useMemo(() => BookingHistoryColumn(), []);
    const { data: bookings, isLoading: dataLoading, isError } = useQuery({
        queryKey: ['bookings'],
        queryFn: () => getBookingHistory(
            user.role === "SuperAdmin" ? undefined : user.tenant
        ),
    })

    const handleOpenDialog = (val: boolean) => {
        setOpenDialog(val);
    }

    if (isError) {
        return <div className="flex justify-center">Something went wrong</div>
    }

    return (
        <>
            <div className={bookings?.length === 0 ? 'w-full h-screen flex items-center justify-center px-14' : 'px-14 w-full'}>
                {dataLoading || bookings === undefined ? <Spinner /> : bookings.length === 0 ? <div className="flex flex-col items-center">
                    <Text as="h3" className="text-center">No Bookings found</Text>
                    <>
                        <Button onClick={() => handleOpenDialog(true)} className="w-48 mt-4 flex justify-center">Create Booking</Button>
                        <Sheet open={openDialog} onOpenChange={(val) => handleOpenDialog(val)} children={<CreateBookingBody setDialogState={(val) => setOpenDialog(val)} />}/ >
                    </>
                </div> : <CustomTable title={"History"} columns={columns} data={bookings} count={7} />}
            </div>
        </>
    );
};

export default BookingsHistoryBody;