import React from "react";
import { Text } from '../../../../Text';
import { TextField } from "@mui/material";
import { SelectDropDown } from '../../../../inputs/SelectDropDown';
import AppliedUsers from "../DetailComponents/AppliedUsers";
import AppliedUserGroups from "../DetailComponents/AppliedUserGroups";
import AppliedTenants from "../DetailComponents/AppliedTenants";

interface FutureBookingsInterface {
    users: { [user: string]: number }; // Use user ids as keys and store number values
    userGroups: { [group: string]: number }; // Use group names as keys and store number values
    tenants: { [tenant: string]: number }; // Use tenant names as keys and store number values
}

interface Tenant {
    name: string;
    gracePeriod: number;
}

interface FutureBookingsProps {
    users: string[];
    appliedUsers: string[];
    appliedUserGroups: string[];
    tenants: Tenant[]; // Array of tenants with gracePeriod
    appliedTenants: string[];
    rfutureBookingPeriods: FutureBookingsInterface;
    setAppliedUsers: React.Dispatch<React.SetStateAction<string[]>>;
    setAppliedTenants: React.Dispatch<React.SetStateAction<string[]>>;
    rsetFutureBookingPeriods: React.Dispatch<React.SetStateAction<FutureBookingsInterface>>;
    setDataChanged: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FutureBookings: React.FC<FutureBookingsProps> = ({
    users,
    appliedUsers,
    setAppliedUsers,
    appliedUserGroups,
    tenants,
    appliedTenants,
    setAppliedTenants,
    rfutureBookingPeriods,
    rsetFutureBookingPeriods,
    setDataChanged
}) => {
    return (
        <>
            {/* Applied Users Dropdown */}
            <AppliedUsers users={users} appliedUsers={appliedUsers} setAppliedUsers={setAppliedUsers} setDataChanged={setDataChanged} />

            {appliedUsers.length > 0 && (<Text as='p'> User Future Booking Periods </Text>)}

            {appliedUsers.map((user) => (
                <div className='wrapper' key={user}>
                    <div className="bg-white py-4">
                        <TextField
                            id="clean"
                            variant="standard"
                            InputProps={{
                                disableUnderline: true,
                            }}
                            contentEditable={false}
                            margin='none'
                            disabled
                            defaultValue={user}
                        />
                    </div>
                    <div>
                        <SelectDropDown className="bg-white" onChange={(e) => {
                            rsetFutureBookingPeriods({ ...rfutureBookingPeriods, users: { ...rfutureBookingPeriods.users, [user]: parseInt(e.target.value) } });
                            setDataChanged(true);
                        }} value={rfutureBookingPeriods.users[user]}>
                            <option value={3}>3 Days</option>
                            <option value={7}>7 Days</option>
                            <option value={14}>14 Days</option>
                            <option value={21}>21 Days</option>
                            <option value={30}>30 Days</option>
                        </SelectDropDown>
                    </div>
                </div>
            ))}

            {/* Applied User Groups Dropdown */}
            <AppliedUserGroups appliedUserGroups={appliedUserGroups} />

            {appliedUserGroups.length > 0 && (<Text as='p'> User Group Future Booking Periods </Text>)}

            {appliedUserGroups.map((group) => (
                <div className='wrapper' key={group}>
                    <div className="bg-white py-4">
                        <TextField
                            id="clean"
                            variant="standard"
                            InputProps={{
                                disableUnderline: true,
                            }}
                            contentEditable={false}
                            margin='none'
                            disabled
                            defaultValue={group}
                        />
                    </div>
                    <div>
                        <SelectDropDown className="bg-white" onChange={(e) => {
                            rsetFutureBookingPeriods({ ...rfutureBookingPeriods, userGroups: { ...rfutureBookingPeriods.userGroups, [group]: parseInt(e.target.value) } });
                            setDataChanged(true);
                        }} value={rfutureBookingPeriods.userGroups[group]}>
                            <option value={3}>3 Days</option>
                            <option value={7}>7 Days</option>
                            <option value={14}>14 Days</option>
                            <option value={21}>21 Days</option>
                            <option value={30}>30 Days</option>
                        </SelectDropDown>
                    </div>
                </div>
            ))}

            {/* Applied Tenants Dropdown */}
            <AppliedTenants tenants={tenants.map(t => t.name)} appliedTenants={appliedTenants} setAppliedTenants={setAppliedTenants} setDataChanged={setDataChanged} isDisabled={false} />

            {appliedTenants.length > 0 && (<Text as='p'> Tenant Future Booking Periods </Text>)}

            {appliedTenants.map((tenant) => {
                const tenantObj = tenants.find(t => t.name === tenant);
                const gracePeriod =tenantObj ? tenantObj.gracePeriod / 60 : 0; 

                return (
                    <div className='wrapper' key={tenant}>
                        <div className="py-4">
                            <TextField
                                id="clean"
                                variant="standard"
                                InputProps={{
                                    disableUnderline: true,
                                }}
                                contentEditable={false}
                                margin='none'
                                disabled
                                defaultValue={tenant}
                            />
                        </div>
                        <div>
                            <SelectDropDown className="bg-white" onChange={(e) => {
                                rsetFutureBookingPeriods({ ...rfutureBookingPeriods, tenants: { ...rfutureBookingPeriods.tenants, [tenant]: parseInt(e.target.value) } });
                                setDataChanged(true);
                            }} value={rfutureBookingPeriods.tenants[tenant]}>
                                <option value={gracePeriod}>{gracePeriod} Days</option>
                            </SelectDropDown>
                        </div>
                    </div>
                )
            })}
        </>
    );
};

export default FutureBookings;
