import { Bar } from "react-chartjs-2";
import { Occupancy } from "@/utils/apis/reports_repository";
import { getDate } from "../../../utils/helper";




interface BarChartProps {
  chartData: Occupancy[];
  tenantName: string;
}


export default function BarChart({ chartData , tenantName }: BarChartProps) {
  
  const maxBooking = Math.max(...chartData.map((data) => data.entries != null ? data.entries.find((entry) => entry.tenantName === tenantName)?.booking || 0 : 0));
  const maxGuest = Math.max(...chartData.map((data) => data.entries != null ? data.entries.find((entry) => entry.tenantName === tenantName)?.guest || 0 : 0));
  const maxVip = Math.max(...chartData.map((data) => data.entries != null ? data.entries.find((entry) => entry.tenantName === tenantName)?.vip || 0 : 0));

  const total = Math.max(maxBooking, maxGuest, maxVip) + 10;


  const data = {
    // first label should be day of week, after shou8ld be the data
    labels: chartData.map((chartData) => getDate(chartData.date) ),
    datasets: [
      {
        label: 'Bookings',
        data: chartData.map((data) => data.entries != null ? data.entries.find((entry) => entry.tenantName === tenantName)?.booking || 0 : 0),
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
        ],
        borderWidth: 1,
      },
      {
        label: 'Guest',
        data: chartData.map((data) => data.entries != null ? data.entries.find((entry) => entry.tenantName === tenantName)?.guest || 0 : 0),
        backgroundColor: [
          'rgba(107, 127, 215, 0.2)',
        ],
        borderColor: [
          'rgba(107, 127, 215, 1)',
        ],
        borderWidth: 1,
      },
      {
        label: 'Permanent',
        data: chartData.map((data) => data.entries != null ? data.entries.find((entry) => entry.tenantName === tenantName)?.vip || 0 : 0),
        backgroundColor: [
          'rgba(239, 202, 8, 0.2)',
        ],
        borderColor: [
          'rgba(239, 202, 8, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };
  return (
    <>
      <Bar data={data} options={{
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            min: 0,
            // get max to the closest 10
            max: total,
          }
        },
        plugins: {
          legend: {
            display: true,
          },
        },
      }} />
    </>
  )
}