import React from 'react';
import CustomTable from '../../components/ui/CustomTable';
import { getRules } from '../../utils/apis/rules_repository';
import { useQuery } from '@tanstack/react-query';
import { Spinner } from "../../components/ui/Spinner";
import { getUserFromLocalStorage } from '../../utils/helper';
import { SuperAdminRulesColumn } from '../../components/ui/table/columns/rules/SuperAdminRulesColumn';
import { TenantAdminRulesColumn } from '../../components/ui/table/columns/rules/TenantAdminRulesColumn';

const RulesBody = () => {

    const user = getUserFromLocalStorage();

    const columns = React.useMemo(() => user.role === "SuperAdmin" ? SuperAdminRulesColumn() : TenantAdminRulesColumn({
        tenant: user.tenant
    }), [user]);

    const { data: ruleData, isLoading: dataLoading, isError } = useQuery({
        queryKey: ['rules'],
        queryFn: () => getRules(),
    })

    if (isError) {
        return <div className="flex justify-center">Something went wrong</div>
    }

    return (
        <div className='w-full px-14'>
            {dataLoading || ruleData === undefined ? <Spinner/> :<CustomTable title={"Rules"} columns={columns} data={ruleData} count={6}/>}
        </div>
    )
}

export default RulesBody;