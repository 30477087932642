import Input, { InputProps } from "./Input";
import {Controller , type RegisterOptions, useFormContext} from "react-hook-form";
import React, {useEffect, memo} from "react";
import { PropsWithClass } from "../../../types";
import _ from "lodash";

export interface InputFormFieldProps {
    name: string;
    options?: RegisterOptions;
    formatter?: (value: string , args: any) => string;
    formatterArgs?: any;
}

const InputFormField: React.FC<PropsWithClass<InputFormFieldProps & InputProps>> = memo(({ name, options={}, formatter, formatterArgs, ...props }) => {
    const { control, formState: {errors} , setValue} = useFormContext();
    useEffect(() => {
        const defaultValue = control._formValues?.[name] as string;
        if (formatter && defaultValue !== undefined) {
          setValue(name, formatter(String(defaultValue), formatterArgs));
        }
    }, [formatterArgs]);
    return (
        <Controller
      control={control}
      name={name}
      render={({ field: { onChange, name, onBlur, value } }) => (
        <Input
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          showError={_.get(errors, name)?.message ? true : false}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          errorMessage={_.get(errors, name)?.message?.toString()}
          onChange={(e) => {
            if (formatter) {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
              const formattedValue = formatter(e.target.value, formatterArgs);
              onChange(formattedValue);
            } else {
              onChange(e);
            }
          }}
          value={value as string}
          onBlur={onBlur}
          name={name}
          {...props}
        />
      )}
    ></Controller>
    );
});

export default InputFormField;
