import { Firestore } from "@firebase/firestore";
import { showToast } from "../toast";
import { UserGroupsModel, getUserGroups } from "./user_groups_repository";
import { UserModel } from "./user_repository";
import log from "../logger";

export interface RulesData {
    mobileOption: { [tenant: string]: boolean | undefined };
    title: string;
    summary: string;
    description: string;
    users: { userId: string; name: string }[];
    userGroups: UserGroupsModel[];
    tenants: string[];
    status: boolean;
    statuses: { [tenant: string]: boolean };
    value: string;
}

export const getUserRules = async (userId: string): Promise<string[]> => {
    try {
        // find the rules which has the user id in the users array
        const db = await import("firebase/firestore");
        const firestore: Firestore = db.getFirestore();

        const rulesRef = db.collection(firestore, "rules");
        const rulesSnap = await db.getDocs(rulesRef);

        // get the list of rules which has the user id in the users array
        const userRules = rulesSnap.docs.filter((doc) => doc.data().users.includes(userId)).map((doc) => doc.data().title);

        return userRules;
    } catch (error) {
        throw error;
    }
};

export const getRules = async (tenant?: string): Promise<RulesData[]> => {
    try {
        const db = await import("firebase/firestore");
        const firestore: Firestore = db.getFirestore();

        const rulesRef = db.collection(firestore, "rules");
        const rulesSnap = await db.getDocs(rulesRef);

        const user = localStorage.getItem("user");
        const userObj = user ? JSON.parse(user) : null;

        const userRole = userObj ? userObj.role : null;

        const usersRef = db.collection(firestore, "users");
        const usersSnap = await db.getDocs(usersRef);

        const userGroups = userRole === "SuperAdmin" ? await getUserGroups() : await getUserGroups(userObj.tenant);
        // console.log("User Groups: ", userGroups);

        const rules = rulesSnap.docs
            .map((doc) => ({
                title: doc.data().title,
                summary: doc.data().summary,
                description: doc.data().description,
                users:
                    userRole === "SuperAdmin"
                        ? doc.data().users
                        : doc.data().users.filter((user: string) => {
                              // get the user doc from the users collection that matches the user id in the rule
                              const userDoc = usersSnap.docs.find((doc) => doc.data().userId === user);
                              // if the tenant of the user doc matches the tenant of the logged in user, return true
                              if (userDoc) {
                                  if (userDoc.data().tenant === userObj.tenant) {
                                      return true;
                                  } else {
                                      return false;
                                  }
                              } else {
                                  return false;
                              }
                          }),
                // rule has only the IDs of the usergroups. Get the usergroup objects from the usergroups array
                // usergroups: userRole === "SuperAdmin" ? doc.data().usergroups : doc.data().usergroups.map((userGroup: string) => {
                //   return userGroups.find((group) => group.groupId === userGroup);
                // }),
                userGroups: doc.data().userGroups
                    ? doc.data().userGroups.map((userGroup: string) => {
                          return userGroups.find((group) => group.groupId === userGroup);
                      })
                    : [],
                tenants:
                    userRole === "SuperAdmin"
                        ? doc.data().tenants
                        : doc.data().tenants.filter((tenant: string) => {
                              // if the user tenant is in the tenants array, return true
                              if (tenant === userObj.tenant) {
                                  return true;
                              } else {
                                  return false;
                              }
                          }),
                status: doc.data().status,
                statuses: doc.data().statuses,
                value: doc.data().value,
                mobileOption: doc.data().mobileOption,
            })) // filter the rules array based on the passed in tenant parameter
            .filter((rule) => (tenant ? rule.tenants.includes(tenant) : true));
        // for each user in the users array, get the user doc from the users collection
        const users = rules.map((rule) => {
            return rule.users.map((user: string) => {
                const userDoc = usersSnap.docs.find((doc) => doc.data().userId === user);
                if (userDoc) {
                    return {
                        userId: userDoc.data().userId,
                        name: userDoc.data().name,
                    };
                } else {
                    return null;
                }
            });
        });
        // remove null users from the users array
        users.forEach((userArray) => {
            userArray.forEach((user: any, index: any) => {
                if (!user) {
                    userArray.splice(index, 1);
                }
            });
        });
        // replace the users array in each rule with the user objects
        rules.forEach((rule, index) => {
            rule.users = users[index];
        });
        // remove null user groups from the user groups array
        rules.forEach((rule) => {
            rule.userGroups.forEach((group: any, index: any) => {
                if (!group) {
                    rule.userGroups.splice(index, 1);
                }
            });
        });
        console.log("Rule user groups: ");
        for (const rule of rules) {
            console.log("Rule: ", rule.title);
            console.log("User Groups: ", rule.userGroups);
        }
        return rules;
    } catch (error) {
        throw error;
    }
};

export const getRule = async (title: string): Promise<RulesData | null> => {
    try {
        const db = await import("firebase/firestore");
        const firestore: Firestore = db.getFirestore();

        const rulesRef = db.collection(firestore, "rules");
        const rulesSnap = await db.getDocs(rulesRef);
        const ruleDoc = rulesSnap.docs.find((doc) => doc.data().title === title);

        if (ruleDoc) {
            return {
                title: ruleDoc.data().title,
                summary: ruleDoc.data().summary,
                description: ruleDoc.data().description,
                users: ruleDoc.data().users.map((user: string) => {
                    return {
                        userId: user,
                        name: "",
                    };
                }),
                userGroups: ruleDoc.data().userGroups,
                tenants: ruleDoc.data().tenants,
                status: ruleDoc.data().status,
                statuses: ruleDoc.data().statuses,
                value: ruleDoc.data().value,
                mobileOption: ruleDoc.data().mobileOption,
            };
        } else {
            return null;
        }
    } catch (error) {
        throw error;
    }
};

export interface BookingTimesInterface {
    startHour: number;
    endHour: number;
}
interface DefaultBookingTimesInterface {
    [tenant: string]: BookingTimesInterface; // Use tenant names as keys and store string values
}

export const getDefaultBookingTimes = async (): Promise<DefaultBookingTimesInterface> => {
    try {
        const db = await import("firebase/firestore");
        const firestore: Firestore = db.getFirestore();

        const tenantsRef = db.collection(firestore, "tenants");
        const tenantsSnap = await db.getDocs(tenantsRef);
        const bookingTimes: DefaultBookingTimesInterface = {};
        const tenantDocs = tenantsSnap.docs;
        tenantDocs.forEach((doc) => {
            bookingTimes[doc.data().name] = doc.data().fulldayDefaultTimes;
        });
        return bookingTimes;
    } catch (error) {
        return {};
    }
};

export const updateRule = async (rule: RulesData, tenant?: string): Promise<void> => {
    try {
        const db = await import("firebase/firestore");
        const firestore: Firestore = db.getFirestore();

        const rulesRef = db.collection(firestore, "rules");
        const rulesSnap = await db.getDocs(rulesRef);
        const ruleDoc = rulesSnap.docs.find((doc) => doc.data().title === rule.title);

        // console.log("Rule Doc: ", ruleDoc?.data());

        const addedTenants = rule.tenants.filter((tenant) => !ruleDoc?.data().tenants.includes(tenant));
        const removedTenants = ruleDoc?.data().tenants.filter((theTenant: string) => !rule.tenants.includes(theTenant));

        const tenantsRef = db.collection(firestore, "tenants");
        const tenantsSnap = await db.getDocs(tenantsRef);

        const usersRef = db.collection(firestore, "users");
        const usersSnap = await db.getDocs(usersRef);

        let tenants: string[] = [];
        tenantsSnap.docs.forEach((doc) => {
            tenants.push(doc.data().name);
        });

        const userRole = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user") as string).role : null;
        const userTenant = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user") as string).tenant : null;
        if (userRole === "TenantAdmin") {
            // console.log("Tenant Admin. resetting addedTenants and removedTenants for the role");
            // tenant admin cannot add or remove other tenants from rules. So if addedTenants or removedTenants has tenants other than the tenant of the tenant admin, remove them
            addedTenants.forEach((tenant) => {
                if (tenant !== userTenant) {
                    addedTenants.splice(addedTenants.indexOf(tenant), 1);
                }
            });
            removedTenants.forEach((tenant: string) => {
                if (tenant !== userTenant) {
                    removedTenants.splice(removedTenants.indexOf(tenant), 1);
                }
            });

            // change the tenants array to only include the tenant of the tenant admin
        }

        console.log("= = = = = = = = = = = = = = = = = = =");
        if (rule.title === "Access" || rule.title === "ReEntry" || rule.title === "Guest Entry") {
            for (const tenant of addedTenants) {
                if (tenants.includes(tenant) && rule.statuses[tenant]) {
                    console.log("true");
                    console.log("Tenant: ", tenant);
                    await sendToCentral(rule.title, tenant, rule.status); // This is right for the toggle button
                } else {
                    console.log("Tenant: ", tenant);
                }
            }
            for (const Rtenant of removedTenants) {
                if (tenants.includes(Rtenant) && rule.statuses[Rtenant]) {
                    console.log("false");
                    console.log("Tenant: ", Rtenant);
                    await sendToCentral(rule.title, Rtenant, false);
                } else {
                    console.log("Tenant: ", tenant);
                }
            }
        } else if (rule.title === "VIP") {
            rule.users.forEach(async (user) => {
                const userDoc = usersSnap.docs.find((doc) => doc.data().userId === user.userId);
                if (userDoc && rule.statuses[userDoc.data().tenant] === true) {
                    console.log("updating user " + userDoc.data().name + " with permanent parking " + rule.status);
                    await db.updateDoc(userDoc.ref, { ...userDoc.data(), permanentParking: rule.status });
                } else {
                    console.log("user not found");
                }
            });
        }
        const users = rule.users.map((user) => user.userId);
        const usergroups = rule.userGroups.map((userGroup) => userGroup.groupId);
        // update the users array with the user ids
        const ruleToSave = { ...rule, users: users, userGroups: usergroups };

        if (ruleDoc) {
            await db.updateDoc(ruleDoc.ref, { ...ruleToSave });
            log({ collection: "rules", documentId: ruleDoc.id, message: "Rule updated", type: "update" });
        } else {
            const docRef = await db.addDoc(rulesRef, ruleToSave);
            log({ collection: "rules", documentId: docRef.id, message: "Rule added", type: "create" });
        }
        showToast("Rule updated successfully", { type: "success" });
    } catch (error) {
        showToast("Error updating rule", { type: "error" });
        throw error;
    }
};

interface GracePeriods {
    [tenant: string]: number; // Use tenant names as keys and store string values
}

interface FutureBookingsInterface {
    users: { [user: string]: number }; // Use user ids as keys and store string values
    userGroups: { [group: string]: number }; // Use group names as keys and store string values
    tenants: { [tenant: string]: number }; // Use tenant names as keys and store string values
}

interface BookingLimitsInterface {
    users: { [user: string]: number }; // Use user ids as keys and store string values
    userGroups: { [group: string]: number }; // Use group names as keys and store string values
    tenants: { [tenant: string]: number }; // Use tenant names as keys and store string values
}

interface LastMinuteBookingsInterface {
    users: { [user: string]: number }; // Use user ids as keys and store string values
    userGroups: { [group: string]: number }; // Use group names as keys and store string values
    tenants: { [tenant: string]: number }; // Use tenant names as keys and store string values
}

export const updateRuleValues = async (
    rule: RulesData,
    addedUsers: string[],
    removedUsers: string[],
    addedUserGroups: string[],
    addedTenants: string[],
    removedTenants: string[],
    users: UserModel[],
    gracePeriods: GracePeriods,
    futureBookingPeriods: FutureBookingsInterface,
    bookingLimits: BookingLimitsInterface,
    bookingTimes: DefaultBookingTimesInterface,
    lastMinBookingHours: LastMinuteBookingsInterface
): Promise<void> => {
    console.log("addedUsers: ", addedUsers);
    console.log("removedUsers: ", removedUsers);
    try {
        const db = await import("firebase/firestore");
        const firestore: Firestore = db.getFirestore();

        const ruleName = rule.title;

        switch (ruleName) {
            case "Weekend":
                for (const user of removedUsers) {
                    const userObj = users.find((u) => u.userId === user);

                    const usersRef = db.collection(firestore, "users");
                    const usersSnap = await db.getDocs(usersRef);
                    const userDoc = usersSnap.docs.find((doc) => doc.data().name === user);

                    if (userObj && userDoc) {
                        await db.updateDoc(userDoc.ref, { ...userDoc.data(), isWeekend: false });
                    }
                }
                for (const user of addedUsers) {
                    const userObj = users.find((u) => u.name === user);

                    const usersRef = db.collection(firestore, "users");
                    const usersSnap = await db.getDocs(usersRef);
                    const userDoc = usersSnap.docs.find((doc) => doc.data().name === user);

                    if (userObj && userDoc) {
                        await db.updateDoc(userDoc.ref, { ...userDoc.data(), isWeekend: true });
                    }
                }
                removedTenants.forEach(async (tenant) => {
                    const tenantRef = db.collection(firestore, "tenants");
                    const tenantSnap = await db.getDocs(tenantRef);
                    const tenantDoc = tenantSnap.docs.find((doc) => doc.data().name === tenant);

                    if (tenantDoc) {
                        await db.updateDoc(tenantDoc.ref, { ...tenantDoc.data(), isWeekend: false, tenantRules: db.arrayRemove("Weekend") });
                    }
                });
                addedTenants.forEach(async (tenant) => {
                    const tenantRef = db.collection(firestore, "tenants");
                    const tenantSnap = await db.getDocs(tenantRef);
                    const tenantDoc = tenantSnap.docs.find((doc) => doc.data().name === tenant);

                    if (tenantDoc) {
                        await db.updateDoc(tenantDoc.ref, { ...tenantDoc.data(), isWeekend: true, tenantRules: db.arrayUnion("Weekend") });
                    }
                });
                break;
            case "Email Alerts":
                for (const tenant of removedTenants) {
                    const tenantRef = db.collection(firestore, "tenants");
                    const tenantSnap = await db.getDocs(tenantRef);
                    const tenantDoc = tenantSnap.docs.find((doc) => doc.data().name === tenant);

                    if (tenantDoc) {
                        await db.updateDoc(tenantDoc.ref, { ...tenantDoc.data(), emailAlerts: false });
                    }
                }
                for (const tenant of addedTenants) {
                    const tenantRef = db.collection(firestore, "tenants");
                    const tenantSnap = await db.getDocs(tenantRef);
                    const tenantDoc = tenantSnap.docs.find((doc) => doc.data().name === tenant);

                    if (tenantDoc) {
                        await db.updateDoc(tenantDoc.ref, { ...tenantDoc.data(), emailAlerts: true });
                    }
                }
                break;
            case "Booking Limit":
                for (const user of removedUsers) {
                    const userObj = users.find((u) => u.userId === user);

                    const usersRef = db.collection(firestore, "users");
                    const usersSnap = await db.getDocs(usersRef);
                    const userDoc = usersSnap.docs.find((doc) => doc.data().userId === user);

                    console.log("User Obj: ", userObj);
                    console.log("User Doc: ", userDoc?.data());

                    if (userObj && userDoc) {
                        console.log("updating user " + userDoc.data().name + " with booking limit " + rule.value);
                        await db.updateDoc(userDoc.ref, { ...userDoc.data(), bookingLimit: parseInt(rule.value) });
                    }
                }
                for (const user of addedUsers) {
                    const userObj = users.find((u) => u.name === user);
                    console.log("User Obj: ", userObj);
                    const usersRef = db.collection(firestore, "users");
                    const usersSnap = await db.getDocs(usersRef);
                    const userDoc = usersSnap.docs.find((doc) => doc.data().name === user);

                    if (userObj && userDoc) {
                        await db.updateDoc(userDoc.ref, { ...userDoc.data(), bookingLimit: bookingLimits.users[user] });
                    }
                }
                for (const group of addedUserGroups) {
                    const userGroupsRef = db.collection(firestore, "userGroups");
                    const userGroupsSnap = await db.getDocs(userGroupsRef);
                    const userGroupDoc = userGroupsSnap.docs.find((doc) => doc.data().name === group);

                    if (userGroupDoc) {
                        await db.updateDoc(userGroupDoc.ref, { ...userGroupDoc.data(), bookingLimit: bookingLimits.userGroups[group] });
                    }
                }
                for (const tenant of removedTenants) {
                    const tenantRef = db.collection(firestore, "tenants");
                    const tenantSnap = await db.getDocs(tenantRef);
                    const tenantDoc = tenantSnap.docs.find((doc) => doc.data().name === tenant);

                    if (tenantDoc) {
                        await db.updateDoc(tenantDoc.ref, { ...tenantDoc.data(), bookingLimit: parseInt(rule.value) });
                    }
                }
                for (const tenant of addedTenants) {
                    const tenantRef = db.collection(firestore, "tenants");
                    const tenantSnap = await db.getDocs(tenantRef);
                    const tenantDoc = tenantSnap.docs.find((doc) => doc.data().name === tenant);

                    if (tenantDoc) {
                        await db.updateDoc(tenantDoc.ref, { ...tenantDoc.data(), bookingLimit: bookingLimits.tenants[tenant] });
                    }
                }
                break;
            case "Grace Period":
                for (const tenant of removedTenants) {
                    const tenantRef = db.collection(firestore, "tenants");
                    const tenantSnap = await db.getDocs(tenantRef);
                    const tenantDoc = tenantSnap.docs.find((doc) => doc.data().name === tenant);

                    if (tenantDoc) {
                        await db.updateDoc(tenantDoc.ref, { ...tenantDoc.data(), gracePeriod: parseInt(rule.value) });
                    }
                }
                for (const tenant of addedTenants) {
                    const tenantRef = db.collection(firestore, "tenants");
                    const tenantSnap = await db.getDocs(tenantRef);
                    const tenantDoc = tenantSnap.docs.find((doc) => doc.data().name === tenant);

                    if (tenantDoc) {
                        await db.updateDoc(tenantDoc.ref, { ...tenantDoc.data(), gracePeriod: gracePeriods[tenant] });
                    }
                }
                break;
            case "Future Bookings":
                for (const user of removedUsers) {
                    const userObj = users.find((u) => u.userId === user);

                    const usersRef = db.collection(firestore, "users");
                    const usersSnap = await db.getDocs(usersRef);
                    const userDoc = usersSnap.docs.find((doc) => doc.data().userId === user);

                    if (userObj && userDoc) {
                        await db.updateDoc(userDoc.ref, { ...userDoc.data(), futureBookingPeriod: parseInt(rule.value) }); // The " Days" part of the string rule.value ("30 Days") is ignored by the parseInt() function because it only parses the initial part of the string until it encounters a non-digit character.
                    }
                }
                for (const user of addedUsers) {
                    const userObj = users.find((u) => u.name === user);

                    const usersRef = db.collection(firestore, "users");
                    const usersSnap = await db.getDocs(usersRef);
                    const userDoc = usersSnap.docs.find((doc) => doc.data().name === user);

                    if (userObj && userDoc) {
                        await db.updateDoc(userDoc.ref, { ...userDoc.data(), futureBookingPeriod: futureBookingPeriods.users[user] });
                    }
                }
                for (const group of addedUserGroups) {
                    const userGroupsRef = db.collection(firestore, "userGroups");
                    const userGroupsSnap = await db.getDocs(userGroupsRef);
                    const userGroupDoc = userGroupsSnap.docs.find((doc) => doc.data().name === group);

                    if (userGroupDoc) {
                        await db.updateDoc(userGroupDoc.ref, { ...userGroupDoc.data(), futureBookingPeriod: futureBookingPeriods.userGroups[group] });
                    }
                }
                for (const tenant of removedTenants) {
                    const tenantRef = db.collection(firestore, "tenants");
                    const tenantSnap = await db.getDocs(tenantRef);
                    const tenantDoc = tenantSnap.docs.find((doc) => doc.data().name === tenant);

                    if (tenantDoc) {
                        await db.updateDoc(tenantDoc.ref, { ...tenantDoc.data(), futureBookingPeriod: parseInt(rule.value) }); // The " Days" part of the string rule.value ("30 Days") is ignored by the parseInt() function because it only parses the initial part of the string until it encounters a non-digit character.
                    }
                }
                for (const tenant of addedTenants) {
                    const tenantRef = db.collection(firestore, "tenants");
                    const tenantSnap = await db.getDocs(tenantRef);
                    const tenantDoc = tenantSnap.docs.find((doc) => doc.data().name === tenant);

                    if (tenantDoc) {
                        await db.updateDoc(tenantDoc.ref, { ...tenantDoc.data(), futureBookingPeriod: futureBookingPeriods.tenants[tenant] });
                    }
                }
                break;
            case "VIP":
                for (const user of removedUsers) {
                    const userObj = users.find((u) => u.userId === user);

                    const usersRef = db.collection(firestore, "users");
                    const usersSnap = await db.getDocs(usersRef);
                    const userDoc = usersSnap.docs.find((doc) => doc.data().userId === user);

                    console.log("User Obj: ", userObj);
                    console.log("User Doc: ", userDoc?.data());

                    if (userObj && userDoc && rule.statuses[userObj.tenant] === true) {
                        console.log("updating user " + userDoc.data().name + " with permanent parking " + rule.status);
                        await db
                            .updateDoc(userDoc.ref, { ...userDoc.data(), permanentParking: false })
                            .then(() => {
                                console.log("User updated");
                            })
                            .catch((error) => {
                                console.log("Error updating user: ", error);
                            });
                    }
                }
                // addedUsers covered in the updateRule function. updated with not "true" but the value of the rule
                break;
            case "Multiple Days":
                for (const user of removedUsers) {
                    const userObj = users.find((u) => u.userId === user);

                    const usersRef = db.collection(firestore, "users");
                    const usersSnap = await db.getDocs(usersRef);
                    const userDoc = usersSnap.docs.find((doc) => doc.data().userId === user);

                    if (userObj && userDoc) {
                        await db.updateDoc(userDoc.ref, { ...userDoc.data(), isMulti: false });
                    }
                }
                for (const user of addedUsers) {
                    const userObj = users.find((u) => u.name === user);

                    const usersRef = db.collection(firestore, "users");
                    const usersSnap = await db.getDocs(usersRef);
                    const userDoc = usersSnap.docs.find((doc) => doc.data().name === user);

                    if (userObj && userDoc) {
                        await db.updateDoc(userDoc.ref, { ...userDoc.data(), isMulti: true });
                    }
                }
                for (const tenant of removedTenants) {
                    const tenantRef = db.collection(firestore, "tenants");
                    const tenantSnap = await db.getDocs(tenantRef);
                    const tenantDoc = tenantSnap.docs.find((doc) => doc.data().name === tenant);

                    if (tenantDoc) {
                        await db.updateDoc(tenantDoc.ref, { ...tenantDoc.data(), isMulti: false, tenantRules: db.arrayRemove("Multi") });
                    }
                }
                for (const tenant of addedTenants) {
                    const tenantRef = db.collection(firestore, "tenants");
                    const tenantSnap = await db.getDocs(tenantRef);
                    const tenantDoc = tenantSnap.docs.find((doc) => doc.data().name === tenant);

                    if (tenantDoc) {
                        await db.updateDoc(tenantDoc.ref, { ...tenantDoc.data(), isMulti: true, tenantRules: db.arrayUnion("Multi") });
                    }
                }
                break;
            case "Default Booking Time":
                // update the fulldayDefaultTimes object for all tenants
                const tenantRef = db.collection(firestore, "tenants");
                const tenantSnap = await db.getDocs(tenantRef);
                tenantSnap.docs.forEach(async (doc) => {
                    const tenant = doc.data().name;
                    if (bookingTimes[tenant]) {
                        await db.updateDoc(doc.ref, { ...doc.data(), fulldayDefaultTimes: bookingTimes[tenant] });
                    }
                });
                break;
            case "Last Minute Booking":
                for (const user of removedUsers) {
                    const userObj = users.find((u) => u.userId === user);

                    const usersRef = db.collection(firestore, "users");
                    const usersSnap = await db.getDocs(usersRef);
                    const userDoc = usersSnap.docs.find((doc) => doc.data().userId === user);

                    console.log("User Obj: ", userObj);
                    console.log("User Doc: ", userDoc?.data());

                    if (userObj && userDoc) {
                        console.log("updating user " + userDoc.data().name + " with override booking limit hours " + rule.value);
                        await db.updateDoc(userDoc.ref, { ...userDoc.data(), lastMinuteBookingPeriod: parseInt(rule.value) });
                    }
                }
                for (const user of addedUsers) {
                    const userObj = users.find((u) => u.name === user);
                    console.log("User Obj: ", userObj);
                    const usersRef = db.collection(firestore, "users");
                    const usersSnap = await db.getDocs(usersRef);
                    const userDoc = usersSnap.docs.find((doc) => doc.data().name === user);

                    if (userObj && userDoc) {
                        await db.updateDoc(userDoc.ref, { ...userDoc.data(), lastMinuteBookingPeriod: lastMinBookingHours.users[user] });
                    }
                }
                for (const group of addedUserGroups) {
                    const userGroupsRef = db.collection(firestore, "userGroups");
                    const userGroupsSnap = await db.getDocs(userGroupsRef);
                    const userGroupDoc = userGroupsSnap.docs.find((doc) => doc.data().name === group);

                    if (userGroupDoc) {
                        await db.updateDoc(userGroupDoc.ref, { ...userGroupDoc.data(), lastMinuteBookingPeriod: lastMinBookingHours.userGroups[group] });
                    }
                }
                for (const tenant of removedTenants) {
                    const tenantRef = db.collection(firestore, "tenants");
                    const tenantSnap = await db.getDocs(tenantRef);
                    const tenantDoc = tenantSnap.docs.find((doc) => doc.data().name === tenant);

                    if (tenantDoc) {
                        await db.updateDoc(tenantDoc.ref, { ...tenantDoc.data(), lastMinuteBookingPeriod: parseInt(rule.value) });
                    }
                }
                for (const tenant of addedTenants) {
                    const tenantRef = db.collection(firestore, "tenants");
                    const tenantSnap = await db.getDocs(tenantRef);
                    const tenantDoc = tenantSnap.docs.find((doc) => doc.data().name === tenant);

                    if (tenantDoc) {
                        await db.updateDoc(tenantDoc.ref, { ...tenantDoc.data(), lastMinuteBookingPeriod: lastMinBookingHours.tenants[tenant] });
                    }
                }
            // access and re-entry handled in the updateRule function for good flow
        }
        log({ collection: "rules", documentId: rule.title, message: "Rule values updated", type: "update" });
        return;
    } catch (error) {
        throw error;
    }
};

export const updateRuleStatus = async (rule: RulesData): Promise<void> => {
    // super admin turning a rule on or off
    try {
        const db = await import("firebase/firestore");
        const firestore: Firestore = db.getFirestore();

        const rulesRef = db.collection(firestore, "rules");
        const rulesSnap = await db.getDocs(rulesRef);
        const ruleDoc = rulesSnap.docs.find((doc) => doc.data().title === rule.title);

        if (ruleDoc) {
            await db.updateDoc(ruleDoc.ref, { ...ruleDoc.data(), status: rule.status });
        }
        log({ collection: "rules", documentId: rule.title, message: "Rule status updated", type: "update" });
    } catch (error) {
        throw error;
    }
};

export const updateRuleStatuses = async (statuses: { [tenant: string]: boolean }, rule: RulesData): Promise<void> => {
    // tenant admins turning a rule on or off
    try {
        const db = await import("firebase/firestore");
        const firestore: Firestore = db.getFirestore();

        const rulesRef = db.collection(firestore, "rules");
        const rulesSnap = await db.getDocs(rulesRef);
        const ruleDoc = rulesSnap.docs.find((doc) => doc.data().title === rule.title);

        if (ruleDoc) {
            await db.updateDoc(ruleDoc.ref, { ...ruleDoc.data(), statuses: statuses });
        }
        log({ collection: "rules", documentId: rule.title, message: "Rule statuses updated", type: "update" });
    } catch (error) {
        throw error;
    }
};

export const sendToCentral = async (rule: string, tenant: string, status: boolean): Promise<void> => {
    const reEntryUpdatePath = `${process.env.REACT_APP_CENTRAL_URL}${process.env.REACT_APP_RE_ENTRY_PATH}?${process.env.REACT_APP_VERSION_PARAM}`;
    const accessGrantPath = `${process.env.REACT_APP_CENTRAL_URL}${process.env.REACT_APP_ACCESS_GRANT_PATH}?${process.env.REACT_APP_VERSION_PARAM}`;
    const guestEntryPath = `${process.env.REACT_APP_CENTRAL_URL}cmGuestEntry?${process.env.REACT_APP_VERSION_PARAM}`;
    let path = "";
    let payload = {};

    if (rule === "ReEntry") {
        path = reEntryUpdatePath;
        payload = {
            request: {
                TenantName: tenant,
                ReEntry: status,
            },
        };
    } else if (rule === "Access") {
        path = accessGrantPath;
        payload = {
            request: {
                TenantName: tenant,
                Access: status,
            },
        };
    } else if (rule === "Guest Entry") {
        path = guestEntryPath;
        payload = {
            request: {
                TenantName: tenant,
                GuestEntry: status,
            },
        };
    }

    const res = await fetch(path, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `${process.env.REACT_APP_AUTH}`,
        },
        body: JSON.stringify(payload),
    });
    await res.json();
};

export const onboardTenant = async (tenantName: string): Promise<void> => {
    // find the rules with titles "Access", "ReEntry" and "Email Alerts". and find their doc IDs
    // add tenant name to the "tenants" array of each rule and set the status of the tenant in the "statuses" object of each rule to false
    console.log("Onboarding tenant: " + tenantName + " to default rules");
    try {
        const db = await import("firebase/firestore");
        const firestore: Firestore = db.getFirestore();

        const rulesRef = db.collection(firestore, "rules");
        const rulesSnap = await db.getDocs(rulesRef);

        const accessRuleDoc = rulesSnap.docs.find((doc) => doc.data().title === "Access");
        const reEntryRuleDoc = rulesSnap.docs.find((doc) => doc.data().title === "ReEntry");
        const emailAlertsRuleDoc = rulesSnap.docs.find((doc) => doc.data().title === "Email Alerts");
        const guestEntryRuleDoc = rulesSnap.docs.find((doc) => doc.data().title === "Guest Entry");
        const defaultBookingTimeRuleDoc = rulesSnap.docs.find((doc) => doc.data().title === "Default Booking Time");

        const tenantRef = db.collection(firestore, "tenants");
        const tenantSnap = await db.getDocs(tenantRef);
        const tenantDoc = tenantSnap.docs.find((doc) => doc.data().name === tenantName);

        if (accessRuleDoc) {
            await db.updateDoc(accessRuleDoc.ref, {
                tenants: db.arrayUnion(tenantName),
                statuses: { ...accessRuleDoc.data().statuses, [tenantName]: false },
            });
        }
        if (reEntryRuleDoc) {
            await db.updateDoc(reEntryRuleDoc.ref, {
                tenants: db.arrayUnion(tenantName),
                statuses: { ...reEntryRuleDoc.data().statuses, [tenantName]: false },
            });
        }
        if (emailAlertsRuleDoc) {
            await db.updateDoc(emailAlertsRuleDoc.ref, {
                tenants: db.arrayUnion(tenantName),
                statuses: { ...emailAlertsRuleDoc.data().statuses, [tenantName]: false },
            });
        }
        if (guestEntryRuleDoc) {
            await db.updateDoc(guestEntryRuleDoc.ref, {
                tenants: db.arrayUnion(tenantName),
                statuses: { ...guestEntryRuleDoc.data().statuses, [tenantName]: true },
            });
        }
        if (defaultBookingTimeRuleDoc) {
            await db.updateDoc(defaultBookingTimeRuleDoc.ref, {
                tenants: db.arrayUnion(tenantName),
                statuses: { ...defaultBookingTimeRuleDoc.data().statuses, [tenantName]: true },
            });
        }
        if (tenantDoc) {
            await db.updateDoc(tenantDoc.ref, {
                fulldayDefaultTimes: {
                    startHour: 800,
                    endHour: 1800,
                },
            });
        }
        console.log("Tenant onboarded to default rules");
        log({ collection: "tenants", documentId: tenantName, message: "Tenant onboarded to default rules", type: "update" });
    } catch (error) {
        throw error;
    }
};

export const offboardTenant = async (tenantName: string): Promise<void> => {
    // remove tenantName from all the rules "tenants" array and the "statuses" object. Not only from access, re-entry and email alerts
    try {
        const db = await import("firebase/firestore");
        const firestore: Firestore = db.getFirestore();

        const rulesRef = db.collection(firestore, "rules");
        const rulesSnap = await db.getDocs(rulesRef);

        const tenantRef = db.collection(firestore, "tenants");
        const tenantSnap = await db.getDocs(tenantRef);
        const tenantDoc = tenantSnap.docs.find((doc) => doc.data().name === tenantName);

        rulesSnap.docs.forEach(async (doc) => {
            const statuses = doc.data().statuses;
            delete statuses[tenantName];
            await db.updateDoc(doc.ref, {
                tenants: db.arrayRemove(tenantName),
                statuses: statuses,
            });
        });
        log({ collection: "tenants", documentId: tenantName, message: "Tenant offboarded from all rules", type: "update" });
    } catch (error) {
        throw error;
    }
};
