import { BookingsData, editMultiBooking, editSingleBooking } from "../../../../utils/apis/bookings_repository";
import { Text } from "../../Text";
import { DatePicker } from "../../inputs/DatePicker";
import { getDateObject, getTime, addDays, getDateTimeString } from "../../../../utils/helper";
import { useState } from "react";
import { Button } from "../../CustomButton";
import { TimePicker } from "../../inputs/TimePicker";
import { DatePickerWithRange } from "../../inputs/DateRangePicker";
import { SheetClose } from "../../Sheets";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { showToast } from "../../../../utils/toast";
import { DateTime } from "luxon";

interface EditBookingBodyProps {
    booking: Partial<BookingsData>;
    bookingId: string
    setSheetState: (state: boolean) => void;
}

const EditBookingBody = ({ booking, bookingId, setSheetState }: EditBookingBodyProps) => {

    const queryClient = useQueryClient();

    const [bookingType, setBookingType] = useState(booking.bookingType);
    const [startTime, updateStartTime] = useState<string>(getTime(booking.startTime!));
    const [endTime, updateEndTime] = useState<string>(getTime(booking.endTime!));

    const [isLoading, setIsLoading] = useState(false);

    const [startDate, setStartDate] = useState<Date>(getDateObject(booking.startTime!));
    const [endDate, setEndDate] = useState<Date>(
        booking.bookingType === "Single" ? getDateObject(booking.endTime!) : getDateObject(booking.originalBooking!.endTime)
    );

    const handleClickMulti = () => {
        setBookingType("Multi");
    }

    const handleClickSingle = () => {
        setBookingType("Single");
    }

    const handleStartTime = (time: string) => {
        updateStartTime(time);
    }

    const handleEndTime = (time: string): void => {
        updateEndTime(time);
    }

    const handleStartDate = (date: Date) => {
        setStartDate(date);
    }

    const handleEndDate = (date: Date) => {
        setEndDate(date);
    }


    const singleBookingMutation = useMutation({
        mutationFn: editSingleBooking,
        onSuccess: (val) => {
            console.log("Loading state", isLoading)
            if (val) {
                console.log("<<== single booking updated successfully ==>>");
                queryClient.invalidateQueries({ queryKey: ['bookings'] });
                setSheetState(false);
            } else {
                setTimeout(() => {
                    console.log("<<== booking update failed ==>>");
                    queryClient.invalidateQueries({ queryKey: ['bookings'] });
                    setSheetState(false);
                }, 2000);
            }
        },
    })

    const multiBookingMutation = useMutation({
        mutationFn: editMultiBooking,
        onSuccess: () => {
            console.log("<<== multi booking updated successfully ==>>");
            queryClient.invalidateQueries({ queryKey: ['bookings'] });
            setSheetState(false);
        },
    })

    const handleBookingUpdate = async () => {

        const now = DateTime.now();

        if ((!startDate || !endDate) && bookingType === "Multi") {
            showToast("One or more date fields are empty", {
                type: "warning"
            });
            return;
        }

        if (!startDate && bookingType === "Single") {
            showToast("Start date is empty", {
                type: "warning"
            });
            return;
        }

        if (!startTime) {
            showToast("Start time is empty", {
                type: "warning"
            });
            return;
        }

        if (!endTime) {
            showToast("End time is empty", {
                type: "warning"
            });
            return;
        }


        const updatedStartDate = getDateTimeString(startDate.toISOString(), startTime);
        const updatedEndDate = getDateTimeString(bookingType === "Single" ? startDate.toISOString() : endDate.toISOString(), endTime);

        if(DateTime.fromJSDate(new Date(updatedStartDate)) < now) {
            showToast("Start time cannot be in the past", {
                type: "warning"
            });
            return;
        }

        if(DateTime.fromJSDate(new Date(updatedStartDate)) > DateTime.fromJSDate(new Date(updatedEndDate))) {
            showToast("Start date cannot be after than the end date", {
                type: "warning"
            });
            return;
        }

        setIsLoading(true);

        if(bookingType === "Single"){
            const updatedBooking: Partial<BookingsData> = {
                bookingId: bookingId,
                startTime: updatedStartDate,
                endTime: updatedEndDate,
            }
            singleBookingMutation.mutate(updatedBooking);
        }else if(bookingType === "Multi"){
            const updatedBooking: Partial<BookingsData> = {
                multiID: booking.multiID,
                startTime: updatedStartDate,
                endTime: updatedEndDate,
            }
            multiBookingMutation.mutate(updatedBooking);
        } 
    }
    return (
        <>
            <div>
                <Text as="h3">Booking Type</Text>
                <div className="flex flex-row space-x-4 mt-6">
                    <Button onClick={handleClickSingle} variant={bookingType === "Single" ? "default" : "secondary"} className={`w-44 border-primary ${bookingType === "Single" ? "text-white" : "border-primary text-primaryText"}`}>Single Booking</Button>
                    <Button onClick={handleClickMulti} variant={bookingType === "Multi" ? "default" : "secondary"} className={`w-44 ${bookingType === "Multi" ? "text-white" : "border-primary text-primaryText"}`}>Multi Booking</Button>
                </div>
            </div>
            <Text className="mt-10" as="h3">Booking Date</Text>
            <div className="mt-6">
                {bookingType === "Single" ? <DatePicker setNewDate={(value) => handleStartDate(value)} initialDate={startDate} /> : <DatePickerWithRange setStartDate={(val) => handleStartDate(val)} setEndDate={(val) => handleEndDate(val)} startDate={startDate} endDate={endDate} />}
            </div>
            <div className="mt-4 flex flex-row">
                <div className="flex flex-col">
                    <Text as="h3">Start Time</Text>
                    <div className="mt-6">
                        <div className="flex flex-row space-x-4">
                            <TimePicker initialTime={startTime} onChange={handleStartTime} />
                        </div>
                    </div>
                </div>
                <div className="flex flex-col ml-6">
                    <Text as="h3">End Time</Text>
                    <div className="mt-6 ">
                        <div className="flex flex-row space-x-4">
                            <TimePicker initialTime={endTime} onChange={handleEndTime} />
                        </div>
                    </div>
                </div>
            </div>
            <Text className="mt-10" as="h3">Valid Plates</Text>
            <div className="flex flex-row space-x-8 mt-6 items-center">{
                booking.plate!.map((plate) => {
                    return (
                        <Button variant="default" className="w-44 text-white">{plate}</Button>
                    )
                }, [])
            }</div>
            <div className="absolute bottom-0 mx-12 right-0 mb-20 flex flex-row justify-end">
                <SheetClose asChild>
                    <Button variant={"secondary"} className="w-44 border border-primary text-primaryText">Cancel</Button>
                </SheetClose>
                <Button variant={"default"} isLoading={isLoading} onClick={handleBookingUpdate} className="w-44 ml-4 text-white">Save Changes</Button>
            </div>
        </>
    );
}

export default EditBookingBody;