// create a view details button of primary color

import BookingsDetails from "../sheets/BookingsDetails";
import UserDetails from "../sheets/UserDetails";

import { BookingsData } from "../../../utils/apis/bookings_repository";
import { UserModel } from "../../../utils/apis/user_repository";
import { Sheet, SheetContent } from "../Sheets";
import RulesDetails from "../sheets/RuleDetails";
import { RulesData } from "../../../utils/apis/rules_repository";
import { useState } from "react";
import UserGroupDetails from "../sheets/body/UserGroupsComponents/UserGroupsDetails";
import { UserGroupsModel } from "../../../utils/apis/user_groups_repository";
import { AlertsData } from "../../../utils/apis/reports_repository";
import { DetailsType } from "../../../utils/enum";
import { AlertDetails } from "../sheets/AlertDetails";

interface ViewDetailsProps {
  data: BookingsData | UserModel | RulesData | UserGroupsModel | AlertsData;
  type: DetailsType
}

const ViewDetails = ({ data, type }: ViewDetailsProps) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  // onClick function to open a modal with booking details
  const handleClick = () => {
    setDialogOpen(true);
  };

  function handleClose(state: boolean): void {
    setDialogOpen(false);
  }

  return (
    <>
      <button
        onClick={handleClick}
        className="bg-primary text-white rounded-lg px-6 py-3"
      >
        View Details
      </button>
      {dialogOpen ? <Sheet open={dialogOpen} onOpenChange={(val) => handleClose(val)}  children={
            getSheetContent(type, data, (val) => setDialogOpen(val))
          }/> : null}
    </>
  );
};

// create a function that uses a switch statement to return the correct sheet content based on the type
const getSheetContent = (type: DetailsType, data: BookingsData | UserModel | RulesData | UserGroupsModel | AlertsData, handleClose: (val: boolean) => void) => {
  switch (type) {
    case DetailsType.BOOKINGS:
      return <BookingsDetails booking={data as BookingsData} setSheetState={handleClose} />;
    case DetailsType.USERS:
      return <UserDetails user={data as UserModel} setSheetOpen={handleClose} />;
    case DetailsType.RULES:
      return <RulesDetails rule={data as RulesData} setDialogState={handleClose} />;
    case DetailsType.GROUPS:
      return <UserGroupDetails userGroup={data as UserGroupsModel} setDialogState={handleClose} />;
    case DetailsType.ALERTS:
      return <AlertDetails alert={data as AlertsData} />;
    default:
      return null;
  }
}


export default ViewDetails;
