import { addSpaceBetweenCapitalLetters } from "../../../utils/helper";

interface RoleChipProps {
    value: string;
}

export default function RoleChip({ value }: RoleChipProps) {
    return (
        <div
            className={`${getColor(value)
                } w-[150px] h-12 rounded-md text-center flex items-center justify-center text-md text-bold`}
        >
            {addSpaceBetweenCapitalLetters(value)}
        </div>
    );
}

function getColor(title: string) {
    switch (title) {
        case "TenantAdmin":
            return "bg-status-approved/40 text-status-approved";
        case "SuperAdmin":
            return "bg-status-exited/40 text-status-exited";
        case "User":
            return "bg-status-arrived/40 text-status-arrived";
        case "Disabled":
            return "bg-status-rejected/40 text-status-rejected";
        case "Guest":
            return "bg-status-pending/40 text-status-pending";
        default:
            return "bg-primary";
    }
}