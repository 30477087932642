import { RulesData } from "@/utils/apis/rules_repository";
import React from "react";
import AppliedTenants from "../DetailComponents/AppliedTenants";

// for applied / other users, groups and tenants -> show AutoComplete_Input field
// pass applied and non applied users, groups and tenants to each field
// store values here in the state. Pass it down with submit option. pass new rule up to the parent component

interface EmailAlertsProps {
    tenants: string[];
    appliedTenants: string[];
    setAppliedTenants: React.Dispatch<React.SetStateAction<string[]>>;
    setDataChanged: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EmailAlerts: React.FC<EmailAlertsProps> = ({  tenants, appliedTenants, setAppliedTenants, setDataChanged }) => {
    return (
        <AppliedTenants tenants={tenants} appliedTenants={appliedTenants} setAppliedTenants={setAppliedTenants} setDataChanged={setDataChanged} isDisabled={false}/>
    );
};

export default EmailAlerts;