import { cn } from "../../../utils/helper";
import * as React from "react";

export type SelectDropDownProps = React.SelectHTMLAttributes<HTMLSelectElement>;

const SelectDropDown = React.forwardRef<HTMLSelectElement, SelectDropDownProps>(
  ({ className, ...props }, ref) => {
    return (
      <select
        className={cn(
          "border-input ring-offset-background focus: outline-blue-500 placeholder:text-muted-foreground flex h-14 w-64 rounded-md border hover:border-2 focus-visible: outline-blue-500 bg-transparent px-4 pr-10 py-2 text-md disabled:cursor-not-allowed disabled:opacity-50 border-gray-400",
          className
        )}
        ref={ref}
        {...props}
      />
    );
  }
);
SelectDropDown.displayName = "SelectDropDown";

export { SelectDropDown as SelectDropDown };

// make the outline blue
// focus-visible: outline-blue-500
// hover:outline-blue-500