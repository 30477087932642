import { getTenant } from '../../../../../../utils/apis/tenants_repository';
import { BookingsData, getBookings } from '../../../../../../utils/apis/bookings_repository';
import React from "react";
import { Autocomplete, TextField } from '@mui/material';
import { Text } from '../../../../Text';
import { UserModel } from '../../../../../../utils/apis/user_repository';
import { Dialog, DialogContent } from '../../../../Dialog';
const { Button } = require('../../../../CustomButton');

//  for applied / other users, groups and tenants -> show AutoComplete_Input field
//  pass applied and non applied users, groups and tenants to each field
//  store values here in the state. Pass it down with submit option. pass new rule up to the parent component

interface VipProps {
    userList: UserModel[];
    appliedUsers: string[];
    setAppliedUsers: React.Dispatch<React.SetStateAction<string[]>>;
    setDataChanged: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Vip: React.FC<VipProps> = ({ userList, appliedUsers, setAppliedUsers, setDataChanged }) => {
    const [showWarning, setShowWarning] = React.useState<boolean>(false);
    const [holdTempUsers, setHoldTempUsers] = React.useState<string[]>([]);
    const [affectedBookings, setAffectedBookings] = React.useState<BookingsData[]>([]);
    const [exceededCategory, setExceededCategory] = React.useState<string>('');

    const handleChange = async (value: string[]) => {
        const newUser = value.filter(name => !appliedUsers.includes(name));
        if (newUser.length > 0) {
            console.log('newUser', newUser);
            const newUserObj = userList.filter(user => newUser.includes(user.name));
            const tenant = await getTenant(newUserObj[0].tenant);
            const bookings = await getBookings(tenant.name);
            const bookingsToShow: BookingsData[] = [];

            const perDayBookingCounts = bookings.reduce((acc, booking) => {
                const date = new Date(booking.startTime).toISOString().split('T')[0]
                acc[date] = acc[date] ? acc[date] + 1 : 1;
                bookingsToShow.push(booking);
                return acc;
            }, {} as { [key: string]: number });

            const maxPerDayBookingCount = Math.max(...Object.values(perDayBookingCounts));

            if (tenant.totalAllocation <= appliedUsers.length) {
                setExceededCategory('vips');
                setHoldTempUsers(value);
                setShowWarning(true);
            } else if (tenant.totalAllocation <= maxPerDayBookingCount + appliedUsers.length) {
                setExceededCategory('bookings');
                setAffectedBookings(bookingsToShow.map(booking => booking));
                setHoldTempUsers(value);
                setShowWarning(true);
            } else {
                setAppliedUsers(value);
                setDataChanged(true);
            }
        } else {
            console.log(value)
            setAppliedUsers(value);
            setDataChanged(true);
        }
    }

    const cancelProceed = () => {
        setHoldTempUsers([]);
        setShowWarning(false);
    }

    const handleProceed = () => {
        setAppliedUsers(holdTempUsers);
        setDataChanged(true);
        setShowWarning(false);
    }

    return (
        <>
            <Text className="mt-10" as="h3">
                Applied Users
            </Text>
            <Dialog open={showWarning} onOpenChange={(value) => {
                setShowWarning(value);
            }}>
                {exceededCategory === 'bookings' && (
                    <DialogContent>
                        <Text as="h3" className="text-center">Warning</Text>
                        <Text as="span" className="text-center">
                            <b>Following bookings will be affected by this action.</b><br />
                            {affectedBookings.map((booking) => {
                                return <>
                                    {booking.bookingId.substring(0, 3)} . . . {booking.bookingId.substring(booking.bookingId.length - 4)} __ {booking.email}, <br />
                                </>;
                            })}
                        </Text>
                        <div className="flex flex-row justify-center space-x-4 mt-6">
                            <Button onClick={() => cancelProceed()} variant="secondary" className="w-44 border-primary text-primaryText">Cancel</Button>
                            <Button onClick={() => handleProceed()} variant="secondary" className="w-44 border-primary text-primaryText">Proceed</Button>
                        </div>
                    </DialogContent>
                )}
                {exceededCategory === 'vips' && (
                    <DialogContent>
                        <Text as="h3" className="text-center">Warning</Text>
                        <Text as="span" className="text-center">
                            <b>Number of VIPs cannot exceed the total allocation. </b>
                        </Text>
                        <div className="flex flex-row justify-center space-x-4 mt-6">
                            <Button onClick={() => setShowWarning(false)} variant="secondary" className="w-44 border-primary text-primaryText">OK</Button>
                        </div>
                    </DialogContent>
                )}
                <div>
                    <Autocomplete
                        disablePortal
                        id="users"
                        options={userList.map(user => user.name)}
                        multiple
                        value={appliedUsers}
                        onChange={(event, value) => {
                            handleChange(value);
                        }}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Select Users" />}
                        className='my-4'
                    />
                </div>
            </Dialog>
        </>
    );
};

export default Vip;