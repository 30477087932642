import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "../../utils/helper";
import { Spinner } from "./Spinner";

const buttonStyle = cva(
    "relative inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 ring-offset-background",
    {
        variants: {
            variant: {
                default: "text-white bg-primary hover:bg-primary-dark focus-visible:ring-primary",
                secondary: "border-2 hover:bg-secondary-dark text-primaryText border-primary text-bold focus-visible:ring-secondary",
                danger: "text-white bg-danger hover:bg-danger-dark focus-visible:ring-danger",
            },
            size: {
                default: "h-12 py-2 px-4",
                sm: "h-9 px-3 rounded-md",
                xs: "h-7 min-w-0 px-2 rounded-md",
                full: "h-10 w-full rounded-md",
                lg: "h-11 px-8 rounded-md",
            },
            font: {
                default: "font-medium",
                bold: "font-bold",
            }
        },
        defaultVariants: {
            variant: "default",
            size: "default",
            font: "default",
        }
    }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonStyle> {
  isLoading?: boolean;
  asChild?: boolean;
}

const CustomButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
    ({ className, variant,  size, font, children, isLoading, ...props }, ref) => {
      return (
        <button
          className={cn(buttonStyle({ variant, size, font, className }))}
          ref={ref}
          {...props}
        >
          {isLoading ? (
            <div className="flex items-center">
              <Spinner fill={"white"} />
            </div>
          ) : (
            children
          )}
        </button>
      );
    }
  );
  CustomButton.displayName = "Button";
  
  export { CustomButton as Button, buttonStyle };