import ViewDetails from "../ViewDetails";
import { UserModel } from "../../../../utils/apis/user_repository";
import { Row } from "react-table";
import { DetailsType } from "../../../../utils/enum";
import RoleChip from '../../chips/RoleChip';

export const UserDataColumn = () => [
  {
    Header: <div style={{ textAlign: 'start' }}>Name</div>,
    accessor: "name",
    Cell: ({ value }: { value: string }) => <div className='text-center'>{value}</div>
  },
  {
    Header: <div style={{ textAlign: 'start' }}>Email</div>,
    accessor: "email",
    Cell: ({ value }: { value: string }) => <div className='text-center'>{value}</div>
  },
  {
    Header: <div style={{ textAlign: 'start' }}>Role</div>,
    accessor: "role",
    Cell: ({ value }: { value: string }) => <div className='flex justify-center'>
      <RoleChip value={value === undefined ? "User" : value} />
    </div>
  },
  {
    Header: <div style={{ textAlign: 'start' }}>Tenant</div>,
    accessor: 'tenant',
    Cell: ({ value }: { value: string }) => <div className='flex justify-center'>{value}</div>
  },
  {
    Header: <div style={{ textAlign: 'start' }}>Plates</div>,
    accessor: "vehicles",
    Cell: ({ value }: { value: string[] }) => <div className='flex justify-center'>{
      value === undefined ? 'No plates' : value[0]
    }</div> // new
  },
  {
    Header: <div style={{ textAlign: 'start' }}></div>,
    accessor: "details",
    Cell: ({ row }: { row: Row<UserModel> }) => <div className='text-center'>{<ViewDetails type={DetailsType.USERS} data={row.original as UserModel}></ViewDetails>}</div> // new
  },

];