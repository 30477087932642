
"use client"

import React, { useState } from 'react'
import { UserDetailsProps } from '../../UserDetails'
import { useForm, Form, FormProvider } from 'react-hook-form'
import { zodResolver } from "@hookform/resolvers/zod"
import { z } from "zod"
import { FormField, FormItem, FormLabel, FormControl } from '../../../inputs/Forms'
import Input from '../../../inputs/Input'
import SimpleDropdown, {SimpleDropdownProps } from '../../../containers/RoleDropdown'
import { Text } from '../../../Text'
import { Button } from '../../../CustomButton'
import { updateUser, UserUpdateModel } from '../../../../../utils/apis/user_repository'
import { mapRoleToEnum } from '../../../../../utils/helper'


export default function EditUserBody({ user , setSheetOpen: setDialogOpen }: UserDetailsProps) {
  const [editLoading, setEditLoading] = useState<boolean>(false);
  const [addVehicle, setAddVehicle] = useState<boolean>(false);
  const [newPlate, setNewPlate] = useState("");
  const [vehicles, setVehicles] = useState(user.vehicles || []);

  const personalDetailsFormSchema = z.object({
    name: z.string().min(3, "Name must be at least 3 characters long"),
  });

  const form = useForm<z.infer<typeof personalDetailsFormSchema>>({
    resolver: zodResolver(personalDetailsFormSchema),
    defaultValues: {
      name: user.name,
    },
  });

  const [role, setRole] = useState(mapRoleToEnum(user.role));

  const handleRoleChange: SimpleDropdownProps["onRoleChange"] = (newRole) => {
    setRole(newRole);
  };

  const onSubmit = (values: z.infer<typeof personalDetailsFormSchema>) => {
    console.log(values);
  };

  const saveChanges = () => {
    setEditLoading(true);

    const newRole = role.charAt(0).toUpperCase() + role.slice(1);

    const updatedUser: UserUpdateModel = {
      name: form.getValues().name,
      role: newRole,
      userId: user.userId,
    };
    updateUser(updatedUser).then(() => {
      setEditLoading(false);
    });
  };

  const onAddVehicle = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (addVehicle && newPlate.trim() !== "") {
      setVehicles([...vehicles, newPlate]);
      setNewPlate("");
      setAddVehicle(false);
    } else {
      setAddVehicle(true);
    }
  };

  const resetToDefaults = () => {
    form.reset({
      name: user.name,
    });
    setRole(mapRoleToEnum(user.role));
  };

  return (
    <div className='flex flex-col items-start '>
      <Text as="h3">Personal Details</Text>
      <FormProvider {...form}>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="flex mt-4 flex-col">
            <div className='flex flex-row justify-start mt-6 space-x-4 w-full'>
              <div className="flex-1">
                <FormField
                  control={form.control}
                  name="name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Username</FormLabel>
                      <FormControl>
                        <Input placeholder="shadcn" {...field} />
                      </FormControl>
                    </FormItem>
                  )}
                />
              </div>
            </div>
          </form>
        </Form>
      </FormProvider>
      <div className='flex mt-6 flex-col space-y-4'>
        <Text className='font-semibold' as="p">User Role</Text>
        <SimpleDropdown currentRole={role} onRoleChange={handleRoleChange} />
      </div>
      <div className='flex flex-row space-x-6 mt-10 self-end'>
        <Button onClick={resetToDefaults} variant="secondary" className="w-38 border-primary text-primaryText">Reset to Defaults</Button>
        <Button type="submit" form="form" onClick={saveChanges} isLoading={editLoading} className="w-38 bg-primary text-white">Save Changes</Button>
      </div>
      <Text className="mt-10" as="h3">Registered Vehicles</Text>
      <div className="flex justify-start space-x-8 mt-6 items-start">
        {
          vehicles.map((plate) => {
            return (
              <div className='w-44 h-12 text-black text-center justify-center items-center flex font-semibold border-primary border-2 rounded-md'>
                {plate}
              </div>
            )
          }, [])
        }
      </div>
      <form onSubmit={onAddVehicle} className="flex h-12 mt-8 flex-row space-x-4">
        {addVehicle ? (
          <Input
            className='h-12'
            placeholder="Enter Vehicle Plate"
            value={newPlate}
            onChange={(e) => setNewPlate(e.target.value)}
          />
        ) : null}
        <Button type="submit" variant="default" className="w-44 text-white">
           {
            addVehicle ? 'Add Plate' : 'Add Vehicle'
           }
        </Button>
      </form>
      <div>
      </div>
    </div>
  )
}
