import StatusChip from "../../chips/StatusChip";
import ViewDetails from "../ViewDetails";
import { getDate } from "../../../../utils/helper";
import { BookingsData } from "../../../../utils/apis/bookings_repository";
import { Row } from "react-table";
import { DetailsType } from "../../../../utils/enum";


export const BookingHistoryColumn = () => [
  {
    Header: <div style={{ textAlign: 'start' }}>Booking ID</div>,
    accessor: "bookingId",
    Cell: ({ value }: { value: string }) => <div className='text-center'>{value}</div>
  },
  {
    Header: <div style={{ textAlign: 'start' }}>Booking Date</div>,
    accessor: "startTime",
    Cell: ({ value }: { value: string }) => <div className='text-center'>{getDate(value)}</div>
  },
  {
    Header: <div style={{ textAlign: 'start' }}>User Email</div>,
    accessor: "email",
    Cell: ({ value }: { value: string }) => <div className='text-center'>{value}</div>
  },
  {
    Header: <div style={{ textAlign: 'start' }}>Type</div>,
    accessor: 'bookingType',
    Cell: ({ value }: { value: string }) => <div className='flex justify-center'>{value + ' Booking'}</div>
  },
  {
    Header: <div style={{ textAlign: 'start' }}>Status</div>,
    accessor: 'status',
    Cell: ({ value }: { value: string }) => <div className='flex justify-center text-white'>{<StatusChip value={value} />}</div>
  },
  {
    Header: <div style={{ textAlign: 'start' }}></div>,
    accessor: "details",
    Cell: ({ row }: { row: Row<BookingsData> }) => <div className='text-center'>{<ViewDetails type={DetailsType.BOOKINGS} data={row.original}></ViewDetails>}</div> // new
  },

];