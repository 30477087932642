import React, { useEffect } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { deleteTenant, getTenants, OnsiteAddTenant } from '../../utils/apis/tenants_repository';
import { Spinner } from '../../components/ui/Spinner';
import { IconButton } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import PencilIcon from '@mui/icons-material/Edit';
import CreateTenant from '../../components/ui/sheets/body/CreateTenantBody';

const Tenants = () => {
    const [isLoading, setIsLoading] = React.useState(false);
    // incase if Edit is needed
    const [selectedTenant, setSelectedTenant] = React.useState<OnsiteAddTenant | null>(null);

    const queryClient = useQueryClient();

    const { data: tenantData, isLoading: dataLoading, isError } = useQuery({
        queryKey: ['tenantData'],
        queryFn: () => getTenants(),
    })

    const handleDeleteTenant = async (tenantId: string) => {
        setIsLoading(true);
        const confirmDelete = window.confirm('Are you sure you want to delete this tenant? This action cannot be undone.');
        if (!confirmDelete) {
            console.log('Delete Tenant Cancelled');
            setIsLoading(false);
            return;
        }
        console.log('Deleting Tenant');
        await deleteTenant(tenantId).then(() => {
        }).then(() => {
            queryClient.invalidateQueries({ queryKey: ['tenantData'] });
            setIsLoading(false);
        });
    }

    const resetEdit = () => {
        console.log('Reset Edit');
        setSelectedTenant(null);
    }

    useEffect(() => {
        console.log("Selected Tenant", selectedTenant);
    }, [selectedTenant]);

    return (
        <div className='flex flex-col mx-14'>
            <div className="w-full flex flex-row mt-6">
                <div className="w-2/3 flex flex-row bg-white rounded-md">
                    <div className="flex flex-col w-full h-full mx-10">
                        <div className="text-xl font-bold text-gray-600 mt-4 mb-2">
                            Tenants
                        </div>
                        {
                            dataLoading || tenantData === undefined || isLoading
                                ?
                                <div className='justify-center items-center flex'>
                                    <Spinner className='justify-center items-center' />
                                </div>
                                :
                                <>
                                    <div className='flex flex-row justify-between items-center border-b-2 border-gray-200 py-2'>
                                        <div className='text-sm text-gray-600' style={{ width: '30%' }}>Tenant ID</div>
                                        <div className='text-sm text-gray-600' style={{ width: '20%', textAlign: 'center' }}>Name</div>
                                        <div className='text-sm text-gray-600' style={{ width: '20%', textAlign: 'center' }}>Total Allocation</div>
                                        <div className='text-sm text-gray-600' style={{ width: '20%', textAlign: 'center' }}>Occupancy</div>
                                        <div className='text-sm text-gray-600' style={{ width: '10%', textAlign: 'center' }}></div>
                                        <div className='text-sm text-gray-600' style={{ width: '10%', textAlign: 'center' }}></div>

                                    </div>
                                    {tenantData && tenantData.length > 0 && (
                                        <div className='flex flex-col'>
                                            {
                                                tenantData.map((tenant: any, index: number) => {
                                                    return (
                                                        <div key={index} className='flex flex-row justify-between items-center border-b-2 border-gray-200 py-2'>
                                                            <div className='text-sm text-gray-600' style={{ width: '30%' }}>{tenant.tenantId}</div>
                                                            <div className='text-sm text-gray-600' style={{ width: '20%', textAlign: 'center' }}>{tenant.name}</div>
                                                            <div className='text-sm text-gray-600' style={{ width: '20%', textAlign: 'center' }}>{tenant.totalAllocation}</div>
                                                            <div className='text-sm text-gray-600' style={{ width: '20%', textAlign: 'center' }}>{tenant.liveGuestOccupancy + tenant.liveVIPOccupancy + tenant.liveBookingOccupancy}</div>
                                                            <div className='text-sm text-gray-600' style={{ width: '10%', textAlign: 'center' }}>
                                                                <IconButton aria-label="delete" onClick={() => {
                                                                    console.log('Edit Tenant clicked', tenant);
                                                                    setSelectedTenant({
                                                                        username: tenant.name,
                                                                        allocation: tenant.totalAllocation,
                                                                        maxPlates: tenant.maxPlates,
                                                                        autoEnroll: tenant.autoEnroll,
                                                                        lprAccess: tenant.lprAccess,
                                                                        locations: tenant.locations,
                                                                        bookingApp: tenant.bookingApp,
                                                                        bookingAppData: tenant.bookingAppData,
                                                                        code: tenant.code,
                                                                        currentOccupancy: tenant.liveGuestOccupancy + tenant.liveVIPOccupancy + tenant.liveBookingOccupancy,
                                                                        image: tenant.image,
                                                                        towerLocation: tenant.towerLocation,
                                                                    });
                                                                }
                                                                }>
                                                                    <PencilIcon />
                                                                </IconButton>
                                                            </div>
                                                            <div className='text-sm text-gray-600' style={{ width: '10%', textAlign: 'center' }}>
                                                                <IconButton aria-label="delete" onClick={() => {
                                                                    handleDeleteTenant(tenant.tenantId);
                                                                }
                                                                }>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    )}
                                </>
                        }
                    </div>
                </div>
                <div className="w-1/3 ml-10 block flex-col bg-white rounded-md">
                    <div className="flex flex-col items-start justify-between mx-10 mt-5">
                        <div className="text-xl self-start font-bold text-gray-600">
                            {selectedTenant ? 'Edit Tenant' : 'Add Tenant'}
                        </div>
                        <div className="text-sm font-medium text-gray-400 mb-2">
                            {selectedTenant ? '' : 'Add a new tenant to the system and auto-generate values'}
                        </div>
                    </div>
                    <CreateTenant selectedTenant={selectedTenant} reset={resetEdit}/>
                </div>
            </div>
        </div>
    )
};

export default Tenants;