import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Text } from '../../components/ui/Text';

const PinVerification: React.FC = () => {
    const [pin, setPin] = useState<string>('');
    const [error, setError] = useState<string>('');
    const navigate = useNavigate();
    const { bookingId } = useParams<{ bookingId: string }>(); // Use bookingId from URL params

    const correctPin = '1234';

    const handlePinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPin(e.target.value);
    };

    const handleVerifyPin = () => {
        if (pin === correctPin) {
            navigate(`/valet/edit?bookingId=${bookingId}`); 
        } else {
            setError('Invalid PIN. Please try again.');
        }
    };

    return (
        <div className="w-full h-screen flex items-center justify-center">
            <div className="valetCard">
                <div className="bg-white flex flex-col items-center justify-center">
                    <Text as="h2" className="text-xl self-start font-bold text-gray-600 mt-2 mb-2">
                        Enter PIN to Edit Booking
                    </Text>
                    <input
                        type="password"
                        value={pin}
                        onChange={handlePinChange}
                        placeholder="Enter PIN"
                        className="border-input ring-offset-background focus:outline-blue-500 placeholder:text-muted-foreground h-14 w-64 rounded-md border hover:border-2 focus-visible:outline-blue-500 bg-transparent px-4 pr-10 py-2 text-md disabled:cursor-not-allowed disabled:opacity-50 border-gray-400"
                    />
                    <button
                        onClick={handleVerifyPin}
                        className="w-48 flex justify-center bg-primary h-12 py-2 px-4 text-white font-medium rounded-md mt-4"
                    >
                        Verify PIN
                    </button>
                    {error && <p className="error-message mt-2 text-red-500">{error}</p>}
                </div>
            </div>
        </div>
    );
};

export default PinVerification;
