import React from "react";
import { Divider } from "@mui/material";

export type TimeSelectorProps = {
    time: string;
    onChange: (time: string) => void;
};

function TimeSelector({ time, onChange }: TimeSelectorProps) {

    const [selectedHour, setSelectedHour] = React.useState<number>(parseInt(time.split(":")[0], 10));
    const [selectedMinute, setSelectedMinute] = React.useState<number>(parseInt(time.split(":")[1], 10));
    const [selectedPeriod, setSelectedPeriod] = React.useState<string>(time.split(" ")[1]);

    const handleHourChange = (e: number) => {
        const newHour = parseInt(e.toString(), 10);
        selectedMinute < 10 ? onChange(`${newHour}:0${selectedMinute} ${selectedPeriod}`) :
            onChange(`${newHour}:${selectedMinute} ${selectedPeriod}`);
        setSelectedHour(newHour);
    };

    const handleMinuteChange = (e: number) => {
        const newMinute = parseInt(e.toString(), 10);
        newMinute < 10 ? onChange(`${selectedHour}:0${newMinute} ${selectedPeriod}`) :
            onChange(`${selectedHour}:${newMinute} ${selectedPeriod}`);
        setSelectedMinute(newMinute);
    };

    const handlePeriodChange = (newPeriod: string) => {
        selectedMinute < 10 ? onChange(`${selectedHour}:0${selectedMinute} ${newPeriod}`) : onChange(`${selectedHour}:${selectedMinute} ${newPeriod}`);
        setSelectedPeriod(newPeriod);
    };

    const hours = Array.from({ length: 12 }, (_, index) => index + 1);
    const quarterHours = Array.from({ length: 4 }, (_, index) => index * 15);

    return (
        <div>
            <div className="flex h-72 bg-gray-50 justify-between py-3 px-4 flex-row">
                <div className="flex flex-col whitespace-nowrap overflow-y-auto py-1 px-1">
                    {hours.map((hour, index) => (
                        <>
                            <div className={`justify-center ${selectedHour === hour ? "bg-primary text-white" : ""} items-center flex px-4 hover:bg-slate-200 py-3 rounded-md`} key={hour} onClick={() => handleHourChange(hour)}>
                                {hour}
                            </div>
                            {index < hours.length - 1 && <Divider className="bg-secondaryText px-2" />}
                        </>
                    ))}
                </div>
                <div className="flex flex-col px-1 py-1">
                    {quarterHours.map((minute, index) => (
                        <>
                            <div className={`justify-center items-center ${selectedMinute === minute ? "bg-primary text-white" : ""} flex px-4 py-3 hover:bg-slate-200 rounded-md`} key={minute} onClick={() => handleMinuteChange(minute)}>
                                {minute === 0 ? "00" : minute}
                            </div>
                            {index < quarterHours.length - 1 && <Divider className="bg-secondaryText px-2" />}
                        </>
                    ))}
                </div>
                <div className="flex flex-col px-1 py-1">
                    {["AM", "PM"].map((period) => (
                        <div className={`justify-center items-center ${selectedPeriod === period ? "bg-primary text-white" : ""} flex px-4 py-3 hover:bg-slate-200 rounded-md`} key={period} onClick={() => handlePeriodChange(period)}>
                            {period}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default TimeSelector;
