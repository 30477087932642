import React from 'react';

interface ModalProps {
  closeModal: () => void;
}

const TermsModal: React.FC<ModalProps> = ({ closeModal }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>FCV Signage Terms and Conditions</h2>
        <p>
          Any person handing over possession of any Vehicle to FCV for the provision of Services does so under the
          terms and conditions set out below. If you do not accept the terms and conditions, you should proceed
          directly to the exit. If you do not exit, you are deemed to have agreed to the terms and conditions.
        </p>
        <ol>
          <li>
            All Customers agree to obey all signs displayed by FCV and any lawful directions given by FCV.
          </li>
          <li>
            Any persons handing over possession of any Vehicle do so at their own risk. FCV is not liable for any loss
            or damage to a Vehicle nor to any property or effects of any persons inside the Vehicle, nor any personal
            injury suffered by them, howsoever caused whether arising from any negligence of FCV or caused by any
            other Vehicle or person.
          </li>
          <li>
            FCV may:
            <ul>
              <li>
                a) Enter all Vehicles left in their possession for any reason whether or not they are locked and, if locked,
                may use whatever means necessary to enter the Vehicle;
              </li>
              <li>
                b) Move or drive any Vehicle to another place if FCV considers it necessary to do so;
              </li>
              <li>
                c) Prevent any Vehicle from leaving the location where the Services are being provided until the
                correct permit is produced or fee paid.
              </li>
            </ul>
          </li>
          <li>
            FCV will not accept any goods or chattels for safe custody. If any goods or chattels are left with FCV, they
            shall be at the Customer’s own risk, and FCV may, without liability in any case whatsoever, deliver or
            redeliver them to any person claiming them.
          </li>
          <li>
            The Customer or any other person will not deface, obliterate, or alter any parking permit, receipt, or ticket.
          </li>
          <li>
            The Customer warrants that he/she is either the owner of the Vehicle or has the authority of the owner of
            the Vehicle to have and pass possession of the Vehicle to FCV, and hereby indemnifies FCV against any
            and all claims made or action brought against them howsoever arising and against all costs or expenses
            incurred by them as a consequence of or in any way arising out of the Customer’s use of the Services.
          </li>
          <li>
            FCV nor any associated entity can be held liable for any duty of care to the customer or the customer’s
            property once the property has been returned to the customer, this includes in the event of a customer
            being intoxicated or engaging in other anti-social behavior.
          </li>
          <li>
            The customer acknowledges that the Valet Services operates between the hours of 6PM-11:30PM. Any
            collection of a vehicle after these hours will incur an after-hours collection fee. Note, NO collection will be
            available between 1:00AM and 9:00AM. After-hours charges are as follows:
            <ul>
              <li>a) Between 11:30PM-1:00AM – $150 inc. GST release fee</li>
              <li>b) After 9:00AM - $300 inc. GST release fee</li>
              <li>c) This fee increases by $300 inc. GST on a 24-hour basis.</li>
            </ul>
          </li>
          <li>
            FCV will not be bound by any variation to these terms and conditions unless the variation is in writing and
            signed by FCV. FCV may at any time vary these conditions.
          </li>
          <li>
            These terms and conditions apply to the exclusion of all others. If any are illegal or unenforceable, they may
            be severed from the remainder of these terms and conditions, which will remain valid. All exclusions of
            liability are subject to any law of the Commonwealth of Australia or the state of New South Wales restricting
            or prohibiting the exclusion of liability.
          </li>
        </ol>
        <p>
          “FCV” means First Class Valet (ABN 68 624 128 561) and includes where context permits its personnel,
          employees, servants, agents, or contractors.
        </p>
        <button onClick={closeModal} className="close-modal-btn">
          Close
        </button>
      </div>
    </div>
  );
};

export default TermsModal;
