import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from "@mui/material/FormControlLabel";
import { RulesData, getDefaultBookingTimes, getRule, updateRule, updateRuleValues } from "../../../utils/apis/rules_repository";
import { Text } from "../Text";
import { Button } from "../Button";
import { Divider } from "@mui/material";
import React from "react";
import { TenantsData, getTenants } from "../../../utils/apis/tenants_repository"
import { UserModel, getAllUsers, getUser } from "../../../utils/apis/user_repository";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import RuleHeader from "./body/RuleDetailComponents/Header";
import { getUserFromLocalStorage } from "../../../utils/helper"
import { UserGroupsModel, getUserGroups } from "../../../utils/apis/user_groups_repository";
import Weekend from "./body/RuleDetailComponents/Rules/Weekend";
import EmailAlerts from "./body/RuleDetailComponents/Rules/EmailAlerts";
import GracePeriod from "./body/RuleDetailComponents/Rules/GracePeriod";
import BookingLimit from "./body/RuleDetailComponents/Rules/BookingLimit";
import MultipleDays from "./body/RuleDetailComponents/Rules/MultipleDays";
import Access from "./body/RuleDetailComponents/Rules/Access";
import ReEntry from "./body/RuleDetailComponents/Rules/ReEntry";
import Vip from "./body/RuleDetailComponents/Rules/VIP";
import {FutureBookings} from "./body/RuleDetailComponents/Rules/FutureBookings";
import GuestEntry from "./body/RuleDetailComponents/Rules/GuestEntry";
import { grey, orange } from '@mui/material/colors';
import DefaultBookingTimes from './body/RuleDetailComponents/Rules/DefaultBookingTime';
import LastMinuteBooking from './body/RuleDetailComponents/Rules/LastMinuteBooking';
import EarlyArrival from './body/RuleDetailComponents/Rules/EarlyArrivals';

interface RulesDetailsProps {
    rule: RulesData;
    setDialogState: (state: boolean) => void;
}
interface GracePeriods {
    [tenant: string]: number; // Use tenant names as keys and store string values
}
interface EarlyArrivals {
    [tenant: string]: number; // Use tenant names as keys and store string values
}
interface FutureBookingsInterface {
    users: { [user: string]: number }; // Use user ids as keys and store string values
    userGroups: { [group: string]: number }; // Use group names as keys and store string values
    tenants: { [tenant: string]: number }; // Use tenant names as keys and store string values
}

interface BookingLimitsInterface {
    users: { [user: string]: number }; // Use user ids as keys and store string values
    userGroups: { [group: string]: number }; // Use group names as keys and store string values
    tenants: { [tenant: string]: number }; // Use tenant names as keys and store string values
}

interface BookingTimesInterface {
    startHour: number;
    endHour: number;
}
interface DefaultBookingTimesInterface {
    [tenant: string]: BookingTimesInterface; // Use tenant names as keys and store string values
}

interface LastMinuteBookingsInterface {
    users: { [user: string]: number }; // Use user ids as keys and store string values
    userGroups: { [group: string]: number }; // Use group names as keys and store string values
    tenants: { [tenant: string]: number }; // Use tenant names as keys and store string values
}
const RulesDetails = ({ rule, setDialogState }: RulesDetailsProps) => {
    const [allUsers, setAllUsers] = React.useState<UserModel[]>([]); // user objects
    const [userList, setUserList] = React.useState<string[]>([]); // user names
    const [appliedUsers, setAppliedUsers] = React.useState<string[]>([]);
    const [appliedUserGroups, setAppliedUserGroups] = React.useState<{ name: string, ruleValue: number }[]>([]);
    const [tenants, setTenants] = React.useState<TenantsData[]>([]);
    const [appliedTenants, setAppliedTenants] = React.useState<string[]>([]);
    const [dataChanged, setDataChanged] = React.useState<boolean>(false);
    const [gracePeriods, setGracePeriods] = React.useState<GracePeriods>({});
    const [earlyArrivals, setEarlyArrivals] = React.useState<EarlyArrivals>({});
    const [releventFutureBookings, setReleventFutureBookings] = React.useState<FutureBookingsInterface>({ users: {}, userGroups: {}, tenants: {} }); // for super admin, all users, user groups and tenants. for tenant admin, only the users and user groups of the tenant. for tenant, ju
    const [releventBookingLimits, setReleventBookingLimits] = React.useState<BookingLimitsInterface>({ users: {}, userGroups: {}, tenants: {} }); // for super admin, all users, user groups and tenants. for tenant admin, only the users and user groups of the tenant. for tenant, ju
    const [bookingTimes, setDefaultBookingTimes] = React.useState<DefaultBookingTimesInterface>({});
    const [lastMinBookingHours, setLastMinuteBookingHours] = React.useState<LastMinuteBookingsInterface>({ users: {}, userGroups: {}, tenants: {} });
    const [mobileOption, setMobileOption] = React.useState(rule.mobileOption);
    const queryClient = useQueryClient();
    const user = getUserFromLocalStorage();
    const role = user.role;


    React.useEffect(() => {
        // set mobile option value
        setMobileOption(rule.mobileOption);
        const appliedUserNames: string[] = [];

        const gracePeriods: GracePeriods = {};
        const earlyArrivals: EarlyArrivals = {};
        const futureBookingPeriods: FutureBookingsInterface = { users: {}, userGroups: {}, tenants: {} };
        const bookingLimits: BookingLimitsInterface = { users: {}, userGroups: {}, tenants: {} };
        const lastMinuteBookingHours: LastMinuteBookingsInterface = { users: {}, userGroups: {}, tenants: {} };

        rule.users.forEach((user) => {
            appliedUserNames.push(user.name);
        });
        getDefaultBookingTimes().then((data) => {
            setDefaultBookingTimes(data);
        });
        setAppliedUsers(appliedUserNames);
        if (rule.userGroups && rule.userGroups.length > 0) {
            setAppliedUserGroups(rule.userGroups.map((group: UserGroupsModel) => ({ name: group.name, ruleValue: rule.title === "Booking Limit" ? (group.bookingLimit ? group.bookingLimit : 0) : rule.title === "Future Bookings" ? (group.futureBookingPeriod ? group.futureBookingPeriod : 0) : 0 })));
        }
        setAppliedTenants(rule.tenants);
        getTenants().then((data) => {
            setTenants(data);
            data.forEach((tenant) => {
                // for each tenant, if the tenant name is in rule.tenants, get and set grace period and future booking period
                gracePeriods[tenant.name] = tenant.gracePeriod;

                //not sure about this line
                earlyArrivals[tenant.name] = tenant.gracePeriod;

                futureBookingPeriods.tenants[tenant.name] = tenant.futureBookingPeriod;
                bookingLimits.tenants[tenant.name] = tenant.bookingLimit;
                lastMinuteBookingHours.tenants[tenant.name] = tenant.lastMinuteBookingPeriod;
            });
        });
        getUserGroups().then((data) => {
            data.forEach((group) => {
                futureBookingPeriods.userGroups[group.name] = group.futureBookingPeriod ? group.futureBookingPeriod : 30;
                bookingLimits.userGroups[group.name] = group.bookingLimit ? group.bookingLimit : 0;
                lastMinuteBookingHours.userGroups[group.name] = group.lastMinuteBookingPeriod ? group.lastMinuteBookingPeriod : 1;
            });
        });
        if (role === "SuperAdmin") {
            getAllUsers().then((data) => {
                const fileteredUsers = data.filter((user) => user.role !== "Pending");
                setAllUsers(fileteredUsers.map((user) => user));
                setUserList(fileteredUsers.map((user) => `${user.name}`));
                fileteredUsers.forEach((user) => {
                    futureBookingPeriods.users[user.name] = user.futureBookingPeriod;
                    bookingLimits.users[user.name] = user.bookingLimit;
                    lastMinuteBookingHours.users[user.name] = user.lastMinuteBookingPeriod;
                });
            })
        } else {
            getAllUsers(user.tenant).then((data) => {
                const fileteredUsers = data.filter((user) => user.role !== "Pending");
                setAllUsers(fileteredUsers.map((user) => user));
                setUserList(fileteredUsers.map((user) => `${user.name}`));
                fileteredUsers.forEach((user) => {
                    futureBookingPeriods.users[user.name] = user.futureBookingPeriod;
                    bookingLimits.users[user.name] = user.bookingLimit;
                    lastMinuteBookingHours.users[user.name] = user.lastMinuteBookingPeriod;
                });
            });
        }
        setGracePeriods(gracePeriods);
        setEarlyArrivals(earlyArrivals);
        setReleventFutureBookings(futureBookingPeriods);
        setReleventBookingLimits(bookingLimits);
        setLastMinuteBookingHours(lastMinuteBookingHours);
    }, []); // set applied grace periods, future booking periods, tenants, usergroups and booking limits

    const mutation = useMutation({
        mutationFn: updateRule,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['rules'] });
            queryClient.invalidateQueries({ queryKey: ['userGroups'] });
        },
    })

    const submitChanges = async () => {
        setDataChanged(false);
        let userIdList: string[] = [];
        if (appliedUsers && appliedUsers.length > 0) {
            // push the user ids to userIdList
            appliedUsers.forEach((appliedUser) => {
                const user = allUsers.find((user) => user.name === appliedUser);
                if (user) {
                    userIdList.push(user.userId);
                }
            });
        }
        const originalRule = await getRule(rule.title);
        const originalTenants = originalRule?.tenants;
        const originalUsers = originalRule?.users;
        // create arrays to assign the values that wasn't fetched/displayed due to the role of the user
        const otherTenants: string[] = [];
        const otherUsers: string[] = [];
        // create arrays to assign the removed tenants and users to the rule
        const removedTenants: string[] = [];
        const removedUsers: string[] = [];

        try {
            if (role === "TenantAdmin") {
                // get the original rule from the database. since the rule is tenant specific, we need to append the users and tenants from other tenants before updating the rule
                if (originalTenants) {
                    originalTenants.forEach((tenant) => {
                        if (!appliedTenants.includes(tenant) && tenant !== user.tenant) {
                            otherTenants.push(tenant);
                        } else if (!appliedTenants.includes(tenant) && tenant === user.tenant) {
                            removedTenants.push(tenant);
                        }
                    });
                }
                // if the original rule has users that are not in the appliedUsers, and if the tenant of the user is not the same as the tenant of the current user, add them to otherUsers
                if (originalUsers) {
                    await Promise.all(originalUsers.map(async (rawUser) => {
                        const userObj = await getUser(rawUser.userId);
                        if (userObj && !appliedUsers?.includes(userObj.name) && userObj.tenant !== user.tenant) {
                            otherUsers.push(userObj.userId);
                        } else if (userObj && !appliedUsers?.includes(userObj.name) && userObj.tenant === user.tenant) {
                            removedUsers.push(userObj.userId);
                            console.log("Removed user", userObj);
                        }
                    }));
                }
            } else if (role === "SuperAdmin") {
                // only push the removed tenants and users to the arrays
                if (originalTenants) {
                    originalTenants.forEach((tenant) => {
                        if (!appliedTenants.includes(tenant)) {
                            removedTenants.push(tenant);
                        }
                    });
                }
                if (originalUsers) {
                    originalUsers.forEach((rawUser) => {
                        const userObj = allUsers.find((user) => user.userId === rawUser.userId);
                        if (userObj && !appliedUsers?.includes(userObj.name)) {
                            removedUsers.push(userObj.userId);
                        }
                    });
                }
            }
        } catch (error) {
            console.log(error);
        }

        await updateRuleValues(rule, appliedUsers, removedUsers, appliedUserGroups.map((group) => group.name), appliedTenants, removedTenants, allUsers, gracePeriods, releventFutureBookings, releventBookingLimits, bookingTimes, lastMinBookingHours);

        console.log(rule)
        console.log(appliedUsers)
        console.log(appliedUserGroups)
        console.log(appliedTenants)
        // update the table
        mutation.mutate({
            title: rule.title,
            summary: rule.summary,
            description: rule.description,
            users: role === "TenantAdmin" ? userIdList.map((userId) => ({ name: "", userId: userId })).concat(otherUsers.map((user) => ({ name: "", userId: user }))) : userIdList.map((userId) => ({ name: "", userId: userId })),
            userGroups: rule.userGroups,
            tenants: role === "TenantAdmin" ? appliedTenants.concat(otherTenants) : appliedTenants,
            status: rule.status,
            statuses: rule.statuses,
            value: rule.value,
            mobileOption: mobileOption,
        })


        // close sheet
        setDialogState(false);
    };

    return (
        <div className="items-between mx-6">
            <div className="flex flex-row items-center justify-between">
                <Text as="h2">Rule Details</Text>
                <div className="flex flex-row space-x-8 items-center">
                    {dataChanged && (
                        <Button
                            className="bg-primary w-38"
                            onClick={submitChanges}
                            style={{ animation: "wobble 1s" }}
                        >
                            Save Changes
                        </Button>
                    )}
                </div>
            </div>
            <div className="pt-4">
                <Divider className="bg-secondaryText" />
            </div>
            <div className="overflow-y-auto overflow-x-hidden max-h-[90vh]">
                <RuleHeader rule={rule} user={user} />

                {rule.title === "Weekend" && (
                    <Weekend userList={userList} appliedUsers={appliedUsers} setAppliedUsers={setAppliedUsers} appliedUserGroups={appliedUserGroups.map((g) => g.name)} tenants={user.role === "SuperAdmin" ? tenants.map((t) => t.name) : tenants.filter((t) => t.name === user.tenant).map((tObjj) => tObjj.name)} appliedTenants={appliedTenants} setAppliedTenants={setAppliedTenants} setDataChanged={setDataChanged}></Weekend>
                )}
                {rule.title === "Email Alerts" && (
                    <EmailAlerts tenants={user.role === "SuperAdmin" ? tenants.map((t) => t.name) : tenants.filter((t) => t.name === user.tenant).map((tObjj) => tObjj.name)} appliedTenants={appliedTenants} setAppliedTenants={setAppliedTenants} setDataChanged={setDataChanged}></EmailAlerts>
                )}
                {rule.title === "Last Minute Booking" && (
                    <LastMinuteBooking rule={rule} users={userList} appliedUsers={appliedUsers} setAppliedUsers={setAppliedUsers} appliedUserGroups={appliedUserGroups.map((g) => g.name)} tenants={user.role === "SuperAdmin" ? tenants.map((t) => t.name) : tenants.filter((t) => t.name === user.tenant).map((tObjj) => tObjj.name)} appliedTenants={appliedTenants} setAppliedTenants={setAppliedTenants} bookingLimitOverrideHours={lastMinBookingHours} setBookingLimitOverrideHours={setLastMinuteBookingHours} setDataChanged={setDataChanged}></LastMinuteBooking>
                )}
                {rule.title === "Default Booking Time" && (
                    <DefaultBookingTimes rule={rule} tenants={user.role === "SuperAdmin" ? tenants.map((t) => t.name) : tenants.filter((t) => t.name === user.tenant).map((tObjj) => tObjj.name)} appliedTenants={appliedTenants} setAppliedTenants={setAppliedTenants} bookingTimes={bookingTimes} setDefaultBookingTimes={setDefaultBookingTimes} setDataChanged={setDataChanged}></DefaultBookingTimes>
                )}
                {rule.title === "Grace Period" && (
                    <GracePeriod rule={rule} tenants={user.role === "SuperAdmin" ? tenants.map((t) => t.name) : tenants.filter((t) => t.name === user.tenant).map((tObjj) => tObjj.name)} appliedTenants={appliedTenants} setAppliedTenants={setAppliedTenants} gracePeriods={gracePeriods} setGracePeriods={setGracePeriods} setDataChanged={setDataChanged}></GracePeriod>
                )}
                {rule.title === "Early Arrivals" && (
                    <EarlyArrival rule={rule} tenants={user.role === "SuperAdmin" ? tenants.map((t) => t.name) : tenants.filter((t) => t.name === user.tenant).map((tObjj) => tObjj.name)} appliedTenants={appliedTenants} setAppliedTenants={setAppliedTenants} earlyArrivals={earlyArrivals} setEarlyArrivals={setEarlyArrivals} setDataChanged={setDataChanged}></EarlyArrival>
                )}
                {rule.title === "Booking Limit" && (
                    <BookingLimit rule={rule} users={userList} appliedUsers={appliedUsers} setAppliedUsers={setAppliedUsers} appliedUserGroups={appliedUserGroups.map((g) => g.name)} tenants={user.role === "SuperAdmin" ? tenants.map((t) => t.name) : tenants.filter((t) => t.name === user.tenant).map((tObjj) => tObjj.name)} appliedTenants={appliedTenants} setAppliedTenants={setAppliedTenants} bookingLimits={releventBookingLimits} setBookingLimits={setReleventBookingLimits} setDataChanged={setDataChanged}></BookingLimit>
                )}
                {rule.title === "Multiple Days" && (
                    <MultipleDays userList={userList} appliedUsers={appliedUsers} setAppliedUsers={setAppliedUsers} appliedUserGroups={appliedUserGroups.map((g) => g.name)} tenants={user.role === "SuperAdmin" ? tenants.map((t) => t.name) : tenants.filter((t) => t.name === user.tenant).map((tObjj) => tObjj.name)} appliedTenants={appliedTenants} setAppliedTenants={setAppliedTenants} setDataChanged={setDataChanged}></MultipleDays>
                )}
                {rule.title === "Access" && (
                    <Access tenants={user.role === "SuperAdmin" ? tenants.map((t) => t.name) : tenants.filter((t) => t.name === user.tenant).map((tObjj) => tObjj.name)} appliedTenants={appliedTenants} setAppliedTenants={setAppliedTenants} setDataChanged={setDataChanged}></Access>
                )}
                {rule.title === "ReEntry" && (
                    <ReEntry tenants={user.role === "SuperAdmin" ? tenants.map((t) => t.name) : tenants.filter((t) => t.name === user.tenant).map((tObjj) => tObjj.name)} appliedTenants={appliedTenants} setAppliedTenants={setAppliedTenants} setDataChanged={setDataChanged}></ReEntry>
                )}
                {rule.title === "VIP" && (
                    <Vip userList={allUsers} appliedUsers={appliedUsers} setAppliedUsers={setAppliedUsers} setDataChanged={setDataChanged}></Vip>
                )}
                {rule.title === "Future Bookings" && (
                    <FutureBookings users={userList} appliedUsers={appliedUsers} setAppliedUsers={setAppliedUsers} appliedUserGroups={appliedUserGroups.map((g) => g.name)} tenants={user.role === "SuperAdmin" 
                        ? tenants 
                        : tenants.filter((t) => t.name === user.tenant)} appliedTenants={appliedTenants} setAppliedTenants={setAppliedTenants} rfutureBookingPeriods={releventFutureBookings} rsetFutureBookingPeriods={setReleventFutureBookings} setDataChanged={setDataChanged} ></FutureBookings>
                )}
                {rule.title === "Guest Entry" && (
                    <GuestEntry tenants={user.role === "SuperAdmin" ? tenants.map((t) => t.name) : tenants.filter((t) => t.name === user.tenant).map((tObjj) => tObjj.name)} appliedTenants={appliedTenants} setAppliedTenants={setAppliedTenants} setDataChanged={setDataChanged}></GuestEntry>
                )}
                <FormControlLabel control={<Checkbox sx={{
                    color: grey[600],
                    '&.Mui-checked': {
                        color: orange[600],
                    },
                }} checked={mobileOption ? mobileOption[user.tenant] ? mobileOption[user.tenant] : false : false} onChange={(e) => {
                    setMobileOption({ ...mobileOption, [user.tenant]: e.target.checked });
                    setDataChanged(true);
                    console.log("mobile option is turned :", e.target.checked)
                }}
                />} label="Show in mobile options" />
            </div>
        </div>
    );
};

export default RulesDetails;
