import { getUserFromLocalStorage } from '../../utils/helper';
import { getTenant } from '../../utils/apis/tenants_repository';
import { getEntriesPerWeek, getOccupancyPerWeek, getTenantAlerts } from '../../utils/apis/reports_repository';
import { useQuery } from '@tanstack/react-query';
import { Spinner } from '../../components/ui/Spinner';
import BarChart from '../../components/ui/charts/BarChart';
import LineChart from '../../components/ui/charts/LineChart';
import DoughnutChart from '../../components/ui/charts/DoughnutChart';
import { DoughnutLabel } from '../../components/ui/containers/DoughnutLabel';
import { ListItem } from '../../components/ui/containers/ListItem';

const ReportsBody = () => {

    const user = getUserFromLocalStorage();

    const { data: tenantData, isLoading: dataLoading, isError } = useQuery({
        queryKey: ['tenantData'],
        queryFn: () => getTenant(user.tenant),
    })

    const { data: occupancyData, isLoading: occupancyLoading, isError: occupancyError } = useQuery({
        queryKey: ['occupancyData'],
        queryFn: () => getOccupancyPerWeek(),
    })

    const { data: entryData, isLoading: entryLoading, isError: entryError } = useQuery({
        queryKey: ['entryData'],
        queryFn: () => getEntriesPerWeek(),
    })

    const { data: tenantAlerts, isLoading: tenantAlertsLoading, isError: tenantAlertsError } = useQuery({
        queryKey: ['tenantAlerts'],
        queryFn: () => getTenantAlerts(user.tenant),
    })

    if (isError || occupancyError || entryError || tenantAlertsError) {
        return <div className="flex justify-center">Something went wrong</div>
    }

    if (dataLoading || occupancyLoading || entryLoading || tenantAlertsLoading) {
        return <div className='flex justify-center items-center'>
            <Spinner />
        </div>
    }

    return (
        <>
            <div className='flex flex-col mx-14'>
                <div className="w-full flex flex-row">
                    <div className="w-1/3 flex flex-row bg-white rounded-md">
                        <div className="flex flex-col items-center mx-10 my-2">
                            <div className="text-xl self-start font-bold text-gray-600 mt-2 mb-2">
                                Live Tenant Occupancy
                            </div>
                            <div className="text-sm font-medium text-gray-400 mb-2">
                                The number of Tenants at the car park along with the types of Tenants.
                            </div>
                            <div className='w-48 h-48'>

                                {
                                    dataLoading || tenantData === undefined ? <div className='justify-center items-center flex'>
                                        <Spinner className='justify-center items-center' />
                                    </div> :
                                        DoughnutChart({ chartData: tenantData })}
                            </div>
                            <DoughnutLabel />
                        </div>
                    </div>
                    <div className="w-2/3 flex flex-row ml-10 bg-white rounded-md">
                        <div className="flex flex-col w-full mx-10 my-3">
                            <div className="text-xl font-bold text-gray-600 mt-2">
                                Tenant Occupancy
                            </div>
                            <div className="text-sm font-medium text-gray-400 mb-2">
                                Breakdown of the number of Tenants at the car park.
                            </div>
                            <div className='w-full h-full my-3'>
                                {occupancyLoading || occupancyData === undefined ? <Spinner /> : <BarChart tenantName={user.tenant} chartData={occupancyData} />}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full h-[450px] flex flex-row mt-6">
                    <div className="w-2/3 flex flex-row bg-white rounded-md">
                        <div className="flex flex-col w-full h-full mx-10">
                            <div className="text-xl font-bold text-gray-600 mt-4 mb-2">
                                Weekly Bookings
                            </div>
                            <div className='w-full h-full py-4'>
                                {entryLoading || entryData === undefined ? <Spinner /> :
                                    <LineChart chartData={entryData} tenantName={user.tenant} />}

                            </div>
                        </div>
                    </div>
                    <div className="w-1/3 ml-10 block flex-col bg-white rounded-md">
                        <div className="flex flex-col items-start justify-between mx-10 mt-5">
                            <div className="text-xl self-start font-bold text-gray-600">
                                Tenant Alerts
                            </div>
                            <div className="text-sm font-medium text-gray-400 mb-2">
                                Alerts received for the Tenant
                            </div>
                        </div>
                        {
                            tenantAlertsLoading || tenantAlerts === undefined ? <div className='justify-center items-center flex'>
                                <Spinner className='justify-center items-center block' />
                            </div> :
                                tenantAlerts.length > 0 ? tenantAlerts.map((alert, index) => {
                                    if (index < 3) {
                                        return <ListItem data={alert} key={index} />
                                    }
                                }) : <div className='flex justify-center items-center'>No Alerts</div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default ReportsBody;