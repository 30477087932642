import { Autocomplete, TextField } from '@mui/material';
import { Text } from '../../../../Text';

interface AppliedUsersProps {
    users: string[];
    appliedUsers: string[];
    setAppliedUsers: React.Dispatch<React.SetStateAction<string[]>>;
    setDataChanged: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AppliedUsers: React.FC<AppliedUsersProps> = ({ users, appliedUsers, setAppliedUsers, setDataChanged }) => {
    return (
        <>
            <Text className="mt-10" as="h3">
                Applied Users
            </Text>
            <Autocomplete
                disablePortal
                id='users'
                options={users}
                multiple
                value={appliedUsers}
                onChange={(event, value) => {
                    setAppliedUsers(value);
                    setDataChanged(true);
                }}
                sx={{ width: 460 }}
                renderInput={(params) => <TextField {...params} label="Select Users" />}
                className='my-4'
            />
        </>
    );
}

export default AppliedUsers;