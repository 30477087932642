import { Firestore } from "firebase/firestore";

interface LogOperation {
    type: "create" | "read" | "update" | "delete";
    collection: "users" | "bookings" | "history" | "rules" | "tenants" | "userGroups";
    documentId: string | null;
    message: string | null;
}

interface LogModel {
    // user data
    author: {
        authId: string;
        name: string;
        email: string;
    };
    // firestore data
    operation: LogOperation;
    // browser data
    client: {
        origin: string;
        userAgent: string;
    };
    timestamp: Date;
}

const log = async (operation: LogOperation) => {
    try {
        const db = await import("firebase/firestore");
        const firestore: Firestore = db.getFirestore();
        const logsRef = db.collection(firestore, "logs");
        const user = JSON.parse(localStorage.getItem("user")?.toString() || "{}");
        const logData: LogModel = {
            author: {
                authId: user?.uid || "",
                name: user?.name || "",
                email: user?.email || "",
            },
            operation: operation,
            client: {
                origin: window.location.origin,
                userAgent: window.navigator.userAgent,
            },
            timestamp: new Date(),
        }
        // console.log("Logging: ", logData);
        await db.addDoc(logsRef, logData);
        return;
    } catch (error) {
        console.error(error);
        return;
    }
};

export default log;