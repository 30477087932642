"use client";

import * as React from "react";
import { TimerIcon } from "lucide-react";

import { cn } from "../../../utils/helper";
import { Popover, PopoverContent, PopoverTrigger, PopoverPortal } from "../Popover";
import { Button } from "../Button";
import TimeSelector from "./TimeSelecter";

export interface TimePickerProps {
  initialTime?: string;
  onChange: (value: string) => void;
}

export const TimePicker: React.FC<TimePickerProps> = ({
  onChange,
  initialTime,
}) => {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          className={cn(
            "w-[200px] justify-between text-left font-medium text-sm h-12 px-4 border border-gray-300 rounded-md text-gray-700 bg-white hover:bg-gray-100",
            !initialTime && "text-muted-foreground"
          )}
        >
          {initialTime ? initialTime : <span className="text-secondaryText">Select a Time</span>}
          <TimerIcon className="h-5 w-5" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <TimeSelector time={initialTime ?? "12:00 AM"} onChange={onChange} />
      </PopoverContent>
    </Popover>
  );
};
