import { Autocomplete, TextField } from '@mui/material';
import { Text } from '../../../../Text';

interface AppliedTenantsProps {
    tenants: string[];
    appliedTenants: string[];
    setAppliedTenants: React.Dispatch<React.SetStateAction<string[]>>;
    setDataChanged: React.Dispatch<React.SetStateAction<boolean>>;
    isDisabled: boolean;
}

export const AppliedTenants: React.FC<AppliedTenantsProps> = ({ tenants, appliedTenants, setAppliedTenants, setDataChanged, isDisabled }) => {
    return (
        <>
            <Text className="mt-10" as="h3">
                Applied Tenants
            </Text>
            <Autocomplete
                disablePortal
                disabled={isDisabled}
                id='tenants'
                options={tenants}
                multiple
                value={appliedTenants}
                onChange={(event, value) => {
                    setAppliedTenants(value);
                    setDataChanged(true);
                }}
                sx={{ width: 460 }}
                renderInput={(params) => <TextField {...params} label="Select Tenants" />}
                className='my-4'
            />
        </>
    );
}

export default AppliedTenants;