import React from "react";
import { UserGroupsModel } from "../../../../../utils/apis/user_groups_repository";
import UserGroupsHeader from "./Header";
import UserGroupsBody from "./Body";

interface CreateUserGroupBodyProps {
    setDialogState: (state: boolean) => void;
}

const CreateUserGroupBody = ({ setDialogState }: CreateUserGroupBodyProps) => {

    const [showSubmitButton, setShowSubmitButton] = React.useState<boolean>(false);

    const [userGroup, setUserGroup] = React.useState<UserGroupsModel>({
        name: '',
        description: '',
        tenant: '',
        users: [],
        appliedRules: []
    });

    return (
        <>
            <div className="flex flex-col items-between mt-12 mx-6">
                
                <UserGroupsHeader title="Create User Group" description="Create User Groups Within Tenants" showSubmitButton={showSubmitButton} userGroup={userGroup} setDialogState={setDialogState} setShowSubmitButton={setShowSubmitButton}></UserGroupsHeader>
                <UserGroupsBody setUserGroup={setUserGroup} setShowSubmitButton={setShowSubmitButton}></UserGroupsBody>
            </div>
        </>
    );
}

export default CreateUserGroupBody;