import React, { useState } from "react";
import { UserGroupsModel } from "../../../../../utils/apis/user_groups_repository";
import UserGroupsHeader from "./Header";
import UserGroupsBody from "./Body";

interface UserGroupsDetailsProps {
    userGroup: UserGroupsModel;
    setDialogState: (state: boolean) => void;
}

const UserGroupDetails = ({ userGroup, setDialogState }: UserGroupsDetailsProps) => {

    const [showSubmitButton, setShowSubmitButton] = useState(false);
    const [userGroupState, setUserGroup] = useState<UserGroupsModel>(userGroup);

    React.useEffect(() => {
        setUserGroup(userGroup);
        setShowSubmitButton(false);
    }
    , []);

    return (
        <>
            <div className="flex flex-col items-between mt-12 mx-6">
                <UserGroupsHeader title="User Group" description="User Group Details" showSubmitButton={showSubmitButton} userGroup={userGroupState} setDialogState={setDialogState} setShowSubmitButton={setShowSubmitButton}></UserGroupsHeader>
                <UserGroupsBody setUserGroup={setUserGroup} setShowSubmitButton={setShowSubmitButton} userGroup={userGroupState}></UserGroupsBody>
            </div>
        </>
    );
}

export default UserGroupDetails