
import React from 'react'
import { Text } from '../../../Text'
import { Button } from '../../../CustomButton'
import RoleChip from '../../../chips/RoleChip'
import { DetailsComponent } from '../../../containers/Details'
import { UserModel } from '../../../../../utils/apis/user_repository'

export default function UserDetailsBody({ user }: { user: UserModel }) {
    return (
        <div className='flex flex-col'>
            <Text as="h3">Personal Details</Text>
            <div className="flex flex-row space-x-8 mt-6 items-center">
                <DetailsComponent title="Full Name" description={user.name} />
                <DetailsComponent title="Email Address" description={user.email} />
                <DetailsComponent title="Cardholder Unique ID" description={user.gallagherId} />
            </div>
            <Text as="h3" className="mt-10">User Role</Text>
            <div className="mt-6">
                <RoleChip value={user.role === undefined ? "User" : user.role} />
            </div>
            <Text className="mt-10" as="h3">Registered Vehicles</Text>
            <div className="flex justify-start space-x-8 mt-6 items-start">{
                user.vehicles?.map((plate) => {
                    return (
                        <Button variant="default" className="w-44 text-white">{plate}</Button>
                    )
                }, [])
            }</div>
        </div>
    )
}
