interface DetailsProps {
    title: string;
    description: string;
}

export const DetailsComponent = ({ title, description }: DetailsProps) => {
    return (
        <>
            <div className="h-24 w-[220px] px-4 flex flex-col text-center border-2 rounded-md border-secondaryText justify-center items-center">
                <span className="text-secondaryText text-md font-medium">{title}</span>
                <span className="text-primaryText mt-1 text-xs font-bold">{description}</span>
            </div>
        </>
    );
}