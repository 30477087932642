import React from "react";
import { Row } from "react-table";
import ViewDetails from "../ViewDetails";
import { UserGroupsModel } from "../../../../utils/apis/user_groups_repository";
import { DetailsType } from "../../../../utils/enum";

export const UserGroupsColumn = () => [
    {
        Header: <div style={{ textAlign: 'start' }}>Name</div>,
        accessor: "name",
        Cell: ({value} : {value: string}) => <div className='text-center'>{value}</div>
      },
      {
        Header: <div style={{ textAlign: 'start' }}>Description</div>,
        accessor: "description",
        Cell: ({value} : {value: string}) => <div className='text-center'>{value}</div>
      },
      {
        Header: <div style={{ textAlign: 'start' }}>Tenant</div>,
        accessor: "tenant",
        Cell: ({value} : {value: string}) => <div className='text-center'>{value}</div>
      },
      {
        Header: <div style={{ textAlign: 'start' }}>Users</div>,
        accessor: "users",
        Cell: ({value} : {value:any[]}) => <div className='text-center'>{value.length === 0 ? "N/A": 
        value.map((user, index) => {
            return <span key={index}>{user.name}{index !== value.length - 1 ? ", " : ""}</span>
        })
        }</div>
      },
      {
        Header: <div style={{ textAlign: 'start' }}>Applied Rules</div>,
        accessor: "appliedRules",
        Cell: ({value} : {value: string[]}) => <div className='text-center'>{value.length === 0 ? "N/A" :
        value.map((rule, index) => {
            return <span key={index}>{rule}{index !== value.length - 1 ? ", " : ""}</span>
        })
        }</div>
      },
      {
        Header: <div style={{ textAlign: 'start' }}></div>,
        accessor: "details",
        Cell: ({row} : {row : Row<UserGroupsModel>}) => <div className='text-center'>{<ViewDetails type={DetailsType.GROUPS} data={row.original as UserGroupsModel}></ViewDetails>}</div> // new
      },
        
];