import React, { ReactNode, ButtonHTMLAttributes } from 'react';
import {classNames} from '../../../utils/helper';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    children: ReactNode;
    className?: string;
}

interface PageButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    children: ReactNode;
    className?: string;
}
  
export function TableButton({ children, className, ...rest }: ButtonProps) {
    return (
      <button
        type="button"
        className={classNames(
          "relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50",
          className
        )}
        {...rest}
      >
        {children}
      </button>
    );
}

export function PageButton({ children, className, ...rest }: PageButtonProps) {
    return (
      <button
        type="button"
        className={classNames(
          "relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50",
          className
        )}
        {...rest}
      >
        {children}
      </button>
    );
}




