
import './App.scss';
import { RouterProvider } from 'react-router-dom';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import router from './routes/Router';
import { ClientToastContainer } from './components/ClientToastContainer';

function App() {

  const queryClient = new QueryClient()

  return (
    <QueryClientProvider client={queryClient}>
    <RouterProvider router={router} />
    <ClientToastContainer />
    </QueryClientProvider>
  )

}

export default App;
