import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { Button } from "../../components/ui/CustomButton";
import InputFormField from "../../components/ui/inputs/InputFormField";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { getUserFromLocalStorage } from "../../utils/helper";
import { sendPasswordResetEmailToUser } from "../../firebase/firebase";
import { useEffect, useState } from "react";

const editPwScheme = z.object({
  email: z
    .string()
    .email("Invalid email")
    .refine((val) => val.trim().length > 0, {
      message: "Email is required",
    }),
});

type EditPwScheme = z.infer<typeof editPwScheme>;

interface EditPwDialogProps {
  open: boolean;
  onClose: () => void;
  selectedValue: string;
  shouldDisable: boolean;
}

const EditPwDialog = (props: EditPwDialogProps) => {
  const { onClose, selectedValue, open, shouldDisable } = props;
  const [saveChanges, setSaveChanges] = useState(false);

  const formData = useForm<EditPwScheme>({
    mode: "onBlur",
    resolver: zodResolver(editPwScheme),
    defaultValues: {
      email: selectedValue,
    },
  });

  const handleClose = () => {
    if (saveChanges) {
      sendPasswordResetEmailToUser(formData.getValues().email);
      setSaveChanges(false);
    }
    onClose();
  };

  useEffect(() => {
    if (saveChanges) {
      console.log("saving changes");
      handleClose();
    }
  }, [saveChanges]);

  return (
    <Dialog onClose={handleClose} open={open}>
      <div className="m-10">
        <DialogTitle id="simple-dialog-title" className="flex flex-row items-center justify-center">Reset Password</DialogTitle>
        <FormProvider {...formData}>
          <InputFormField
            name="email"
            label="Email Address"
            placeholder="Enter your email"
            className="mt-3 mb-3"
            onChange={(e) => {
                formData.setValue("email", e.target.value);
            }}
            disabled={shouldDisable}
          />
        </FormProvider>
        <div className="flex flex-row justify-end">
          <Button
            className="mt-3 mb-3"
            onClick={() => {
                setSaveChanges(true);
            }}
          >
            Send Reset Link to Email
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default EditPwDialog;
