import { createBrowserRouter } from "react-router-dom";
import AuthLayout from "../layouts/AuthLayout";
import RequireAuth from "../components/require-auth";
import AppLayout from "../layouts/AppLayout";

import SignIn from "../pages/authentication/signin";

import BookingsBody from "../pages/navbar/Bookings";
import ProfileBody from "../pages/navbar/Profile";
import ReportsBody from "../pages/navbar/Reports";
import UsersBody from "../pages/navbar/Users";
import UserGroups from "../pages/navbar/UserGroups";
import Tenants from "../pages/navbar/Tenants";
import RulesBody from "../pages/navbar/Rules";
import ErrorPage from "../components/error";
import UnAuthPage from "../components/unauth";
import Payment from "../pages/navbar/Payment";
import EditBooking from "../pages/valet/Editbooking";
import CancelBooking from "../pages/valet/CancelBooking";
import PinVerification from "../pages/valet/VerificationPin";
import BookingsHistoryBody from "../pages/navbar/History";
import ComingSoon from "../components/ui/containers/ComingSoon";


const router = createBrowserRouter([
    {
        element: <AuthLayout />,
        children: [
            {
                path: '/',
                element: <SignIn />
            },
            {
                path: '/signin',
                element: <SignIn />
            },
            {
                path: '/register',
                element: <h1>Register</h1>
            }
        ]
    },
    {
        element: <RequireAuth />,
        children: [
            {
                element: <AppLayout />,
                children: [
                    {
                        path: '/profile',
                        element: <ProfileBody />
                    },
                    {
                        path: '/bookings',
                        element: <BookingsBody />
                    },
                    {
                        path: '/bookings/history',
                        element: <BookingsHistoryBody />
                    },
                    {
                        path: '/reports',
                        element: <ReportsBody />
                    },
                    {
                        path: '/users',
                        element: <UsersBody />
                    },
                    {
                        path: '/usergroups',
                        element: <UserGroups />
                    },
                    {
                        path: '/tenants',
                        element: <Tenants />
                    },
                    {
                        path: '/rules',
                        element: <RulesBody />
                    }
                ]
            }
        ]
    },
    {
        path: "/unauth",
        element: <UnAuthPage />,
    },
    {
        path: "*",
        element: <ErrorPage />,
    },
    // Valet routes showing "Coming Soon"
    {
        element: <AppLayout />,
        children: [
            {
                path: '/valet',
                element: <Payment /> 
            },
            {
                path: '/valet/verify-pin/:bookingId',
                element: <PinVerification /> 
            },
            {
                path: '/valet/edit',
                element: <EditBooking />
            },
            {
                path: '/valet/cancel',
                element: <CancelBooking /> 
            }
        ],
    },
    // Valet-test routes showing actual content
    {
        element: <AppLayout />,
        children: [
            {
                path: '/valet-test',
                element: <ComingSoon /> 
            },
            {
                path: '/valet-test/verify-pin/:bookingId',
                element: <ComingSoon /> 
            },
            {
                path: '/valet-test/edit',
                element: <ComingSoon /> 
            },
            {
                path: '/valet-test/cancel',
                element: <ComingSoon /> 
            }
        ],
    },
]);


export default router;