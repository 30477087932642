import { Line } from "react-chartjs-2";
import { registerables, Chart as ChartJS } from 'chart.js';
import { Occupancy } from "@/utils/apis/reports_repository";
import { getDate , getDayOfWeek } from "../../../utils/helper";

ChartJS.register(...registerables);


interface LineChartProps {
  chartData: Occupancy[];
  tenantName: string;
}

export default function LineChart({ chartData, tenantName }: LineChartProps) {

  const options = {
    
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
        },
        annotations: {
          line1 : {
            type: 'line',
            yMin: 5,
            yMax: 5,
            borderColor: 'rgba(239, 202, 8, 1)',
            borderWidth: 2,
          },
        },
      },
      scales: {
        y: {
          min: 0,
          max: 10,
        }
      }
    
  }

  const data = {
    labels: chartData.map((chartData) => getDayOfWeek(chartData.date) === 'Monday' ? getDayOfWeek(chartData.date) : getDate(chartData.date)),
    datasets: [
      {
        label: 'Expected Bookings',
        data: chartData.map((data) => data.expectedEntries != null ? data.expectedEntries.filter((entry) => entry.tenant === tenantName)?.length || 0 : 0),
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
        ],

        borderWidth: 2,
      },
      {
        label: 'Actual Bookings',
        data: chartData.map((data) => data.entries != null ? data.entries.find((entry) => entry.tenantName === tenantName)?.booking || 0 : 0),
        backgroundColor: [
          'rgba(107, 127, 215, 0.2)',
        ],
        borderColor: [
          'rgba(107, 127, 215, 1)',
        ],
        borderWidth: 2,
      },
    ],

  };


  return (
    <>
      <Line data={data} options={
        options
      } />
    </>
  )
}

