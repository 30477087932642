import { TextField } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import InputFormField from '../../inputs/InputFormField';
import { SelectDropDown } from '../../inputs/SelectDropDown';
import { Button } from '../../CustomButton';
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { getTenant, OnsiteAddTenant, sendTenantToOnsite, TenantsData, updateTenant } from '../../../../utils/apis/tenants_repository';
import { showToast } from '../../../../utils/toast';
import { useQueryClient } from '@tanstack/react-query';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from "@mui/material/FormControlLabel";
import { grey, orange } from '@mui/material/colors';
import { useEffect, useState } from 'react';
import { Spinner } from '../../Spinner';
import { getRules } from '../../../../utils/apis/rules_repository';

interface CreateTenantProps {
    selectedTenant: OnsiteAddTenant | null;
    reset: () => void;
}

const addOnsiteTenantScheme = z.object({
    name: z.string().min(1, "Name is required"),
    allocation: z.string(),
    locations: z.array(z.string()),
    autoEnroll: z.boolean(),
    lprAccess: z.boolean(),
    maxPlates: z.string(),
});

type AddOnsiteTenantScheme = z.infer<typeof addOnsiteTenantScheme>;

export const CreateTenant: React.FC<CreateTenantProps> = ({ selectedTenant, reset }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [locations, setLocations] = useState<string[]>(selectedTenant?.locations || []);
    const [autoEnroll, setAutoEnroll] = useState<boolean>(selectedTenant?.autoEnroll || false);
    const [lprAccess, setLprAccess] = useState<boolean>(selectedTenant?.lprAccess || false);
    const [firestoreTenant, setFirestoreTenant] = useState<TenantsData | null>(null);

    const formData = useForm<AddOnsiteTenantScheme>({
        mode: "onBlur",
        resolver: zodResolver(addOnsiteTenantScheme),
        defaultValues: {
            name: "",
            allocation: "",
            locations: [],
            autoEnroll: false,
            lprAccess: false,
            maxPlates: "",
        },
    });

    // UseEffect to update form values and states when selectedTenant changes
    useEffect(() => {
        if (selectedTenant) {
            console.log('Sent Tenant: ', selectedTenant);
            formData.setValue("name", selectedTenant.username || "");
            formData.setValue("allocation", selectedTenant.allocation.toString() || "0");
            formData.setValue("maxPlates", selectedTenant.maxPlates?.toString() || "0");
            setLocations(selectedTenant.locations || []);
            setAutoEnroll(selectedTenant.autoEnroll || false);
            setLprAccess(selectedTenant.lprAccess || false);
            getTenant(selectedTenant.username).then((tenant) => {
                setFirestoreTenant(tenant);
            });
        } else {
            formData.setValue("name", "");
            formData.setValue("allocation", "");
            formData.setValue("maxPlates", "");
            setLocations([]);
            setAutoEnroll(false);
            setLprAccess(false);
        }
    }, [selectedTenant]);

    useEffect(() => {
        console.log('Sent Tenant: ', selectedTenant);
    }, []);

    useEffect(() => {
        formData.setValue("locations", locations);
    }, [locations]);

    useEffect(() => {
        formData.setValue("autoEnroll", autoEnroll);
    }, [autoEnroll]);

    useEffect(() => {
        formData.setValue("lprAccess", lprAccess);
    }, [lprAccess]);

    const queryClient = useQueryClient();

    const submitTenant = async (tenant: OnsiteAddTenant) => {
        console.log('Submit Tenant: ', firestoreTenant?.tenantRules);
        const rulesData = await getRules();

        const tenantRulesMap = rulesData.reduce((acc, rule) => {
            switch (rule.title) {
                case 'gracePeriod':
                    acc.gracePeriod = parseInt(rule.value, 10) || 0;
                    break;
                case 'futureBookingPeriod':
                    acc.futureBookingPeriod = parseInt(rule.value, 10) || 0;
                    break;
                case 'bookingLimit':
                    acc.bookingLimit = parseInt(rule.value, 10) || 0;
                    break;
                case 'lastMinuteBookingPeriod':
                    acc.lastMinuteBookingPeriod = parseInt(rule.value, 10) || 0;
                    break;
              
                default:
                    break;
            }
            return acc;
        }, {
            gracePeriod: 0,
            futureBookingPeriod: 0,
            bookingLimit: 0,
            lastMinuteBookingPeriod: 1,  
        });


        await sendTenantToOnsite(tenant, selectedTenant ? 'update' : 'add').then(async (status) => {
            if (status) {
                await updateTenant({
                    name: formData.getValues("name"),
                    totalAllocation: parseInt(formData.getValues("allocation")),
                    gracePeriod: tenantRulesMap.gracePeriod,  
                    futureBookingPeriod: tenantRulesMap.futureBookingPeriod, 
                    bookingLimit: tenantRulesMap.bookingLimit,  
                    lastMinuteBookingPeriod: tenantRulesMap.lastMinuteBookingPeriod, 
                    fulldayDefaultTimes: {
                        startHour: 800,
                        endHour: 1800,
                    },
                    liveBookingOccupancy: 0,
                    liveGuestOccupancy: 0,
                    liveVIPOccupancy: 0,
                    availableOccupancy: tenant.allocation,
                    isWeekend: firestoreTenant?.isWeekend || false,
                    isMulti: firestoreTenant?.isMulti || false,
                    tenantRules: [],
                    tenantId: 'weirdAbcID012',
                    autoEnroll: tenant.autoEnroll,
                    lprAccess: tenant.lprAccess,
                    locations: tenant.locations,
                    maxPlates: tenant.maxPlates,
                    towerLocation: tenant.towerLocation,
                }).then(() => {
                    queryClient.invalidateQueries({ queryKey: ['tenantData'] });
                    formData.setValue("name", '');
                    formData.setValue("allocation", "");
                    formData.setValue("maxPlates", "");
                    setLocations([]);
                    setAutoEnroll(false);
                    setLprAccess(false);
                    showToast("Tenant Added Successfully", { type: 'success' });
                }).catch(() => {
                    showToast("Error Adding Tenant", { type: 'error' });
                });
            } else {
                showToast("Error Adding Tenant", { type: 'error' });
            }
        }).catch(() => {
            showToast("Error Adding Tenant", { type: 'error' });
        });
        setIsLoading(false);
    }

    return (
        <>
            <div className="m-10" aria-disabled={isLoading}>
                <FormProvider {...formData} >
                    <div className="flex flex-row items-center justify-between mt-3 mb-3">
                        <TextField id="clean" variant="standard" contentEditable={false} margin='none' disabled defaultValue={"Tenant Name"}
                            InputProps={{
                                disableUnderline: true,
                            }}
                        />
                        <InputFormField name="name" disabled={isLoading || selectedTenant !== null}
                            onChange={(e) => {
                                formData.setValue("name", e.target.value);
                            }}
                        />
                    </div>
                    <div className="flex flex-row items-center justify-between mt-3 mb-3">
                        <TextField id="clean" variant="standard" contentEditable={false} margin='none' disabled defaultValue={"Allocation"}
                            InputProps={{
                                disableUnderline: true,
                            }}
                        />
                        <InputFormField name="allocation" disabled={isLoading}
                            onChange={(e) => {
                                formData.setValue("allocation", e.target.value);
                            }}
                        />
                    </div>
                    <div className="flex flex-row items-center justify-between mt-3 mb-3">
                        <TextField id="clean" variant="standard" contentEditable={false} margin='none' disabled defaultValue={"Max Plates"}
                            InputProps={{
                                disableUnderline: true,
                            }}
                        />
                        <InputFormField name="maxPlates" disabled={isLoading}
                            onChange={(e) => {
                                formData.setValue("maxPlates", e.target.value);
                            }}
                        />
                    </div>
                    <div className="flex flex-row items-center justify-between mb-3">
                        <TextField id="clean" variant="standard" contentEditable={false} margin='none' disabled defaultValue={"Auto Enroll"}
                            InputProps={{
                                disableUnderline: true,
                            }}
                        />
                        <SelectDropDown className="bg-white" disabled={isLoading} onChange={(e) => {
                            setAutoEnroll(e.target.value === "true");
                        }
                        } value={autoEnroll.toString()}>
                            {
                                [true, false].map((item, index) => {
                                    return <option key={index} value={item.toString()}>{item.toString()}</option>
                                })
                            }
                        </SelectDropDown>
                    </div>
                    <div className="flex flex-row items-center justify-between mb-3">
                        <TextField id="clean" variant="standard" contentEditable={false} margin='none' disabled defaultValue={"LPR Access"}
                            InputProps={{
                                disableUnderline: true,
                            }}

                        />
                        <SelectDropDown className="bg-white" disabled={isLoading} onChange={(e) => {
                            setLprAccess(e.target.value === "true");
                        }
                        } value={lprAccess.toString()}>
                            {
                                [true, false].map((item, index) => {
                                    return <option key={index} value={item.toString()}>{item.toString()}</option>
                                })
                            }
                        </SelectDropDown>
                    </div>
                    <div className="flex flex-row items-center justify-between mb-3">
                        <TextField id="clean" variant="standard" contentEditable={false} margin='none' disabled defaultValue={"Tower Locations"}
                            InputProps={{
                                disableUnderline: true,
                            }}
                        />
                        <FormControlLabel control={<Checkbox sx={{
                            color: grey[600],
                            '&.Mui-checked': {
                                color: orange[600],
                            },
                        }} checked={locations.includes("B1")} disabled={isLoading}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setLocations([...locations, "B1"]);
                                } else {
                                    setLocations(locations.filter((item) => item !== "B1"));
                                }
                            }}
                        />} label="B1" />
                        <FormControlLabel control={<Checkbox sx={{
                            color: grey[600],
                            '&.Mui-checked': {
                                color: orange[600],
                            },
                        }} checked={locations.includes("B2")} disabled={isLoading}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setLocations([...locations, "B2"]);
                                } else {
                                    setLocations(locations.filter((item) => item !== "B2"));
                                }
                            }}
                        />} label="B2" />
                    </div>
                </FormProvider>
                <div className="flex flex-row justify-end">
                    <Button
                        className="mt-3 mb-3 mx-3 bg-gray-500"
                        onClick={() => {
                            reset();
                        }
                        }
                    >
                        {
                            isLoading ? (
                                <div className='justify-center items-center flex'>
                                    <Spinner className='justify-center items-center' />
                                </div>
                            ) :
                                <>
                                    Discard Changes
                                </>
                        }
                    </Button>
                    <Button
                        className="mt-3 mb-3"
                        onClick={() => {
                            setIsLoading(true);
                            const tenantToSubmit: OnsiteAddTenant = {
                                username: formData.getValues("name"),
                                code: formData.getValues("name"),
                                allocation: parseInt(formData.getValues("allocation")),
                                locations: locations,
                                autoEnroll: autoEnroll,
                                lprAccess: lprAccess,
                                maxPlates: parseInt(formData.getValues("maxPlates")),
                                image: "",
                                towerLocation: "T1",
                                currentOccupancy: 0,
                                bookingApp: true,
                                bookingAppData: {
                                    reEntry: false,
                                    noBookingEntry: true,
                                    permanentParker: 0,
                                    reservedAllocation: 0,
                                    liveBookingOccupancy: 0,
                                    livePermanentParkingOccupancy: 0,
                                    accessRequired: false,
                                },
                            }
                            submitTenant(tenantToSubmit);
                        }
                        }
                    >
                        {
                            isLoading ? (
                                <div className='justify-center items-center flex'>
                                    <Spinner className='justify-center items-center' />
                                </div>
                            ) :
                                <>
                                    {selectedTenant ? "Update Tenant" : "Create Tenant"}
                                </>
                        }
                    </Button>
                </div>
            </div>
        </>
    );
}

export default CreateTenant;