import React from "react";
import AppliedUsers from "../DetailComponents/AppliedUsers";
import AppliedUserGroups from "../DetailComponents/AppliedUserGroups";
import AppliedTenants from "../DetailComponents/AppliedTenants";

// for applied / other users, groups and tenants -> show AutoComplete_Input field
// pass applied and non applied users, groups and tenants to each field
// store values here in the state. Pass it down with submit option. pass new rule up to the parent component

interface WeekendProps {
    userList: string[];
    appliedUsers: string[];
    setAppliedUsers: React.Dispatch<React.SetStateAction<string[]>>;
    appliedUserGroups: string[];
    tenants: string[];
    appliedTenants: string[];
    setAppliedTenants: React.Dispatch<React.SetStateAction<string[]>>;
    setDataChanged: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Weekend: React.FC<WeekendProps> = ({ userList, appliedUsers, setAppliedUsers, appliedUserGroups, tenants, appliedTenants, setAppliedTenants, setDataChanged }) => {
    return (
        <>
            <AppliedUsers users={userList} appliedUsers={appliedUsers} setAppliedUsers={setAppliedUsers} setDataChanged={setDataChanged} />

            <AppliedUserGroups appliedUserGroups={appliedUserGroups} />

            <AppliedTenants tenants={tenants} appliedTenants={appliedTenants} setAppliedTenants={setAppliedTenants} setDataChanged={setDataChanged} isDisabled={false}/>
        </>
    );
};

export default Weekend;