import { RulesData } from "@/utils/apis/rules_repository";
import React from "react";
import { Text } from '../../../../Text';
import { TextField } from "@mui/material";
import { SelectDropDown } from '../../../../inputs/SelectDropDown';
import AppliedUsers from "../DetailComponents/AppliedUsers";
import AppliedUserGroups from "../DetailComponents/AppliedUserGroups";
import AppliedTenants from "../DetailComponents/AppliedTenants";


interface LastMinuteBookingsInterface {
    users: { [user: string]: number }; // Use user ids as keys and store string values
    userGroups: { [group: string]: number }; // Use group names as keys and store string values
    tenants: { [tenant: string]: number }; // Use tenant names as keys and store string values
}
interface LastMinuteBookingProps {
    rule: RulesData;
    users: string[];
    appliedUsers: string[];
    appliedUserGroups: string[];
    tenants: string[];
    appliedTenants: string[];
    bookingLimitOverrideHours: LastMinuteBookingsInterface;
    setAppliedUsers: React.Dispatch<React.SetStateAction<string[]>>;
    // setAppliedUserGroups: React.Dispatch<React.SetStateAction<{ name: string, ruleValue: number }[]>>;
    setAppliedTenants: React.Dispatch<React.SetStateAction<string[]>>;
    setBookingLimitOverrideHours: React.Dispatch<React.SetStateAction<LastMinuteBookingsInterface>>;
    setDataChanged: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LastMinuteBooking: React.FC<LastMinuteBookingProps> = ({ rule, users, appliedUsers, setAppliedUsers, appliedUserGroups, tenants, appliedTenants, setAppliedTenants, bookingLimitOverrideHours, setBookingLimitOverrideHours, setDataChanged }) => {
    return (
        <>
            <AppliedUsers users={users} appliedUsers={appliedUsers} setAppliedUsers={setAppliedUsers} setDataChanged={setDataChanged} />

            <Text as='p'> User Booking Limits </Text>

            {appliedUsers.map((user) => {
                return (
                    <div className='wrapper' key={user}>
                        <div className='bg-white py-4'>
                            <TextField
                                id="clean"
                                variant="standard"
                                InputProps={{
                                    disableUnderline: true,
                                }}
                                contentEditable={false}
                                margin='none'
                                disabled
                                defaultValue={user}
                            />
                        </div>
                        <div>
                            <SelectDropDown className="bg-white" onChange={(e) => {
                                setBookingLimitOverrideHours({ ...bookingLimitOverrideHours, users: { ...bookingLimitOverrideHours.users, [user]: parseInt(e.target.value) } });
                                setDataChanged(true);
                            }
                            } value={bookingLimitOverrideHours.users[user] ? bookingLimitOverrideHours.users[user] : parseInt(rule.value)}>
                                {/* 1,2,4,8,12,16,24,48 */}
                                <option value={1}>1 Hour</option>
                                <option value={2}>2 Hours</option>
                                <option value={4}>4 Hours</option>
                                <option value={8}>8 Hours</option>
                                <option value={12}>12 Hours</option>
                                <option value={16}>16 Hours</option>
                                <option value={24}>24 Hours</option>
                                <option value={48}>48 Hours</option>
                            </SelectDropDown>
                        </div>
                    </div>
                )
            }
            )}

            <AppliedUserGroups appliedUserGroups={appliedUserGroups} />
            
            <Text as='p'> User Group Booking Limits </Text>

            {appliedUserGroups.map((group) => {
                return (
                    <div className='wrapper' key={group}>
                        <div className='bg-white py-4'>
                            <TextField
                                id="clean"
                                variant="standard"
                                InputProps={{
                                    disableUnderline: true,
                                }}
                                contentEditable={false}
                                margin='none'
                                disabled
                                defaultValue={group}
                            />
                        </div>
                        <div>
                            <SelectDropDown className="bg-white" onChange={(e) => {
                                setBookingLimitOverrideHours({ ...bookingLimitOverrideHours, userGroups: { ...bookingLimitOverrideHours.userGroups, [group]: parseInt(e.target.value) } });
                                setDataChanged(true);
                            }
                            } value={bookingLimitOverrideHours.userGroups[group]}>
                                {/* 1,2,4,8,12,16,24,48 */}
                                <option value={1}>1 Hour</option>
                                <option value={2}>2 Hours</option>
                                <option value={4}>4 Hours</option>
                                <option value={8}>8 Hours</option>
                                <option value={12}>12 Hours</option>
                                <option value={16}>16 Hours</option>
                                <option value={24}>24 Hours</option>
                                <option value={48}>48 Hours</option>
                            </SelectDropDown>
                        </div>
                    </div>
                )
            }
            )}

            <AppliedTenants tenants={tenants} appliedTenants={appliedTenants} setAppliedTenants={setAppliedTenants} setDataChanged={setDataChanged} isDisabled={false}/>

            <Text as='p'> Tenant Booking Limits </Text>

            {appliedTenants.map((tenant) => {
                return (
                    <div className='wrapper' key={tenant}>
                        <div className='bg-white py-4'>
                            <TextField
                                id="clean"
                                variant="standard"
                                InputProps={{
                                    disableUnderline: true,
                                }}
                                contentEditable={false}
                                margin='none'
                                disabled
                                defaultValue={tenant}
                            />
                        </div>
                        <div>
                            <SelectDropDown className="bg-white" onChange={(e) => {
                                setBookingLimitOverrideHours({ ...bookingLimitOverrideHours, tenants: { ...bookingLimitOverrideHours.tenants, [tenant]: parseInt(e.target.value) } });
                                setDataChanged(true);
                            }
                            } value={bookingLimitOverrideHours.tenants[tenant] ? bookingLimitOverrideHours.tenants[tenant] : parseInt(rule.value)}>
                                {/* 1,2,4,8,12,16,24,48 */}
                                <option value={1}>1 Hour</option>
                                <option value={2}>2 Hours</option>
                                <option value={4}>4 Hours</option>
                                <option value={8}>8 Hours</option>
                                <option value={12}>12 Hours</option>
                                <option value={16}>16 Hours</option>
                                <option value={24}>24 Hours</option>
                                <option value={48}>48 Hours</option>
                            </SelectDropDown>
                        </div>
                    </div>
                )
            }
            )}
        </>
    );
};

export default LastMinuteBooking;