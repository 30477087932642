'use client';

import React from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

export const ClientToastContainer = () => {
  return (
    <><ToastContainer position="bottom-right" /></>
  )
}
