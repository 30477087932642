import React from 'react';

const ComingSoon = () => {
  return (
    <div className="coming-soon-container">
      <div className="coming-soon-content">
        <h1>🚧 Coming Soon 🚧</h1>
        <p>We are working on this feature. Stay tuned!</p>
      </div>
    </div>
  );
};

export default ComingSoon;
