import { format } from "date-fns"
import { Calendar as CalendarIcon } from "lucide-react"
import { useState } from "react"
import { cn } from "../../../utils/helper"
import { Button } from "../Button"
import { Calendar } from "../Calendar"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../Popover"

interface DatePickerProps {
  initialDate?: Date
  setNewDate: (date: Date) => void
}
 
export function DatePicker({
  initialDate,
  setNewDate,
 }: DatePickerProps) {
  const [date, setDate] = useState<Date>();

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          className={cn(
            "w-[280px] justify-start text-left font-medium text-sm h-12 px-4 border border-gray-300 rounded-md text-gray-700 bg-white hover:bg-gray-100",
            !date && "text-muted-foreground"
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {date ? format(date, "PPP") : initialDate ? format(initialDate, "PPP") : <span className="text-secondaryText">Select Booking Date</span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="single"
          className="bg-gray-50"
          selected={date ?? initialDate}
          disabled={{ before:  new Date() }}
          onSelect= {
            (newDate) => {
              setDate(newDate);
              setNewDate(newDate!);
            }
          }
          initialFocus
        />
      </PopoverContent>
    </Popover>
  )
}