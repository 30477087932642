import { convertTimestampToDate, convertTimestampToTime } from "../../../utils/helper";
import { Timestamp } from "firebase/firestore";

interface EventChipProps {
    type: string;
    status: string;
    timestamp: Timestamp;
    key?: string;
}

export default function EventsChip({ status, type, timestamp, key }: EventChipProps) {
    return (
        <div
            className={`${getColor(status)
                } w-full px-4 py-3 rounded-md text-center flex items-center justify-center text-md text-bold`}
        >
            {`${
                getStatus(status, type)
            } on ${convertTimestampToDate(timestamp)} at ${convertTimestampToTime(timestamp)}`}
        </div>
    );
}

// function where if status is arrived, return the type of status
function getStatus(status: string , type: string) {
    if (status === "Arrived") {
        return type;
    }
    return status;
}

// create a function that changes the background color and text color based on the title
function getColor(status: string) {
    switch (status) {
        case status.includes("Approved") ? status : "":
            return "bg-status-approved/40 text-status-approved";
        case "Updated":
            return "bg-status-updated/40 text-status-updated";
        case "Exit":
            return "bg-status-exited/40 text-status-exited";
        case "Arrived":
            return "bg-status-arrived/40 text-status-arrived";
            
        case "Update Rejected":
            return "bg-status-rejected/40 text-status-rejected";
        case "Pending":
            return "bg-status-pending/40 text-status-pending";
        case "Rejected":
            return "bg-status-rejected/40 text-status-rejected";
        case "Completed":
            return "bg-status-completed/40 text-status-completed";
        case "Expired":
            return "bg-status-expired/40 text-status-expired";
        default:
            return "bg-primary";
    }
}