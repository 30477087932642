import { initializeApp } from "firebase/app";
import {
  getAuth,
  onAuthStateChanged,
  signOut,
  signInWithEmailAndPassword,
  NextOrObserver,
  User,
  OAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail,
} from "firebase/auth";
import { getFirebaseConfig } from "./firebase-config";
import { collection, doc, getDoc, getFirestore } from "firebase/firestore";
import log from "../utils/logger";

const app = initializeApp(getFirebaseConfig());
const auth = getAuth(app);

export const signInUser = async (email: string, password: string) => {
  if (!email && !password) return;
  const userCredential = await signInWithEmailAndPassword(auth, email, password)
    .then(async (userCredential) => {
      const dbUser = await getFirestoreUser(userCredential.user.uid);
      log({collection: "users", documentId: userCredential.user.uid, message: "User signed in", type: "read"});
      return { ...userCredential.user, ...dbUser, error: null };
      // return userCredential;
    })
    .catch((error) => {
      if (error.code === "auth/user-not-found") {
        return { error: "Invalid email" };
      } else if (error.code === "auth/wrong-password") {
        return { error: "Invalid password " };
      } else if (error.code === "auth/invalid-email") {
        return { error: "Invalid email" };
      } else {
        // "auth/abc-def" => "Abc Def"
        return { error: error.code.replace("auth/", "").replace(/-/g, " ").replace(/\b\w/g, (c: string) => c.toUpperCase()) };
      }
    });
  return userCredential;
};

export const userStateListener = (callback: NextOrObserver<User>) => {
  return onAuthStateChanged(auth, callback);
};

export const SignOutUser = async () => await signOut(auth);

// Microsoft Auth
const microsoftProvider = new OAuthProvider("microsoft.com");
export const signInWithMicrosoft = async (email: string) => {
  try {
    const emailExists = await checkUserEmailExists(email);
    if( emailExists ) {
      microsoftProvider.setCustomParameters({
        // Target specific email with login hint.
        login_hint: email
      });
      const userCredential = await signInWithPopup(auth, microsoftProvider);
      const dbUser = await getFirestoreUser(userCredential.user.uid);
      return { ...userCredential.user, ...dbUser };
    } else {
      return null;
    }
  } catch (error) {
    console.log("Error signing in with Microsoft: ", error);
    return null;
  }
};

export const checkUserEmailExists = async (email: string) => {
  const db = await import("firebase/firestore");
  const firestore = db.getFirestore();
  const usersRef = collection(firestore, "users");
  const query = db.query(usersRef, db.where("email", "==", email));
  const userSnap = await db.getDocs(query);
  const user = userSnap.docs[0];
  return userSnap.docs.length > 0;
}

export const getFirestoreUser = async (uid: string) => {
  const db = await import("firebase/firestore");
  const firestore = db.getFirestore();
  const userRef = collection(firestore, "users");
  const userSnap = await getDoc(doc(userRef, uid));
  return userSnap.data();
};

export const updateUserName = async (uid: string, name: string) => {
  const db = await import("firebase/firestore"); // Lazy load Firestore because this function is not always used
  const firestore = db.getFirestore(app);
  const userRef = db.doc(firestore, "users", uid);
  await db.updateDoc(userRef, { name });
  return { uid, name };
};

export const sendPasswordResetEmailToUser = async (email: string) => {
  console.log("Sending password reset email to: ", email);
  await sendPasswordResetEmail(auth, email);
};
