import { RulesData } from "@/utils/apis/rules_repository";
import React from "react";
import { TextField } from '@mui/material';
import { Text } from '../../../../Text';
import { TimePicker } from "../../../../inputs/TimePicker";
import AppliedTenants from "../DetailComponents/AppliedTenants";
import { prettifyTime, unprettifyTime } from "../../../../../../utils/helper";

interface BookingTimesInterface {
    startHour: number;
    endHour: number;
}
interface DefaultBookingTimesInterface {
    [tenant: string]: BookingTimesInterface; // Use tenant names as keys and store string values
}

interface DefaultBookingTimesProps {
    rule: RulesData;
    tenants: string[];
    appliedTenants: string[];
    bookingTimes: DefaultBookingTimesInterface;
    setDefaultBookingTimes: React.Dispatch<React.SetStateAction<DefaultBookingTimesInterface>>;
    setAppliedTenants: React.Dispatch<React.SetStateAction<string[]>>;
    setDataChanged: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DefaultBookingTimes: React.FC<DefaultBookingTimesProps> = ({ rule, tenants, appliedTenants, setAppliedTenants, bookingTimes, setDefaultBookingTimes, setDataChanged }) => {
    const handleStartTimeChange = (tenant: string, time: string) => {
        console.log(tenant, time);
        let startTime = unprettifyTime(time);
        let endTime = bookingTimes[tenant] ? bookingTimes[tenant].endHour ? bookingTimes[tenant].endHour : 1800 : 1800;
        if (startTime >= endTime) {
            alert("Start time cannot be after end time. Please select a valid time range.");
            return;
        }
        setDefaultBookingTimes((prev) => {
            return {
                ...prev,
                [tenant]: {
                    startHour: unprettifyTime(time),
                    endHour: prev[tenant] ? prev[tenant].endHour ? prev[tenant].endHour : 1800 : 1800
                }
            };
        });
        setDataChanged(true);
    };
    const handleEndTimeChange = (tenant: string, time: string) => {
        console.log(tenant, time);
        let endTime = unprettifyTime(time);
        let startTime = bookingTimes[tenant] ? bookingTimes[tenant].startHour ? bookingTimes[tenant].startHour : 800 : 800;
        if (startTime >= endTime) {
            alert("End time cannot be before start time. Please select a valid time range.");
            return;
        }
        setDefaultBookingTimes((prev) => {
            return {
                ...prev,
                [tenant]: {
                    startHour: prev[tenant] ? prev[tenant].startHour ? prev[tenant].startHour : 800 : 800,
                    endHour: unprettifyTime(time)
                }
            };
        });
        setDataChanged(true);
    };
    return (
        <>
            <AppliedTenants tenants={tenants} appliedTenants={appliedTenants} setAppliedTenants={setAppliedTenants} setDataChanged={setDataChanged} isDisabled={true} />

            <Text as='p'> Default Booking Times </Text>

            {appliedTenants.map((tenant) => {
                return (
                    <div className='wrapper' key={tenant}>
                        <div className='bg-white py-4'>
                            <TextField
                                id="clean"
                                variant="standard"
                                InputProps={{
                                    disableUnderline: true,
                                }}
                                contentEditable={false}
                                margin='none'
                                disabled
                                defaultValue={tenant}
                            />
                        </div>
                        <div>
                            <div className="mt-2">
                                <div className="flex flex-row space-x-4">
                                    <TimePicker onChange={(t) => handleStartTimeChange(tenant, t)} initialTime={bookingTimes[tenant] ? bookingTimes[tenant].startHour ? prettifyTime(bookingTimes[tenant].startHour) : prettifyTime(800) : prettifyTime(800)} />
                                    <TimePicker onChange={(t) => handleEndTimeChange(tenant, t)} initialTime={bookingTimes[tenant] ? bookingTimes[tenant].endHour ? prettifyTime(bookingTimes[tenant].endHour) : prettifyTime(1800) : prettifyTime(1800)} />
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            )}
        </>
    );
};

export default DefaultBookingTimes;