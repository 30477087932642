import { Outlet } from "react-router-dom";
import SideBar from "../components/ui/SideBar";

const AppLayout = () => {
    return (
        <div className="flex h-screen flex-1 flex-col overflow-hidden md:flex-row md:pb-0">
        <SideBar/>
        <div className="flex h-full w-full py-10 flex-col overflow-auto bg-background">
        <Outlet />
        </div>
        </div>
    )
}

export default AppLayout;