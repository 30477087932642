import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { ChangeEvent, FormEvent, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { signInUser, signInWithMicrosoft } from "../../firebase/firebase";
import Microsoft_logo_2012 from "../../assets/images/Microsoft_logo_2012.png";
import InputFormField from "../../components/ui/inputs/InputFormField";
import { Button } from "../../components/ui/CustomButton";
import { MicrosoftButton } from "../../components/ui/MicrosoftButton";
import EditPwDialog from "../dialogs/editPassword";
import { getUserFromLocalStorage } from "../../utils/helper";
import { TextField, Button as MUIButton, FormControl, InputLabel, Input, FormHelperText } from "@mui/material";

const signInScheme = z.object({
  email: z.string().email("Please enter a valid email address"),
  password: z.string().min(1, "Password is required"),
});

type SignInScheme = z.infer<typeof signInScheme>;

const SignIn = () => {

  const [pwopen, setPwOpen] = useState(false);
  const user = getUserFromLocalStorage();
  const [userEmail, setUserEmail] = useState<string>("");
  const [signInWithMs, setSignInWithMs] = useState(false);
  const [emailValid, setEmailValid] = useState(true);
  const [accountExists, setAccountExists] = useState(true);

  useEffect(() => {
    // check if the email is valid.
    if (userEmail) {
      if (userEmail.includes("@") && userEmail.includes(".") && userEmail.length > 5) {
        setEmailValid(true);
      } else {
        setEmailValid(false);
      }
    }
  }, [userEmail]);

  const handlePasswordResetClose = () => {
    setPwOpen(false);
  };

  const formData = useForm<SignInScheme>({
    mode: "onBlur",
    resolver: zodResolver(signInScheme),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const navigate = useNavigate();

  return (

    <div className="authInputBg">
      {/* Title. align left in a row */}
      <div className="flex flex-row items-center justify-start">
        <div className="text-3xl font-bold text-gray-600">Admin Dashboard</div>
      </div>
      {!signInWithMs && (
        <div className="authCard">
          <FormProvider {...formData}>
            <InputFormField
              name="email"
              label="Email Address"
              placeholder="Enter your email"
              className="mt-3 mb-3"
            />

            <InputFormField
              name="password"
              label="Password"
              type="password"
              placeholder="Password"
              className="mt-3 mb-4"
            />

            <div className="flex justify-end text-sm text-gray-500 hover:text-gray-600 hover:bg-gray-100 cursor-pointer p-1 rounded-md" onClick={() => { setPwOpen(true); }}>
              Forgot Password?
            </div>

            <EditPwDialog
              open={pwopen}
              onClose={handlePasswordResetClose}
              selectedValue={user ? user.email : ""}
              shouldDisable={false}
            />

            <Button
              type="submit"
              className="mt-3"
              onClick={formData.handleSubmit(async (data) => {
                const loginResponse = await signInUser(data.email, data.password);
                console.log(loginResponse);
                if (loginResponse && loginResponse.error === null) {
                  formData.reset();
                  localStorage.setItem("user", JSON.stringify(loginResponse));
                  navigate("/bookings");
                } else {
                  if(loginResponse?.error === "Invalid email") { // if the error is in the email address, set the error right below the email field.
                    formData.setError("email", {
                      type: "manual",
                      message: loginResponse ? loginResponse.error ?? "Invalid email" : "Invalid email",
                    });
                  } else {
                    formData.setError("password", { // if the error is not in the email address, set the error right below the password field.
                      type: "manual",
                      message: loginResponse ? loginResponse.error ?? "Invalid credentials" : "Invalid credentials",
                    });
                  }
                }
              })}
            >
              Sign In
            </Button>

          </FormProvider>
        </div>
      )}

      {signInWithMs && (
        <div className="authCard">
          <img src={Microsoft_logo_2012} alt="authentication" className="w-24 h-5 mb-10" />
          <h1 style={{ marginTop: '8px', marginBottom: '20px', color: 'gray', fontSize: '1.1rem' }}>Sign In</h1>
          <TextField
          inputProps={{"id": "ms-email", "type": "email", "autoComplete": "email", "autoFocus": true, "required": true }}
            error={!emailValid}
            label="Email"
            variant="standard"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setAccountExists(true);
              setUserEmail(e.target.value);
            }}
          />

          {!accountExists && (
            <div className="text-red-500">
              Account does not exist. Please sign up or use a different email.
            </div>
          )}
          <div className="m-10"></div>
          <div className="flex flex-row items-center justify-end">
            <MUIButton
              disabled={!emailValid || userEmail.length < 1}
              variant="contained"
              onClick={async () => {
                console.log("Verifying email");
                console.log(userEmail)
                const loginResponse = await signInWithMicrosoft(userEmail!);
                if (loginResponse) {
                  formData.reset();
                  localStorage.setItem("user", JSON.stringify(loginResponse));
                  navigate("/bookings");
                } else {
                  setAccountExists(false);
                }
              }}
            >
              Next
            </MUIButton>
          </div>
        </div>
      )}

      <div className="flex items-center justify-center mb-10">
        <div className="text-gray-400"> OR </div>
      </div>

      {!signInWithMs && (
        <MicrosoftButton
          className="mb-4"
          onClick={async () => {
            setSignInWithMs(true);
          }}
        >
          Sign In with Microsoft
        </MicrosoftButton>
      )}
      {signInWithMs && (
        <Button className="px-44"
          onClick={() => {
            setSignInWithMs(false);
          }}
        >
          Sign In with Email and Password
        </Button>
      )}
      <h1 style={{ marginTop: '100px', marginBottom: '0px', color: 'gray', fontSize: '1rem' }}>Please download the mobile app to signup.</h1>
    </div>
  );
};

export default SignIn;
